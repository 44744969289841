import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PlanActionModel } from 'src/app/models/multiple.activity.save.model';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { MultipleActivityService } from 'src/app/services/multiple-activity.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-new-planaction',
  templateUrl: './add-new-planaction.component.html',
  styleUrls: ['./add-new-planaction.component.scss']
})
export class AddNewPlanactionComponent implements OnInit {
  @Input() public updatedPlanAction = new PlanActionModel();
  @Input() public planActionArrayIndex = 0;
  @Input() public isUpdate = false;

  NewPlanActionFormGroup: FormGroup;

  constructor(public addNewPlanActionModal: NgbActiveModal,
    private multipleActivityService: MultipleActivityService,
    private datePipe: DatePipe,
    public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.InitForm();
  }

  private InitForm() {
    this.NewPlanActionFormGroup = this.formBuilder.group({
      Title: ['', [Validators.required]],
      Action: ["executed", [Validators.required]],
      EstimatedDate: ['', [Validators.required]],
      Description: [''],
      Cost: [''],
      Responsible: [null],
      CanMitigateRisk: [null],
      CanEliminateRisk: [null],
      ActionChekedInVisit: [null]
    });

    this.NewPlanActionFormGroup.patchValue({
      Title: this.updatedPlanAction.title,
      Action: this.updatedPlanAction.action == null ? 'executed' : this.updatedPlanAction.action,
      EstimatedDate: this.updatedPlanAction.estimatedDate,
      Description: this.updatedPlanAction.description,
      Cost: this.updatedPlanAction.cost,
      Responsible: this.updatedPlanAction.responsible,
      CanMitigateRisk: this.updatedPlanAction.canMitigateRisk == null ? false : this.updatedPlanAction.canMitigateRisk,
      CanEliminateRisk: this.updatedPlanAction.canEliminateRisk == null ? false : this.updatedPlanAction.canEliminateRisk,
      ActionChekedInVisit: this.updatedPlanAction.actionChekedInVisit == null ? false : this.updatedPlanAction.actionChekedInVisit
    });
  }

  private AddPlanAction() {
    const planAction = this.multipleActivityService.getPlanActionWithCommonValues();
    planAction.title = this.NewPlanActionFormGroup.value.Title;
    planAction.action = this.NewPlanActionFormGroup.value.Action;
    planAction.estimatedDate = this.datePipe.transform(this.NewPlanActionFormGroup.value.EstimatedDate, 'yyyy-MM-dd');
    planAction.cost = this.NewPlanActionFormGroup.value.Cost;
    planAction.description = this.NewPlanActionFormGroup.value.Description;
    planAction.responsible = this.NewPlanActionFormGroup.value.Responsible;
    planAction.canMitigateRisk = this.NewPlanActionFormGroup.value.CanMitigateRisk;
    planAction.canEliminateRisk = this.NewPlanActionFormGroup.value.CanEliminateRisk;
    planAction.actionChekedInVisit = this.NewPlanActionFormGroup.value.ActionChekedInVisit;
    this.multipleActivityService.selectedPlanAction.push(planAction);
    this.addNewPlanActionModal.close();
  }

  private UpdatePlanAction() {
    this.updatedPlanAction.title = this.NewPlanActionFormGroup.value.Title;
    this.updatedPlanAction.action = this.NewPlanActionFormGroup.value.Action;
    this.updatedPlanAction.estimatedDate = this.datePipe.transform(this.NewPlanActionFormGroup.value.EstimatedDate, 'yyyy-MM-dd');
    this.updatedPlanAction.description = this.NewPlanActionFormGroup.value.Description;
    this.updatedPlanAction.cost = this.NewPlanActionFormGroup.value.Cost;
    this.updatedPlanAction.responsible = this.NewPlanActionFormGroup.value.Responsible;
    this.updatedPlanAction.canEliminateRisk = this.NewPlanActionFormGroup.value.CanEliminateRisk;
    this.updatedPlanAction.canMitigateRisk = this.NewPlanActionFormGroup.value.CanMitigateRisk;
    this.updatedPlanAction.actionChekedInVisit = this.NewPlanActionFormGroup.value.ActionChekedInVisit;
    this.multipleActivityService.selectedPlanAction.splice(this.planActionArrayIndex, 1, this.updatedPlanAction);
    this.addNewPlanActionModal.close();
    this.isUpdate = false;
  }

  OnPlanActionSave() {
    if (this.validatePlanActionForm()) {
      if (this.isUpdate) {
        this.UpdatePlanAction();
      } else {
        this.AddPlanAction();
      }
    }
  }

  validatePlanActionForm() {
    if (this.NewPlanActionFormGroup.invalid) {
      Object.keys(this.NewPlanActionFormGroup.controls).forEach(field => {
        const control = this.NewPlanActionFormGroup.get(field);
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
      });
      return false;
    } else {
      Object.keys(this.NewPlanActionFormGroup.controls).forEach(field => {
        const control = this.NewPlanActionFormGroup.get(field);
        control.markAsPristine({ onlySelf: true });
        control.markAsUntouched({ onlySelf: true });        
      });
      return true;
    }
  }

  deletePlanAction() {
    Swal.fire({
      title: 'La acción entera se eliminará, y no podrá ser recuperada. Está seguro?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Volver'
    }).then((result) => {
      if (result.value) {
        this.multipleActivityService.selectedPlanAction.splice(this.planActionArrayIndex, 1);
        this.addNewPlanActionModal.close();
        this.isUpdate = false;
      }
    });
  }

  closePlanAction() {
    Swal.fire({
      title: 'Los cambios no se guardarán, desea continuar',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Salir sin guardar',
      cancelButtonText: 'Volver'
    }).then((result) => {
      if (result.value) {
        this.addNewPlanActionModal.close();
        this.isUpdate = false;
      }
    });
  }

}
