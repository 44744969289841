import { Injectable, ErrorHandler } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from 'src/app/services/_authService/authentication.service';
import { GAuthService } from '../_authService/gauth.service';
import { Router } from '@angular/router';
import { ErrorHandlerService } from '../error-handler.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: GAuthService,private router:Router,private errorHandlerService:ErrorHandlerService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {        
        return next.handle(request).pipe(catchError(err => {           
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.setItem("sessionErrorMsg", "Su sesión ha terminado.Por favor,inicie sesión de nuevo.");
                this.router.navigate(['/login']);
               // this.authenticationService.logout();
                //location.reload(true);
            }            
           // const error = err.error.message || err.statusText;
            return throwError("");
        }))
    }
}
