import {
  Component,
  OnInit,
  ViewChild,
  Input,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { AddNewActivityComponent } from "./add-new-activity/add-new-activity.component";
import { AddExistingActivityComponent } from "./add-existing-activity/add-existing-activity.component";
import { AddContactsComponent } from "./add-contacts/add-contacts.component";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { SelectOptions } from "src/app/models/select-options.model";
import { DropdownService } from "src/app/services/dropdown.service";
import { ErrorHandlerService } from "src/app/services/error-handler.service";
import { MultipleActivityService } from "src/app/services/multiple-activity.service";
import { ActivityService } from "src/app/services/activity.service";
import { Activity } from "src/app/models/activity.model";
import { ToastrService } from "ngx-toastr";

import {
  ActivityModel,
  MultipleActivitySaveModel,
  PlanActionModel,
} from "src/app/models/multiple.activity.save.model";
import { SubSink } from "subsink";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivityRoot } from "src/app/models/activity.save.activityroot";
import { LogActivityService } from "src/app/services/_logActivity/log-activity.service";
import { UsuarioUpsert } from "../../../../models/activity.save.usuarioupsert";
import Swal from "sweetalert2";
import { MasterDataService } from "src/app/services/master-data.service";
import { ParteDeVisitaState } from "src/app/models/Enum/ParteDeVisitaState";
import { WizardComponent } from "angular2-wizard";
import { AddNewPlanactionComponent } from "./add-new-planaction/add-new-planaction.component";
import { DatePipe } from "@angular/common";
import { SignaturePadComponent } from "@almothafar/angular-signature-pad";
import { DataSharingService } from "src/app/services/data-sharing.service";
import { MemoriaAnualService } from "src/app/services/common/memoria-anual.service";

@Component({
  selector: "app-add-multiple-activities",
  templateUrl: "./add-multiple-activities.component.html",
  styleUrls: ["./add-multiple-activities.component.scss"],
})
export class AddMultipleActivitiesComponent implements OnInit, AfterViewInit {
  modalOptions: NgbModalOptions;
  @Input() public ActivityFormGroupWizard1: FormGroup = this.fb.group({
    ParentActivityId: [null, Validators.required],
    CompanyId: [null, Validators.required],
    CompanyName: [null],
    ActivityDate: [null, Validators.required],
    ActivityTime: [null, Validators.required],
    ActivityFinishTime: [null],
    Duration: [
      null,
      [Validators.required, Validators.min(0), Validators.max(500)],
    ],
    WorkCentreId: [null, Validators.required],
    WorkCentreName: [null],
    AssignedUserId: [null, Validators.required],
    AssignedUserName: [null],
    Description: [null],
    VisitActivityId: [null],
    ActivityTypeId: [null, Validators.required],
  });

  ActivityFormGroupWizard2: FormGroup;
  ActivityFormGroupWizard3: FormGroup;
  ActivityFormGroupWizard5: FormGroup;
  ActivityFormGroupWizard6: FormGroup;

  PreventionDelegateForm: FormGroup;

  companies$: SelectOptions[];
  workingCentres$: SelectOptions[];
  users$: SelectOptions[];
  selectedUser: number;

  multiActivitySaveSuccess = false;
  emailSendSuccess = false;
  isMarked: boolean = true;
  isClosedClicked: boolean = false;

  isActivityListVerified = false;
  isPatientCountInputEnabled = false;
  isDiseasesInputEnabled = false;
  isNewWorkerInputEnabled = false;
  private clientReqIPAddress = "";
  private subs = new SubSink();

  public userEmailAddress = "";
  emailAddresses = [];
  public emailValidators = [Validators.email];
  public emailPlaceholder =
    "ingrese la (s) dirección (s) de correo electrónico";
  public emailAddMoreText = "";

  @ViewChild(SignaturePadComponent) signaturePad: SignaturePadComponent;
  @ViewChild("preventionDelegateSignaturePad")
  preventionDelegateSignaturePad: SignaturePadComponent;
  @ViewChild("firmaTecSignaturePad")
  firmaTecSignaturePad: SignaturePadComponent;
  @ViewChild("wizardComponent") wizardComponent: WizardComponent;

  public ParteDeVisitaStates = ParteDeVisitaState;
  public SelectedParteDeVisitaState: ParteDeVisitaState;
  public SelectedReferenceNo: string;
  public IsValidated = false;
  public IsFooterHidden = false;

  public selectedCompanyLanguage = "es";

  public signaturePadOptions: Object = {
    minWidth: 5,
    canvasWidth: 800,
    canvasHeight: 200,
    backgroundColor: "rgba(255, 255, 255, 1)",
  };
  emailSubject: any;
  emailBody: any;
  stepform6: FormGroup;
  stepform7: FormGroup;
  userDetailsId: any;
  userDetails: any;

  constructor(
    public fb: FormBuilder,
    public multipleActivityModal: NgbActiveModal,
    private modalService: NgbModal,
    public multipleActivityService: MultipleActivityService,
    private activityService: ActivityService,
    private dropdownService: DropdownService,
    private toastrService: ToastrService,
    private errorHandler: ErrorHandlerService,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    private logActivityService: LogActivityService,
    private masterDataService: MasterDataService,
    private changeDetectorRef: ChangeDetectorRef,
    private dataSharingService: DataSharingService,
    private memoriaAnualService: MemoriaAnualService
  ) {
    this.modalOptions = {
      backdrop: "static",
      windowClass: "text-left modal-fade-animation",
      size: "sm",
      centered: true,
    };

    const loggedUserId = JSON.parse(localStorage.getItem("loggeduser")).id;

    this.subs.add(
      this.dropdownService.getClientReqIPAddress().subscribe(
        (response) => {
          this.clientReqIPAddress = response.clientReqIPAddress;
        },
        (error) => {
          this.errorHandler.handle(error);
        }
      ),
      this.dropdownService.getUserEmailAddress(loggedUserId).subscribe(
        (response) => {
          this.userEmailAddress = response.userEmail ? response.userEmail : "";
          if (this.userEmailAddress !== "") {
            const emailObj = {
              display: this.userEmailAddress,
              value: this.userEmailAddress,
            };
            this.emailAddresses.push(emailObj);
          }
        },
        (error) => {
          this.errorHandler.handle(error);
        }
      )
    );

    this.stepform6 = this.fb.group({
      trabajos_altura: [false],
      trabajos_verticales: [false],
      plataformas_elevadoras: [false],
      carretillas_elevadoras: [false],
      transpaletas_apiladores: [false],
      incendios: [false],
      primeros_auxilios: [false],
      espacios_confinados: [false],
      consignacion_maquinas: [false],
      riesgo_electrico: [false],
      riesgo_quimico: [false],
      riesgo_biologico: [false],
      riesgo_ergonomico: [false],
      nivel_basico_prl: [false],
      delegados_prevencion: [false],
      seguridad_vial: [false],
      teletrabajo: [false],
      otros_formacion: [false],
      admin_20h: [false],
      directivos_10h: [false],
      mandos_intermedios_20h: [false],
      responsables_tecnicos_20h: [false],
      nivel_basico_prl_60h: [false],
      delegados_prevencion_70h: [false],
      oficios_20h: [false],
      oficios_6h: [false],
      admin_metal_obra_20h: [false],
      directivos_metal_obra_10h: [false],
      mandos_intermedios_metal_obra_20h: [false],
      responsables_tecnicos_metal_obra_20h: [false],
      nivel_basico_prl_metal_obra_60h: [false],
      delegados_prevencion_metal_obra_50h: [false],
      oficios_metal_obra_20h: [false],
      oficios_metal_obra_6h: [false],
      reciclaje_oficios_metal_obra_4h: [false],

      personal_oficines: [false],
      directius: [false],
      delegats_de_prevenció: [false],
      nivell_bàsic: [false],
      oficis_producció: [false],
      conductor_grues: [false],
      altres_oficis: [false],
      producció: [false],
      reciclatge_oficis: [false],
      directivos_10: [false],
      administrativos_10: [false],
      responsables_20: [false],
      mandos_20: [false],
      delegados_50: [false],
      Oficios_20h: [false],
      Oficinas_6: [false],
      nivel_básico: [false],
      administración_6h: [false],
      directivos_10hora: [false],
      oficinas_20hora: [false],
      nivel_básico_60hora: [false],
      agentes_químicos: [false],
      agentes_biológicos: [false],
      sonometría: [false],
      dosimetría: [false],
      radiaciones_ionizantes: [false],
      campos_electromagnéticos: [false],
      electricidad_estática: [false],
      estrés_térmico: [false],
      vibraciones: [false],
      otros: [false],
      manipulación_manual_de_cargas: [false],
      movimientos_repetitivos: [false],
      posturas_forzadas: [false],
      estudi_psicosocial: [false],
      protocol_assetjament: [false],
      pla_digualtat: [false],
      emmagatzematge_productes_químics: [false],
      document_proteccio_contra_explosions: [false],
      informe_adequacio_maquines: [false],
      pla_mobilitat_seguretat_viaria: [false],
      profesiograma: [false],

      agentes_químicos_text: [""],
      agentes_biológicos_text: [""],
      otros_text: [""],
      estudi_psicosocial_text: [""],
      protocol_assetjament_text: [""],
      pla_digualtat_text: [""],
      emmagatzematge_productes_químics_text: [""],
      document_proteccio_contra_explosions_text: [""],
      informe_adequacio_maquines_text: [""],
      pla_mobilitat_seguretat_viaria_text: [""],
      profesiograma_text: [""],
    });

    this.stepform7 = this.fb.group({
      llistat_personal: [false],
      llistat_maquinaria: [false],
      fitxes_dades: [false],
      procediments_instr: [false],
      declaracio_conformitat: [false],
      manuals_instr: [false],
      llibres_registre: [false],
      informes_adequacio: [false],
      requeriments_inspeccio: [false],
      part_accident: [false],
      informe_sinistralitat: [false],
      registre_accidents: [false],
      documentacio_prevencio: [false],
      certificats_formacio: [false],
      estudis_ergonomics: [false],
      estudis_higienics: [false],
      estudis_psicosocials: [false],
      certificat_reo: [false],
      fullet_epis: [false],

      manuals_instr_text: [""],
      informe_sinistralitat_text: [""],
      registre_accidents1_text: [""],
      registre_accidents2_text: [""],
      altra_documentacio_text: [""],
    });
  }

  InitFormWizard2() {
    this.ActivityFormGroupWizard2 = this.fb.group({
      WorkCentreWorkerCount: [
        "null",
        [Validators.required, Validators.min(1), Validators.max(500)],
      ],
      IsAccidentOccured: ["false", Validators.required],
      MenAccidents: [{ disabled: true, value: null }, [Validators.required]],
      WomenAccidents: [{ disabled: true, value: null }, [Validators.required]],
      VisitedAccidents: [
        { disabled: true, value: null },
        [Validators.required],
      ],
      InvestigatedAccidents: [
        { disabled: true, value: null },
        [Validators.required],
      ],
      IsNewWorkerAdded: ["false", Validators.required],
      IsJobInfoGiven: ["false", Validators.required],
      IsEPIGiven: ["null", Validators.required],
      TrainingProgramRemark: [null],
      TemplateLanguage: [this.selectedCompanyLanguage, Validators.required],
      NoMen: [null],
      NoWomen: [null],
      StaffWomen: [{ disabled: true, value: null }, [Validators.required]],
      StaffMen: [{ disabled: true, value: null }, [Validators.required]],
      ETTWomen: [{ disabled: true, value: null }, [Validators.required]],
      ETTMen: [{ disabled: true, value: null }, [Validators.required]],
      IsTrainedRiskPrevention: ["false", Validators.required],
      IsUnderMedicalExamination: ["false", Validators.required],
      IsOccupationDiseases: ["false", Validators.required],
      MenOccupationDiseases: [
        { disabled: true, value: null },
        [Validators.required],
      ],
      WomenOccupationDiseases: [
        { disabled: true, value: null },
        [Validators.required],
      ],
      VisitedOccupationDiseases: [
        { disabled: true, value: null },
        [Validators.required],
      ],
      InvestigatedOccupationDiseases: [
        { disabled: true, value: null },
        [Validators.required],
      ],
    });
  }

  InitFormWizard3() {
    this.ActivityFormGroupWizard3 = this.fb.group({
      IsWorkTeamJoined: ["false", Validators.required],
      IsMedicalCertificateAvl: ["false", Validators.required],
      IsCoordinationDone: ["false"],
      IsPregnantWomenAvl: "",
      PregnantWomenRemark: "",
      IsMinorsAvl: "",
      MinorsRemark: "",
      IsESensitiveAvl: "",
      ESensitiveRemark: "",
      IsChemicalSubstances: ["null"],
      IsWorkPlaceChanged: ["null"],
      IslegalProsecution: ["false"],
      IsRiskNotifications: ["false"],
    });
  }

  InitFormWizard5() {
    this.ActivityFormGroupWizard5 = this.fb.group({
      ParteContactName: [""],
      ParteContactDNI: [""],
    });
  }

  InitPreventionDelegateForm() {
    this.PreventionDelegateForm = this.fb.group({
      Name: [""],
      DNI: [""],
    });
  }

  InitFormWizard6() {
    this.ActivityFormGroupWizard6 = this.fb.group({
      EmailSubject: ["", Validators.required],
      EmailBody: ["", Validators.required],
      ContactEmail: [null],
    });
  }

  ngOnInit() {
    this.InitFormWizard2();
    this.InitFormWizard3();
    this.InitFormWizard5();
    this.InitFormWizard6();
    this.InitPreventionDelegateForm();
    this.InitWizard1DropDowns();
    this.changeWizardText();
    this.saveGeneralActivityState();

    this.IsValidated =
      this.SelectedParteDeVisitaState === this.ParteDeVisitaStates.Validated;
    const workCenterId = this.ActivityFormGroupWizard1.value.WorkCentreId;

    if (this.SelectedParteDeVisitaState !== this.ParteDeVisitaStates.Create) {
      this.loadParteDeVisitaData();
    } else {
      this.multipleActivityService.selectedPlanAction.length = 0;
      this.getWorkCentreWorkerCount(workCenterId);
    }
    if (this.dropdownService.emailAddresses.length > 0) {
      this.emailAddresses = this.dropdownService.emailAddresses;
    }

    this.isClosedClicked = false;

    this.dropdownService
      .getContactEmailAddress(workCenterId)
      .subscribe((response) => {
        if (response.length > 0) {
          response.forEach((element) => {
            const emailObj = { display: element, value: element };
            this.emailAddresses.push(emailObj);
          });
        }
      });
  }

  ngAfterViewInit(): void {
    if (this.IsValidated) {
      this.setParteDeVisitaState();
    }
  }

  private setParteDeVisitaState(): void {
    this.ActivityFormGroupWizard2.disable();
    this.ActivityFormGroupWizard3.disable();
    this.ActivityFormGroupWizard5.disable();
    this.ActivityFormGroupWizard1.disable();
    this.ActivityFormGroupWizard1.disable();
    this.PreventionDelegateForm.disable();

    this.wizardComponent.steps.forEach((v, i) => {
      v.isActive = false;
      v.isValid = true;
      v.isDisabled = false;
    });

    const step =
      this.wizardComponent.steps[this.wizardComponent.steps.length - 1];
    step.isActive = true;
    step.isValid = false;

    this.wizardComponent.goToStep(step);
    this.signaturePad.off();
    this.preventionDelegateSignaturePad.off();
    this.firmaTecSignaturePad.off();
    this.changeDetectorRef.detectChanges();
  }

  private setCurrentStep(stepTitle: string): void {
    const stepIndex = this.wizardComponent.steps.findIndex(
      (e) => e.title === stepTitle.trim()
    );
    if (stepIndex > -1) {
      this.wizardComponent.steps.forEach((v, i) => {
        if (i < stepIndex) {
          v.isActive = false;
          v.isValid = true;
          v.isDisabled = false;
        }
      });
      const step = this.wizardComponent.steps[stepIndex];
      step.isActive = true;
      // The step before the last has the validate button
      if (step.title === "Firma Tec.") {
        this.changeWizardText(true);
      }
      this.wizardComponent.goToStep(step);
    }
  }

  private loadParteDeVisitaData(): void {
    this.masterDataService.getActivityPart(this.SelectedReferenceNo).subscribe(
      (response) => {
        this.ActivityFormGroupWizard2.patchValue({
          WorkCentreWorkerCount: response.workerCount,
          IsAccidentOccured: String(response.isAccidentOccured),
          VisitedAccidents: response.visitedAccidents,
          InvestigatedAccidents: response.investigatedAccidents,
          IsNewWorkerAdded: String(response.isNewWorkerAdded),
          IsJobInfoGiven: String(response.isJobInfoGiven),
          IsEPIGiven: String(response.isEPIGiven),
          TrainingProgramRemark: response.trainingProgramRemark,
          TemplateLanguage: response.templateLanguage
            ? response.templateLanguage
            : "es",
          WomenAccidents: response.womenAccidents,
          MenAccidents: response.menAccidents,
          NoMen: response.noMen,
          NoWomen: response.noWomen,
          StaffMen: response.staffMen,
          StaffWomen: response.staffWomen,
          ETTMen: response.ettMen,
          ETTWomen: response.ettWomen,
          IsTrainedRiskPrevention: String(
            response.isTrainedRiskPrevention == null ? false : true
          ),
          MenOccupationDiseases: response.menOccupationDiseases,
          WomenOccupationDiseases: response.womenOccupationDiseases,
          InvestigatedOccupationDiseases:
            response.investigatedOccupationDiseases,
          VisitedOccupationDiseases: response.visitedOccupationDiseases,
          IsUnderMedicalExamination: String(response.isUnderMedicalExamination),
          IsOccupationDiseases: String(response.isOccupationDiseases),
        });

        this.ActivityFormGroupWizard3.patchValue({
          IsWorkTeamJoined: String(response.isWorkTeamJoined),
          IsMedicalCertificateAvl: String(response.isMedicalCertificateAvl),
          IsCoordinationDone: String(response.isCoordinationDone),
          IsPregnantWomenAvl: String(response.isPregnantWomenAvl),
          PregnantWomenRemark: response.pregnantWomenRemark,
          IsMinorsAvl: String(response.isMinorsAvl),
          MinorsRemark: response.minorsRemark,
          IsESensitiveAvl: String(response.isESensitiveAvl),
          ESensitiveRemark: response.eSensitiveRemark,
          IsChemicalSubstances: String(response.isChemicalSubstances),
          IsWorkPlaceChanged: String(response.isWorkPlaceChanged),
          IslegalProsecution: String(
            response.islegalProsecution == null ? false : true
          ),
          IsRiskNotifications: String(response.isRiskNotifications),
        });
        this.ActivityFormGroupWizard3.markAllAsTouched();

        this.ActivityFormGroupWizard5.patchValue({
          ParteContactName: response.signedUsername,
          ParteContactDNI: response.signedUserDNI,
        });
        this.ActivityFormGroupWizard5.markAllAsTouched();

        if (response.signatureImage) {
          const src = "data:image/png;base64," + response.signatureImage;
          this.signaturePad.fromDataURL(src);
        }

        this.PreventionDelegateForm.patchValue({
          Name: response.preventionDelegateName,
          DNI: response.preventionDelegateDNI,
        });
        this.PreventionDelegateForm.markAllAsTouched();

        if (response.preventionDelegateSignature) {
          const src =
            "data:image/png;base64," + response.preventionDelegateSignature;
          this.preventionDelegateSignaturePad.fromDataURL(src);
        }

        if (response.firmaTecSignature) {
          const src = "data:image/png;base64," + response.firmaTecSignature;
          this.firmaTecSignaturePad.fromDataURL(src);
        }

        if (response.customerPlanActions) {
          const planActionList = JSON.parse(response.customerPlanActions);
          this.multipleActivityService.selectedPlanAction.length = 0;
          this.isMarked = planActionList[0]["IsMarked"];

          if (planActionList[0]["CanMitigateRisk"] != null) {
            planActionList.forEach((v, i) => {
              const planAction =
                this.multipleActivityService.getPlanActionWithCommonValues();
              (planAction.isMarked = v.IsMarked), (planAction.title = v.Title);
              planAction.description = v.Description;
              planAction.action = v.Action;
              planAction.cost = v.Cost;
              planAction.responsible = v.Responsible;
              planAction.estimatedDate = v.EstimatedDate;
              planAction.canEliminateRisk = v.CanEliminateRisk;
              planAction.canMitigateRisk = v.CanMitigateRisk;
              planAction.actionChekedInVisit = v.ActionChekedInVisit;
              this.multipleActivityService.selectedPlanAction.push(planAction);
            });
          }
        } else {
          this.multipleActivityService.selectedPlanAction.length = 0;
          this.isMarked = true;
        }

        if (response.activityListAttribute) {
          this.multipleActivityService.selectedActivity.length = 0;
          const activityList = JSON.parse(response.activityListAttribute);
          const mainDetails = this.ActivityFormGroupWizard1.value;

          activityList.forEach((v, i) => {
            const activity =
              this.multipleActivityService.getActivityWithCommonValues();
            activity.activityDate = v.ActivityDate;
            activity.activityTime = v.ActivityTime;
            activity.activityTypeId = v.ActivityTypeId;
            activity.activityTypeName = v.ActivityTypeName;
            activity.assignedUserId = v.AssignedUserId;
            activity.companyId = v.CompanyId;
            activity.description = v.Description;
            activity.duration = v.Duration;
            activity.entryDate = v.EntryDate;
            activity.id = v.Id;
            activity.isDeleted = v.IsDeleted;
            activity.statusTypeId = v.StatusTypeId;
            activity.statusTypeName = v.StatusTypeName;
            activity.userId = v.UserId;
            activity.workCentreId = v.WorkCentreId;
            activity.companyName = mainDetails.CompanyName;
            activity.workCentreName = mainDetails.WorkCentreName;
            this.multipleActivityService.selectedActivity.push(activity);
          });
        }

        if (response.currentStepTitle && !this.IsValidated) {
          this.setCurrentStep(response.currentStepTitle);
        }

        this.setEmailContent();
      },
      (error) => {
        this.errorHandler.handle(error);
      }
    );
  }

  changeWizardText(isFirma = false): void {
    const modalElement = document.getElementById("modal-activity-multiple");
    const footer = modalElement.getElementsByClassName("card-footer")[0];
    const footerBtns = footer.getElementsByClassName("btn");

    for (let index = 0; index < footerBtns.length; index++) {
      footerBtns[index].classList.add("wizard-buttons");
      if (footerBtns[index].innerHTML === "Previous") {
        footerBtns[index].innerHTML = "Anterior";
      } else if (
        footerBtns[index].innerHTML === "Next" ||
        footerBtns[index].innerHTML === "Validar y Firmar"
      ) {
        footerBtns[index].innerHTML = "Siguiente";
      } else if (footerBtns[index].innerHTML === "Done") {
        footerBtns[index].innerHTML = "Enviar Mail";
      }

      if (
        !this.IsValidated &&
        isFirma &&
        footerBtns[index].innerHTML === "Siguiente"
      ) {
        footerBtns[index].innerHTML = "Validar y Firmar";
      }
    }
  }

  openAddNewActivityPopup() {
    const a = this.ActivityFormGroupWizard1.value;
    localStorage.removeItem("newactivitymastervalues");
    localStorage.setItem(
      "newactivitymastervalues",
      JSON.stringify({
        companyId: a.CompanyId,
        companyName: a.CompanyName,
        workCentreId: a.WorkCentreId,
        workCentreName: a.WorkCentreName,
      })
    );
    const modalRef = this.modalService.open(
      AddNewActivityComponent,
      this.modalOptions
    );

    modalRef.result.then(
      (close) => {
        this.verifyActivityList();
      },
      (dismiss) => {}
    );
  }

  openAddNewPlanActionPopup() {
    const modalRef = this.modalService.open(
      AddNewPlanactionComponent,
      this.modalOptions
    );
    // modalRef.result.then((close) => {
    //   this.verifyPlanActionList();
    // }, (dismiss) => { });
  }

  openAddExistingActivityPopup() {
    const a = this.ActivityFormGroupWizard1.value;
    localStorage.removeItem("newactivitymastervalues");
    localStorage.removeItem("parentactivityid");
    localStorage.setItem(
      "parentactivityid",
      this.ActivityFormGroupWizard1.value.ParentActivityId
    );
    localStorage.setItem(
      "newactivitymastervalues",
      JSON.stringify({
        companyId: a.CompanyId,
        companyName: a.CompanyName,
        workCentreId: a.WorkCentreId,
        workCentreName: a.WorkCentreName,
      })
    );
    const modalRef = this.modalService.open(
      AddExistingActivityComponent,
      this.modalOptions
    );
    modalRef.result.then(
      (close) => {
        this.verifyActivityList();
      },
      (dismiss) => {}
    );
  }

  openAddContactsPopup() {
    const modalRef = this.modalService.open(
      AddContactsComponent,
      this.modalOptions
    );
    modalRef.result.then(
      (close) => {
        this.ActivityFormGroupWizard6.patchValue({
          ContactEmail: this.multipleActivityService.saveModel.recipientEmail,
        });
      },
      (dismiss) => {}
    );
  }

  async saveGeneralActivityState() {
    if (this.ActivityFormGroupWizard1.valid) {
      this.multipleActivityService.saveModel.companyId =
        this.ActivityFormGroupWizard1.value.CompanyId;
      this.multipleActivityService.saveModel.workCentreId =
        this.ActivityFormGroupWizard1.value.WorkCentreId;
      this.multipleActivityService.saveModel.assignedUserId =
        this.ActivityFormGroupWizard1.value.AssignedUserId;

      this.multipleActivityService.saveModel.activityPartDate =
        this.ActivityFormGroupWizard1.value.ActivityDate;
      this.multipleActivityService.saveModel.activityPartFinishTime =
        this.activityService.getActivityFinishTime(
          this.ActivityFormGroupWizard1.value.ActivityTime,
          null,
          Number(this.ActivityFormGroupWizard1.value.Duration)
        );
      const startTime = this.ActivityFormGroupWizard1.value.ActivityTime;
      this.multipleActivityService.saveModel.activityPartTime =
        ("0" + startTime.getHours()).slice(-2) +
        ("0" + startTime.getMinutes()).slice(-2) +
        ("0" + startTime.getSeconds()).slice(-2);
      this.multipleActivityService.saveModel.activityPartDuration =
        this.ActivityFormGroupWizard1.value.Duration;

      const localStorageUser = JSON.parse(localStorage.getItem("loggeduser"));
      this.multipleActivityService.saveModel.userId = localStorageUser.id;
      this.multipleActivityService.saveModel.activityTypeId =
        this.ActivityFormGroupWizard1.value.ActivityTypeId; // Visit

      if (this.multipleActivityService.selectedActivity.length > 0) {
        this.multipleActivityService.refreshActivityList();
      }

      if (this.multipleActivityService.selectedPlanAction.length > 0) {
        this.multipleActivityService.refreshPlanActionList();
      }
    }
  }

  saveQuestionnaire() {
    if (this.ActivityFormGroupWizard2.valid || this.IsValidated) {
      this.multipleActivityService.saveModel.noOfWorkCenterWorkers =
        this.ActivityFormGroupWizard2.value.WorkCentreWorkerCount;
      this.multipleActivityService.saveModel.isAccidentOccured =
        this.ActivityFormGroupWizard2.value.IsAccidentOccured == "true"
          ? true
          : false;
      this.multipleActivityService.saveModel.visitedAccidents =
        this.ActivityFormGroupWizard2.value.VisitedAccidents == undefined
          ? null
          : this.ActivityFormGroupWizard2.value.VisitedAccidents;
      this.multipleActivityService.saveModel.investigatedAccidents =
        this.ActivityFormGroupWizard2.value.InvestigatedAccidents == undefined
          ? null
          : this.ActivityFormGroupWizard2.value.InvestigatedAccidents;
      this.multipleActivityService.saveModel.isNewWorkerAdded =
        this.ActivityFormGroupWizard2.value.IsNewWorkerAdded == "true"
          ? true
          : false;
      this.multipleActivityService.saveModel.isJobInfoGiven =
        this.ActivityFormGroupWizard2.value.IsJobInfoGiven == "true"
          ? true
          : false;
      this.multipleActivityService.saveModel.isEPIGiven =
        this.ActivityFormGroupWizard2.value.IsEPIGiven == "null"
          ? null
          : this.ActivityFormGroupWizard2.value.IsEPIGiven;
      this.multipleActivityService.saveModel.trainingProgramRemark =
        this.ActivityFormGroupWizard2.value.TrainingProgramRemark;
      this.multipleActivityService.saveModel.templateLanguage =
        this.ActivityFormGroupWizard2.value.TemplateLanguage;

      this.multipleActivityService.saveModel.womenAccidents =
        this.ActivityFormGroupWizard2.value.WomenAccidents == undefined
          ? null
          : this.ActivityFormGroupWizard2.value.WomenAccidents;
      this.multipleActivityService.saveModel.menAccidents =
        this.ActivityFormGroupWizard2.value.MenAccidents == undefined
          ? null
          : this.ActivityFormGroupWizard2.value.MenAccidents;
      this.multipleActivityService.saveModel.noMen =
        this.ActivityFormGroupWizard2.value.NoMen;
      this.multipleActivityService.saveModel.noWomen =
        this.ActivityFormGroupWizard2.value.NoWomen;
      this.multipleActivityService.saveModel.staffMen =
        this.ActivityFormGroupWizard2.value.StaffMen == undefined
          ? null
          : this.ActivityFormGroupWizard2.value.StaffMen;
      this.multipleActivityService.saveModel.staffWomen =
        this.ActivityFormGroupWizard2.value.StaffWomen == undefined
          ? null
          : this.ActivityFormGroupWizard2.value.StaffWomen;
      this.multipleActivityService.saveModel.ettWomen =
        this.ActivityFormGroupWizard2.value.ETTWomen == undefined
          ? null
          : this.ActivityFormGroupWizard2.value.ETTWomen;
      this.multipleActivityService.saveModel.ettMen =
        this.ActivityFormGroupWizard2.value.ETTMen == undefined
          ? null
          : this.ActivityFormGroupWizard2.value.ETTMen;
      this.multipleActivityService.saveModel.isTrainedRiskPrevention =
        this.ActivityFormGroupWizard2.value.IsTrainedRiskPrevention == "true"
          ? true
          : false;
      this.multipleActivityService.saveModel.isUnderMedicalExamination =
        this.ActivityFormGroupWizard2.value.IsUnderMedicalExamination == "true"
          ? true
          : false;
      this.multipleActivityService.saveModel.isOccupationDiseases =
        this.ActivityFormGroupWizard2.value.IsOccupationDiseases == "true"
          ? true
          : false;
      this.multipleActivityService.saveModel.investigatedOccupationDiseases =
        this.ActivityFormGroupWizard2.value.InvestigatedOccupationDiseases ==
        undefined
          ? null
          : this.ActivityFormGroupWizard2.value.InvestigatedOccupationDiseases;
      this.multipleActivityService.saveModel.visitedOccupationDiseases =
        this.ActivityFormGroupWizard2.value.VisitedOccupationDiseases ==
        undefined
          ? null
          : this.ActivityFormGroupWizard2.value.VisitedOccupationDiseases;
      this.multipleActivityService.saveModel.menOccupationDiseases =
        this.ActivityFormGroupWizard2.value.MenOccupationDiseases == undefined
          ? null
          : this.ActivityFormGroupWizard2.value.MenOccupationDiseases;
      this.multipleActivityService.saveModel.womenOccupationDiseases =
        this.ActivityFormGroupWizard2.value.WomenOccupationDiseases == undefined
          ? null
          : this.ActivityFormGroupWizard2.value.WomenOccupationDiseases;

      this.setEmailContent();
    }

    if (this.ActivityFormGroupWizard3.valid || this.IsValidated) {
      this.multipleActivityService.saveModel.isWorkTeamJoined =
        this.ActivityFormGroupWizard3.value.IsWorkTeamJoined;

      this.multipleActivityService.saveModel.isPregnantWomenAvl =
        this.ActivityFormGroupWizard3.value.IsPregnantWomenAvl;
      this.multipleActivityService.saveModel.pregnantWomenRemark =
        this.ActivityFormGroupWizard3.value.PregnantWomenRemark;

      this.multipleActivityService.saveModel.isMinorsAvl =
        this.ActivityFormGroupWizard3.value.IsMinorsAvl;
      this.multipleActivityService.saveModel.minorsRemark =
        this.ActivityFormGroupWizard3.value.MinorsRemark;

      this.multipleActivityService.saveModel.isESensitiveAvl =
        this.ActivityFormGroupWizard3.value.IsESensitiveAvl;
      this.multipleActivityService.saveModel.eSensitiveRemark =
        this.ActivityFormGroupWizard3.value.ESensitiveRemark;

      this.multipleActivityService.saveModel.isMedicalCertificateAvl =
        this.ActivityFormGroupWizard3.value.IsMedicalCertificateAvl;
      this.multipleActivityService.saveModel.isCoordinationDone =
        this.ActivityFormGroupWizard3.value.IsCoordinationDone;
      this.multipleActivityService.saveModel.isChemicalSubstances =
        this.ActivityFormGroupWizard3.value.IsChemicalSubstances == "null"
          ? null
          : this.ActivityFormGroupWizard3.value.IsChemicalSubstances;
      this.multipleActivityService.saveModel.isWorkPlaceChanged =
        this.ActivityFormGroupWizard3.value.IsWorkPlaceChanged == "null"
          ? null
          : this.ActivityFormGroupWizard3.value.IsWorkPlaceChanged;
      this.multipleActivityService.saveModel.islegalProsecution =
        this.ActivityFormGroupWizard3.value.IslegalProsecution == "true"
          ? true
          : false;
      this.multipleActivityService.saveModel.isRiskNotifications =
        this.ActivityFormGroupWizard3.value.IsRiskNotifications == "true"
          ? true
          : false;
    }
  }

  updateActivityList(event) {
    this.multipleActivityService.saveModel.activityList =
      new Array<ActivityModel>();
    this.multipleActivityService.saveModel.activityListForClientAPI =
      new Array<ActivityRoot>();

    this.multipleActivityService.saveModel.planActionList =
      new Array<PlanActionModel>();

    const totalTime = parseInt(
      this.multipleActivityService.saveModel.activityPartDuration,
      10
    );
    let duration =
      totalTime / this.multipleActivityService.selectedActivity.length;
    duration = Math.round((duration + Number.EPSILON) * 100) / 100;

    this.multipleActivityService.selectedActivity.forEach((activity) => {
      const saveActivity = new ActivityModel();
      let saveActivityForClientAPI = new ActivityRoot();
      // let mainDetails = new EspecialidadTecnica();
      let dataInsertUser = new UsuarioUpsert();

      const activityStartTimeStr =
        this.multipleActivityService.saveModel.activityPartTime;
      // Keep the date of existing as it is
      // let activityDate = (activity.id != null && activity.id !== '') ?
      //   activity.activityDate : this.multipleActivityService.saveModel.activityPartDate;

      let activityDate =
        this.multipleActivityService.saveModel.activityPartDate;

      if (typeof activityDate === "string") {
        activityDate = new Date(activityDate);
      }

      // Javascript month starts with 0;
      const actualMonth: number = activityDate.getMonth() + 1;
      // Get activity date in YYYYMMdd format
      const activityDateStr =
        activityDate.getFullYear() +
        ("0" + actualMonth).slice(-2) +
        ("0" + activityDate.getDate()).slice(-2);

      //#region 'For base API'

      // Unique Details
      saveActivity.id = activity.id;
      saveActivity.activityTypeId = activity.activityTypeId;
      saveActivity.activityTypeName = activity.activityTypeName;
      saveActivity.duration = String(duration);
      saveActivity.description = activity.description;
      saveActivity.userId = activity.userId;
      saveActivity.isDeleted = activity.isDeleted;
      saveActivity.entryDate = activity.entryDate;

      // Common Details
      saveActivity.companyId = this.multipleActivityService.saveModel.companyId;
      saveActivity.workCentreId =
        this.multipleActivityService.saveModel.workCentreId;
      saveActivity.assignedUserId =
        this.multipleActivityService.saveModel.assignedUserId;
      (saveActivity.activityDate = this.datePipe.transform(
        this.multipleActivityService.saveModel.activityPartDate,
        "yyyy-MM-dd"
      )),
        (saveActivity.activityTime = activityStartTimeStr);
      /*NOTE:Client requirement=> Activities added in 'Parte De Visita' should be in 'Done and validated' status*/
      saveActivity.statusTypeId = "02";
      saveActivity.statusTypeName = "Realizada pdte validar";

      //#endregion

      //#region 'For client API'
      const mainDetails = {
        tipo_especialidad: activity.activityTypeId,
        evento_estado: saveActivity.statusTypeId,
        tiempo_estimado: 0,
        hora_inicio: activityStartTimeStr,
        hora_fin: activityStartTimeStr, //Client has informed to put same date
        //hora_fin: this.multipleActivityService.saveModel.activityPartFinishTime,
        empresa_id: this.multipleActivityService.saveModel.companyId,
        empresa_centro_id: this.multipleActivityService.saveModel.workCentreId,
      };

      dataInsertUser = {
        // if it is an exiting activity,keep the date as it is
        fechaHora:
          activity.id != null && activity.id !== ""
            ? activity.activityDate
            : new Date(this.multipleActivityService.saveModel.activityPartDate),
        ipOrigen: this.clientReqIPAddress,
        objectId2: "", // TODO: Not yet clarified.
      };

      saveActivityForClientAPI = {
        id: activity.id,
        alias: "",
        descripcion: activity.description,
        fecha: activityDateStr,
        hora: activityStartTimeStr,
        user_assigned: this.multipleActivityService.saveModel.assignedUserId,
        especialidadTecnica: mainDetails,
        usuario_upsert: dataInsertUser,
      };

      //#endregion
      this.multipleActivityService.saveModel.activityList.push(saveActivity);
      this.multipleActivityService.saveModel.activityListForClientAPI.push(
        saveActivityForClientAPI
      );
    });

    if (this.multipleActivityService.selectedPlanAction.length == 0) {
      const planAction = new PlanActionModel();
      planAction.isMarked = this.isMarked;
      this.multipleActivityService.saveModel.planActionList.push(planAction);
    }

    this.multipleActivityService.selectedPlanAction.forEach((item) => {
      const planAction = new PlanActionModel();
      planAction.isMarked = this.isMarked;
      planAction.title = item.title;
      planAction.description = item.description;
      planAction.action = item.action;
      planAction.cost = item.cost;
      planAction.responsible = item.responsible;
      planAction.estimatedDate = this.datePipe.transform(
        item.estimatedDate,
        "yyyy-MM-dd"
      );
      planAction.canEliminateRisk = item.canEliminateRisk;
      planAction.canMitigateRisk = item.canMitigateRisk;
      planAction.actionChekedInVisit = item.actionChekedInVisit;
      this.multipleActivityService.saveModel.planActionList.push(planAction);
    });
  }

  editListActivity(activity: Activity, index: number) {
    if (!this.IsValidated) {
      const modalRef = this.modalService.open(
        AddNewActivityComponent,
        this.modalOptions
      );
      modalRef.componentInstance.updatedActivity = activity;
      modalRef.componentInstance.activityArrayIndex = index;
      modalRef.componentInstance.isUpdate = true;
    }
  }

  editListPlanAction(planAction: PlanActionModel, index: number) {
    const modalRef = this.modalService.open(
      AddNewPlanactionComponent,
      this.modalOptions
    );
    modalRef.componentInstance.updatedPlanAction = planAction;
    modalRef.componentInstance.planActionArrayIndex = index;
    modalRef.componentInstance.isUpdate = true;
  }

  verifyActivityList() {
    if (this.multipleActivityService.selectedActivity.length > 0) {
      this.isActivityListVerified = true;
    } else {
      this.isActivityListVerified = false;
    }
  }

  // verifyPlanActionList() {
  //   if (this.multipleActivityService.selectedPlanAction.length > 0) {
  //     this.isPlanActionVerified = false;
  //   } else {
  //     this.isPlanActionVerified = true;
  //   }
  // }

  signAndValidateActivity() {
    if (this.ActivityFormGroupWizard5.valid && !this.IsValidated) {
      Swal.fire({
        title: "Una vez firmado, el parte no podrá modificarse",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          this.SetFormValuesToLatest();
          this.saveMultipleActivities();
        } else {
          this.wizardComponent.steps.forEach((v, i) => {
            v.isActive = false;
          });
          const step = this.wizardComponent.steps.filter(
            (e) => e.title === "Firma Tec."
          )[0];
          step.isActive = true;
          this.wizardComponent.goToStep(step);
          this.changeDetectorRef.detectChanges();
        }
      });
    }
  }

  clearSignature(step: number) {
    if (step == 1) {
      this.signaturePad.clear();
    } else if (step == 2) {
      this.preventionDelegateSignaturePad.clear();
    } else if (step == 3) {
      this.firmaTecSignaturePad.clear();
    }
  }

  saveParteEmailInfo() {
    if (this.ActivityFormGroupWizard5.valid || this.IsValidated) {
      this.multipleActivityService.saveModel.signedUsername =
        this.ActivityFormGroupWizard5.value.ParteContactName;
      this.multipleActivityService.saveModel.signedUserDNI =
        this.ActivityFormGroupWizard5.value.ParteContactDNI;
      this.multipleActivityService.saveModel.signatureImage = this.signaturePad
        .toDataURL()
        .replace(/^data:image\/(png|jpg);base64,/, "");
      this.changeWizardText();
    }
  }

  savePreventionDelegate() {
    if (this.PreventionDelegateForm.valid || this.IsValidated) {
      this.multipleActivityService.saveModel.preventionDelegateName =
        this.PreventionDelegateForm.value.Name;
      this.multipleActivityService.saveModel.preventionDelegateDNI =
        this.PreventionDelegateForm.value.DNI;
      this.multipleActivityService.saveModel.preventionDelegateSignature =
        this.preventionDelegateSignaturePad
          .toDataURL()
          .replace(/^data:image\/(png|jpg);base64,/, "");

      this.changeWizardText(true);
    }
  }

  async saveMultipleActivities() {
    let saveType = "";
    let errorCount = 0;
    let loopCount = 0;
    const activityCount = this.multipleActivityService.saveModel
      .activityListForClientAPI
      ? this.multipleActivityService.saveModel.activityListForClientAPI.length
      : 0;
    let responseParam = "",
      committedAction = "";
    let failedActionsStr = "";

    this.spinner.show();

    //#region 'Save actions to client API'
    const list = this.multipleActivityService.saveModel.activityListForClientAPI
      ? this.multipleActivityService.saveModel.activityListForClientAPI
      : new Array<ActivityRoot>();
    for (let i = 0; i < list.length; i++) {
      const activity = list[i];
      saveType =
        activity.id != null && activity.id !== ""
          ? "Multiple Activity=>UPDATE Action"
          : "Multiple Activity=>INSERT Action";
      activity.especialidadTecnica.hora_inicio =
        activity.especialidadTecnica.hora_inicio
          .replace(":", "")
          .replace(":", "");
      /*NOTE:Client requirement=> Status type '11' is not available in client DB.Therefore set '01' instead*/
      activity.especialidadTecnica.evento_estado =
        activity.especialidadTecnica.evento_estado === "11"
          ? "01"
          : activity.especialidadTecnica.evento_estado;

      if (this.dataSharingService.currentActuacionDataObject) {
        const dateTimeNow = new Date();
        let hora_fin = "";
        hora_fin =
          ("0" + dateTimeNow.getHours().toString()).slice(-2) +
          ("0" + dateTimeNow.getMinutes().toString()).slice(-2) +
          ("0" + dateTimeNow.getSeconds().toString()).slice(-2);
        this.dataSharingService.currentActuacionDataObject.especialidadTecnica.hora_fin =
          hora_fin;
        await this.activityService.saveActivity(
          this.dataSharingService.currentActuacionDataObject
        );
      }

      await this.activityService.saveActivity(activity).then(
        (response) => {
          loopCount += 1;
          responseParam = JSON.stringify(response);

          // Check for error
          if (response.webError) {
            errorCount += 1;
            committedAction = saveType + " failed";
            failedActionsStr +=
              `Actuacion ${loopCount.toString()}-${
                response.webError.messageToUser
              }` + "<br/>";
          } else {
            committedAction = saveType + " success";
            if (this.isActivityListVerified && this.isClosedClicked) {
              this.activityService.fetchActivityList();
            }
          }
          const indexedDBRecord = {
            userId: JSON.parse(localStorage.getItem("loggeduser")).id,
            activityId: activity.id,
            loggedInTime: "",
            loggedOutTime: "",
            committedAction: committedAction,
            requestParam: JSON.stringify(activity),
            responseParam: responseParam,
            logDate: this.logActivityService.getDateInCustomFormat(new Date()),
          };
          this.logActivityService.saveDataToIndexedDB(
            "useractivity",
            indexedDBRecord
          );
        },
        (error) => {
          errorCount += 1;
          responseParam = JSON.stringify(error);
          committedAction = saveType + " failed";
          const indexedDBRecord = {
            userId: JSON.parse(localStorage.getItem("loggeduser")).id,
            activityId: activity.id,
            loggedInTime: "",
            loggedOutTime: "",
            committedAction: committedAction,
            requestParam: JSON.stringify(activity),
            responseParam: responseParam,
            logDate: this.logActivityService.getDateInCustomFormat(new Date()),
          };
          this.logActivityService.saveDataToIndexedDB(
            "useractivity",
            indexedDBRecord
          );
          this.spinner.hide();
          this.errorHandler.handle(error);
          document
            .getElementsByClassName("card-footer")[0]
            .removeAttribute("hidden");
        }
      );
    }

    //#endregion

    //#region 'Save actions to base API'
    // NOTE: Execute the base API email sending function only if the client API function is successful
    if (errorCount === 0) {
      this.saveMultiActivityMasterData(true);
      this.IsValidated = true;
      this.SelectedParteDeVisitaState = this.ParteDeVisitaStates.Validated;
      this.changeWizardText();
      this.setParteDeVisitaState();
    } else {
      this.spinner.hide();
      const message =
        errorCount.toString() +
        " de cada " +
        activityCount.toString() +
        " acciones fallaron para salvar." +
        "<br/>" +
        failedActionsStr;
      this.errorHandler.handle(message, true);
      document
        .getElementsByClassName("card-footer")[0]
        .removeAttribute("hidden");
    }
  }

  private InitWizard1DropDowns() {
    this.dropdownService.getCompanies().subscribe(
      (response) => {
        this.companies$ = this.dropdownService.WrappedToDropDownList(
          response,
          "name",
          "companyId"
        );
      },
      (error) => {
        this.errorHandler.handle(error);
      }
    );
    this.dropdownService.getWorkingCentres().subscribe(
      (response) => {
        this.workingCentres$ = this.dropdownService.WrappedToDropDownList(
          response,
          "centreName",
          "id"
        );
      },
      (error) => {
        this.errorHandler.handle(error);
      }
    );
    this.dropdownService.getUsers().subscribe(
      (response) => {
        this.users$ = this.dropdownService.WrappedToDropDownList(
          response,
          "firstName",
          "id"
        );
      },
      (error) => {
        this.errorHandler.handle(error);
      }
    );
  }

  getCompanyWorkCentres(selectedOption: any) {
    this.ActivityFormGroupWizard1.patchValue({
      WorkCentreId: null,
    });
    this.dropdownService
      .getWorkingCentresByCompany(selectedOption.value)
      .subscribe(
        (response) => {
          this.workingCentres$ = this.dropdownService.WrappedToDropDownList(
            response,
            "centreName",
            "id"
          );
        },
        (error) => {
          this.errorHandler.handle(error);
        }
      );
  }

  getWorkCentreWorkerCount(workCentreId: any) {
    let result = null;
    this.dropdownService.getWorkCentreDetails(workCentreId).subscribe(
      (response) => {
        result = response == null ? null : response.noOfWorkers;
        this.ActivityFormGroupWizard2.patchValue({
          WorkCentreWorkerCount: result,
        });
      },
      (error) => {
        this.errorHandler.handle(error);
        this.ActivityFormGroupWizard2.patchValue({
          WorkCentreWorkerCount: null,
        });
      }
    );
  }

  isPatientCountValid() {
    if (
      this.ActivityFormGroupWizard2.value.IsAccidentOccured === "true" &&
      this.ActivityFormGroupWizard2.value.WomenAccidents == null &&
      this.ActivityFormGroupWizard2.value.MenAccidents == null &&
      this.ActivityFormGroupWizard2.value.VisitedAccidents == null &&
      this.ActivityFormGroupWizard2.value.InvestigatedAccidents == null
    ) {
      return false;
    }
    return true;
  }

  isDiseasesCountValid() {
    if (
      this.ActivityFormGroupWizard2.value.IsOccupationDiseases === "true" &&
      this.ActivityFormGroupWizard2.value.MenOccupationDiseases == null &&
      this.ActivityFormGroupWizard2.value.WomenOccupationDiseases == null &&
      this.ActivityFormGroupWizard2.value.InvestigatedOccupationDiseases ==
        null &&
      this.ActivityFormGroupWizard2.value.VisitedOccupationDiseases == null
    ) {
      return false;
    }
    return true;
  }

  isNewWorkerCountValid() {
    if (
      this.ActivityFormGroupWizard2.value.IsNewWorkerAdded === "true" &&
      this.ActivityFormGroupWizard2.value.StaffMen == null &&
      this.ActivityFormGroupWizard2.value.StaffWomen == null &&
      this.ActivityFormGroupWizard2.value.ETTMen == null &&
      this.ActivityFormGroupWizard2.value.ETTWomen == null
    ) {
      return false;
    }
    return true;
  }

  checkForNewWorkerEnableStatus() {
    if (this.ActivityFormGroupWizard2.value.IsNewWorkerAdded === "true") {
      this.isNewWorkerInputEnabled = true;
      this.ActivityFormGroupWizard2.get("StaffMen").enable();
      this.ActivityFormGroupWizard2.get("StaffMen").markAsTouched();
      this.ActivityFormGroupWizard2.get("StaffWomen").enable();
      this.ActivityFormGroupWizard2.get("StaffWomen").markAsTouched();
      this.ActivityFormGroupWizard2.get("ETTMen").enable();
      this.ActivityFormGroupWizard2.get("ETTMen").markAsTouched();
      this.ActivityFormGroupWizard2.get("ETTWomen").enable();
      this.ActivityFormGroupWizard2.get("ETTWomen").markAsTouched();
    } else {
      this.isNewWorkerInputEnabled = false;
      this.ActivityFormGroupWizard2.patchValue({
        StaffMen: null,
        StaffWomen: null,
        ETTMen: null,
        ETTWomen: null,
      });
      this.ActivityFormGroupWizard2.get("StaffMen").disable();
      this.ActivityFormGroupWizard2.get("StaffMen").markAsUntouched();
      this.ActivityFormGroupWizard2.get("StaffWomen").disable();
      this.ActivityFormGroupWizard2.get("StaffWomen").markAsUntouched();
      this.ActivityFormGroupWizard2.get("ETTMen").disable();
      this.ActivityFormGroupWizard2.get("ETTMen").markAsUntouched();
      this.ActivityFormGroupWizard2.get("ETTWomen").disable();
      this.ActivityFormGroupWizard2.get("ETTWomen").markAsUntouched();
    }
  }

  checkForDiseasesEnableStatus() {
    if (this.ActivityFormGroupWizard2.value.IsOccupationDiseases === "true") {
      this.isDiseasesInputEnabled = true;
      this.ActivityFormGroupWizard2.get("MenOccupationDiseases").enable();
      this.ActivityFormGroupWizard2.get(
        "MenOccupationDiseases"
      ).markAsTouched();
      this.ActivityFormGroupWizard2.get("WomenOccupationDiseases").enable();
      this.ActivityFormGroupWizard2.get(
        "WomenOccupationDiseases"
      ).markAsTouched();
      this.ActivityFormGroupWizard2.get(
        "InvestigatedOccupationDiseases"
      ).enable();
      this.ActivityFormGroupWizard2.get(
        "InvestigatedOccupationDiseases"
      ).markAsTouched();
      this.ActivityFormGroupWizard2.get("VisitedOccupationDiseases").enable();
      this.ActivityFormGroupWizard2.get(
        "VisitedOccupationDiseases"
      ).markAsTouched();
    } else {
      this.isDiseasesInputEnabled = false;
      this.ActivityFormGroupWizard2.patchValue({
        MenOccupationDiseases: null,
        WomenOccupationDiseases: null,
        InvestigatedOccupationDiseases: null,
        VisitedOccupationDiseases: null,
      });
      this.ActivityFormGroupWizard2.get("MenOccupationDiseases").disable();
      this.ActivityFormGroupWizard2.get(
        "MenOccupationDiseases"
      ).markAsUntouched();
      this.ActivityFormGroupWizard2.get("WomenOccupationDiseases").disable();
      this.ActivityFormGroupWizard2.get(
        "WomenOccupationDiseases"
      ).markAsUntouched();
      this.ActivityFormGroupWizard2.get(
        "InvestigatedOccupationDiseases"
      ).disable();
      this.ActivityFormGroupWizard2.get(
        "InvestigatedOccupationDiseases"
      ).markAsUntouched();
      this.ActivityFormGroupWizard2.get("VisitedOccupationDiseases").disable();
      this.ActivityFormGroupWizard2.get(
        "VisitedOccupationDiseases"
      ).markAsUntouched();
    }
  }

  checkForPatientCountEnableStatus() {
    if (this.ActivityFormGroupWizard2.value.IsAccidentOccured === "true") {
      this.isPatientCountInputEnabled = true;
      this.ActivityFormGroupWizard2.get("MenAccidents").enable();
      this.ActivityFormGroupWizard2.get("MenAccidents").markAsTouched();
      this.ActivityFormGroupWizard2.get("WomenAccidents").enable();
      this.ActivityFormGroupWizard2.get("WomenAccidents").markAsTouched();
      this.ActivityFormGroupWizard2.get("VisitedAccidents").enable();
      this.ActivityFormGroupWizard2.get("VisitedAccidents").markAsTouched();
      this.ActivityFormGroupWizard2.get("InvestigatedAccidents").enable();
      this.ActivityFormGroupWizard2.get(
        "InvestigatedAccidents"
      ).markAsTouched();
    } else {
      this.isPatientCountInputEnabled = false;
      this.ActivityFormGroupWizard2.patchValue({
        WomenAccidents: null,
        MenAccidents: null,
        // TotalAccidents: null,
        VisitedAccidents: null,
        InvestigatedAccidents: null,
      });
      this.ActivityFormGroupWizard2.get("MenAccidents").disable();
      this.ActivityFormGroupWizard2.get("MenAccidents").markAsUntouched();
      this.ActivityFormGroupWizard2.get("WomenAccidents").disable();
      this.ActivityFormGroupWizard2.get("WomenAccidents").markAsUntouched();
      this.ActivityFormGroupWizard2.get("VisitedAccidents").disable();
      this.ActivityFormGroupWizard2.get("VisitedAccidents").markAsUntouched();
      this.ActivityFormGroupWizard2.get("InvestigatedAccidents").disable();
      this.ActivityFormGroupWizard2.get(
        "InvestigatedAccidents"
      ).markAsUntouched();
    }

    this.ActivityFormGroupWizard2.updateValueAndValidity();
  }

  public saveParteDeVisitaState(): void {
    this.isClosedClicked = true;
    if (!this.IsValidated) {
      this.SetFormValuesToLatest();
      this.saveMultiActivityMasterData(false);
    }
    if (this.isActivityListVerified && this.isClosedClicked) {
      this.activityService.fetchActivityList();
    }
    this.multipleActivityModal.close();
  }

  private getSaveModel(isValidated: boolean): MultipleActivitySaveModel {
    this.multipleActivityService.saveModel.LoggedUserName = this.userDetails?.nombre + ' ' + this.userDetails?.apellidos;
    const saveModel = this.multipleActivityService.saveModel;
    saveModel.isValidated = isValidated;
    saveModel.sendEmailToUserOnly = false;
    const emailList = this.emailAddresses;
    saveModel.recipientEmail = emailList.map((item) => item.display);
    saveModel.emailSubject = this.emailSubject
      ? this.emailSubject
      : this.ActivityFormGroupWizard6.value.EmailSubject;
    saveModel.emailBody = this.emailBody
      ? this.emailBody
      : this.ActivityFormGroupWizard6.value.EmailBody;
    saveModel.description = this.ActivityFormGroupWizard1.value.Description;
    saveModel.userId = JSON.parse(localStorage.getItem("loggeduser")).id;

    // Get the time in the format HH:mm:ss
    if (
      typeof saveModel.activityPartTime === "string" &&
      !saveModel.activityPartTime.includes(":")
    ) {
      const startTime = saveModel.activityPartTime;
      saveModel.activityPartTime =
        startTime.substr(0, 2) +
        ":" +
        startTime.substr(2, 2) +
        ":" +
        startTime.substr(4, 2);
    }

    const actualMonth: number = saveModel.activityPartDate.getMonth() + 1;
    const parentDate: Date = saveModel.activityPartDate;
    saveModel.activityPartDateStr = `${parentDate
      .getDate()
      .toString()}/${actualMonth.toString()}/${parentDate
      .getFullYear()
      .toString()}`;

    if (saveModel.activityList) {
      saveModel.activityList.forEach((a) => {
        a.activityDate = this.datePipe.transform(
          saveModel.activityPartDate,
          "yyyy-MM-dd"
        );
        // Get the time in the format HH:mm:ss
        a.activityTime =
          a.activityTime.substr(0, 2) +
          ":" +
          a.activityTime.substr(2, 2) +
          ":" +
          a.activityTime.substr(4, 2);
      });
    }
    // Get Last Active Wizard Step
    const activeSteps = this.wizardComponent.steps.filter((e) => !e.isDisabled);
    saveModel.currentStepTitle = activeSteps[activeSteps.length - 1].title;

    return saveModel;
  }

  private saveMultiActivityMasterData(isValidated: boolean): void {
    const saveModel = this.getSaveModel(isValidated);
    this.masterDataService.saveMultipleActivities(saveModel).then(
      async (res) => {
        this.multiActivitySaveSuccess = true;

        // Update Visit Parent Activity Status  as pending
        await this.activityService
          .getActivity(this.ActivityFormGroupWizard1.value.VisitActivityId)
          .then(
            async (response) => {
              const activity =
                this.activityService.mapActivityResponseToActivityModel(
                  response
                );
              const startDate = activity.activityDate;
              const month = startDate.getMonth() + 1;
              // Get the date in the format dd/MM/yyyy
              activity.activityDate =
                ("0" + startDate.getDate()).slice(-2) +
                "/" +
                ("0" + month).slice(-2) +
                "/" +
                startDate.getFullYear();

              const activityRoot =
                this.activityService.mapActivityModelToActivityRoot(activity);
              /*NOTE:Client requirement=> Status type '11' is not available in client DB.Therefore set  '01' instead*/
              activityRoot.especialidadTecnica.evento_estado =
                activityRoot.especialidadTecnica.evento_estado === "11"
                  ? "01"
                  : activityRoot.especialidadTecnica.evento_estado;
              activityRoot.descripcion =
                this.ActivityFormGroupWizard1.value.Description;

              this.activityService.saveActivity(activityRoot).then(
                (updateResponse) => {
                  // Check for error
                  if (updateResponse.webError != null) {
                    this.errorHandler.handle(
                      updateResponse.webError.messageToUser,
                      true
                    );
                  } else {
                    if (this.isActivityListVerified && this.isClosedClicked) {
                      this.activityService.fetchActivityList();
                    }
                  }
                },
                (error) => {
                  this.spinner.hide();
                  this.errorHandler.handle(error);
                }
              );
            },
            (error) => {
              this.errorHandler.handle(error);
            }
          );

        this.spinner.hide();

        if (String(res).toLowerCase().includes("error")) {
          this.toastrService.error(
            "No pudo validar y firmar la parte de visita",
            "Error"
          );
        } else {
          this.toastrService.success(
            "validado y firmado con éxito la parte de visita",
            "Éxito"
          );
        }
      },
      (err) => {
        const responseParam = JSON.stringify(err);
        const indexedDBRecord = {
          userId: JSON.parse(localStorage.getItem("loggeduser")).id,
          activityId: "",
          loggedInTime: "",
          loggedOutTime: "",
          committedAction: "Multiple Activity=>Base API=>Send email",
          requestParam: JSON.stringify(saveModel),
          responseParam: responseParam,
          logDate: this.logActivityService.getDateInCustomFormat(new Date()),
        };
        this.logActivityService.saveDataToIndexedDB(
          "useractivity",
          indexedDBRecord
        );
        this.spinner.hide();

        if (!err) {
          this.errorHandler.handle(
            "Se produjo un error interno al validar y firmar",
            true
          );
        } else {
          this.errorHandler.handle(err);
        }
      }
    );
  }

  private async fetchUserDetails(): Promise<any> {
    const reqObject = {
      id: this.userDetailsId,
      usuario_upsert: {
        userId: JSON.parse(localStorage.getItem('loggeduser')).id,
        fechaHora: new Date(),
        ipOrigen: null,
        objectId2: null
      }
    };
  
    try {
      const res = await this.memoriaAnualService.getUserDetailsAndSignature(reqObject).toPromise();
      return res?.data;
    } catch (err) {
      throw err;
    }
  }
  
  public async downloadAttachment(): Promise<void> {
    try {
      this.spinner.show();
  
      // Fetch user details before proceeding
     this.userDetails = await this.fetchUserDetails();
  
      this.SetFormValuesToLatest();
      const saveModel = this.getSaveModel(true);
  
      const response = await this.masterDataService.downloadParteDeVisitaAttachment(saveModel).toPromise();
  
      const linkSource = "data:application/pdf;base64," + response.base64String + "\n";
      const downloadLink = document.createElement("a");
      const fileName = response.fileName;
  
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
  
    } catch (error) {
      this.toastrService.error("No se pudo descargar el archivo adjunto", "Error");
      this.errorHandler.handle(error);
    } finally {
      this.spinner.hide();
    }
  }

  public async sendParteDeVisitaEmail(): Promise<void>{
    this.spinner.show();
    this.IsFooterHidden = true;
    this.emailSubject = this.ActivityFormGroupWizard6.value.EmailSubject;
    this.emailBody = this.ActivityFormGroupWizard6.value.EmailBody;

    this.SetFormValuesToLatest();
    this.userDetails = await this.fetchUserDetails();
    const saveModel = this.getSaveModel(true);

    this.masterDataService.sendParteDeVisitaEmail(saveModel).then(
      async (res) => {
        document
          .getElementsByClassName("card-footer")[0]
          .removeAttribute("hidden");
        this.IsFooterHidden = false;
        this.spinner.hide();
        if (String(res).toLowerCase().includes("error")) {
          this.toastrService.error(
            "Se guardó correctamente, pero no se envió un correo electrónico ya que no se especificó ninguno en la aplicación",
            "Error"
          );
        } else {
          this.toastrService.success(
            "Guardado correctamente, se le enviará un correo electrónico si ha solicitado uno",
            "Éxito"
          );
          this.multipleActivityModal.close();
        }
      },
      (err) => {
        document
          .getElementsByClassName("card-footer")[0]
          .removeAttribute("hidden");
        this.IsFooterHidden = false;
        const responseParam = JSON.stringify(err);
        const indexedDBRecord = {
          userId: JSON.parse(localStorage.getItem("loggeduser")).id,
          activityId: "",
          loggedInTime: "",
          loggedOutTime: "",
          committedAction: "Multiple Activity=>Base API=>Send email",
          requestParam: JSON.stringify(saveModel),
          responseParam: responseParam,
          logDate: this.logActivityService.getDateInCustomFormat(new Date()),
        };
        this.logActivityService.saveDataToIndexedDB(
          "useractivity",
          indexedDBRecord
        );
        this.spinner.hide();

        if (!err) {
          this.errorHandler.handle(
            "Se produjo un error interno al enviar el correo electrónico",
            true
          );
        } else {
          this.errorHandler.handle(err);
        }
      }
    );
  }

  private SetFormValuesToLatest(): void {
    this.saveQuestionnaire();
    this.updateActivityList(null);
    this.updateFormdata1();
    this.updateFormData2()
    this.saveParteEmailInfo();
    this.savePreventionDelegate();
    // this.multipleActivityService.saveModel.preventionDelegateName = this.PreventionDelegateForm.value.Name;
    // this.multipleActivityService.saveModel.preventionDelegateDNI = this.PreventionDelegateForm.value.DNI;
    this.multipleActivityService.saveModel.firmaTecSignature =
      this.firmaTecSignaturePad
        .toDataURL()
        .replace(/^data:image\/(png|jpg);base64,/, "");
  }

  public setEmailContent(): void {
    let emailSubject = "";
    let emailBody = "";

    if (this.ActivityFormGroupWizard2.value.TemplateLanguage === "es") {
      emailSubject = "[GESEME] Comprobante de visita";
      emailBody =
        `Apreciado/a cliente. \n\n` +
        `Después de la visita realizada en el día de hoy, le adjunto el comprobante de visita originado por la misma. ` +
        `Ruego archive este documento, junto con el resto de documentos de prevención de riesgos laborales. \n\n` +
        `Atentamente,`;
    } else {
      emailSubject = "[GESEME] Comprovant de visita";
      emailBody =
        `Benvolgut/da client, \n\n` +
        `Després de la visita realitzada en el dia d'avui, adjunto en aquest email el comprovant de visita. ` +
        `Prego que arxivi aquest document, juntament amb la resta de documents de prevenció de riscos laborals. \n\n` +
        `Atentament,`;
    }

    this.ActivityFormGroupWizard6.patchValue({
      EmailSubject: emailSubject,
      EmailBody: emailBody,
    });
  }

  focusOutWorkCentreWorker() {
    let men = this.ActivityFormGroupWizard2.controls.NoMen.value;
    let women = this.ActivityFormGroupWizard2.controls.NoWomen.value;
    let workCenterTotal =
      this.ActivityFormGroupWizard2.controls.WorkCentreWorkerCount.value;

    if (men != null || women != null) {
      let total = men + women;
      if (total > workCenterTotal) {
        this.ActivityFormGroupWizard2.controls.NoMen.setValue(null);
        this.ActivityFormGroupWizard2.controls.NoWomen.setValue(null);
        this.ActivityFormGroupWizard2.controls.WorkCentreWorkerCount.setValue(
          null
        );
        this.toastrService.warning(
          "Total nunca podría ser menor que la suma de ambas"
        );
      }
    }
  }

  focusOutMenandWomen() {
    let men = this.ActivityFormGroupWizard2.controls.NoMen.value;
    let women = this.ActivityFormGroupWizard2.controls.NoWomen.value;

    if (men != null || women != null) {
      let total = men + women;
      if (total >= 500) {
        this.ActivityFormGroupWizard2.controls.NoMen.setValue(null);
        this.ActivityFormGroupWizard2.controls.NoWomen.setValue(null);
        this.ActivityFormGroupWizard2.controls.WorkCentreWorkerCount.setValue(
          null
        );
        this.toastrService.warning("Ingrese un número entre 1 y 500");
      } else {
        this.ActivityFormGroupWizard2.controls.WorkCentreWorkerCount.setValue(
          total
        );
      }
    }
  }


  async updateFormdata1() {
    Object.keys(this.stepform6.controls).forEach((controlName) => {
      switch (controlName) {
        case "trabajos_altura":
          this.multipleActivityService.saveModel.TrabajosAltura =
            this.stepform6.controls[controlName].value;
          break;
        case "trabajos_verticales":
          this.multipleActivityService.saveModel.TrabajosVerticales =
            this.stepform6.controls[controlName].value;
          break;
        case "plataformas_elevadoras":
          this.multipleActivityService.saveModel.PlataformasElevadoras =
            this.stepform6.controls[controlName].value;
          break;
        case "carretillas_elevadoras":
          this.multipleActivityService.saveModel.CarretillasElevadoras =
            this.stepform6.controls[controlName].value;
          break;
        case "transpaletas_apiladores":
          this.multipleActivityService.saveModel.TranspaletasApiladores =
            this.stepform6.controls[controlName].value;
          break;
        case "incendios":
          this.multipleActivityService.saveModel.Incendios =
            this.stepform6.controls[controlName].value;
          break;
        case "primeros_auxilios":
          this.multipleActivityService.saveModel.PrimerosAuxilios =
            this.stepform6.controls[controlName].value;
          break;
        case "espacios_confinados":
          this.multipleActivityService.saveModel.EspaciosConfinados =
            this.stepform6.controls[controlName].value;
          break;
        case "consignacion_maquinas":
          this.multipleActivityService.saveModel.ConsignacionMaquinas =
            this.stepform6.controls[controlName].value;
          break;
        case "riesgo_electrico":
          this.multipleActivityService.saveModel.RiesgoElectrico =
            this.stepform6.controls[controlName].value;
          break;
        case "riesgo_quimico":
          this.multipleActivityService.saveModel.RiesgoQuimico =
            this.stepform6.controls[controlName].value;
          break;
        case "riesgo_biologico":
          this.multipleActivityService.saveModel.RiesgoBiologico =
            this.stepform6.controls[controlName].value;
          break;
        case "riesgo_ergonomico":
          this.multipleActivityService.saveModel.RiesgoErgonomico =
            this.stepform6.controls[controlName].value;
          break;
        case "nivel_basico_prl":
          this.multipleActivityService.saveModel.NivelBasicoPRL =
            this.stepform6.controls[controlName].value;
          break;
        case "delegados_prevencion":
          this.multipleActivityService.saveModel.DelegadosPrevencion =
            this.stepform6.controls[controlName].value;
          break;
        case "seguridad_vial":
          this.multipleActivityService.saveModel.SeguridadVial =
            this.stepform6.controls[controlName].value;
          break;
        case "teletrabajo":
          this.multipleActivityService.saveModel.Teletrabajo =
            this.stepform6.controls[controlName].value;
          break;
        case "otros_formacion":
          this.multipleActivityService.saveModel.OtrosFormacion =
            this.stepform6.controls[controlName].value;
          break;
        case "admin_20h":
          this.multipleActivityService.saveModel.Admin20H =
            this.stepform6.controls[controlName].value;
          break;
        case "directivos_10h":
          this.multipleActivityService.saveModel.Directivos10H =
            this.stepform6.controls[controlName].value;
          break;
        case "mandos_intermedios_20h":
          this.multipleActivityService.saveModel.MandosIntermedios20H =
            this.stepform6.controls[controlName].value;
          break;
        case "responsables_tecnicos_20h":
          this.multipleActivityService.saveModel.ResponsablesTecnicos20H =
            this.stepform6.controls[controlName].value;
          break;
        case "nivel_basico_prl_60h":
          this.multipleActivityService.saveModel.NivelBasicoPRL60H =
            this.stepform6.controls[controlName].value;
          break;
        case "delegados_prevencion_70h":
          this.multipleActivityService.saveModel.DelegadosPrevencion70H =
            this.stepform6.controls[controlName].value;
          break;
        case "oficios_20h":
          this.multipleActivityService.saveModel.Oficios20H =
            this.stepform6.controls[controlName].value;
          break;
        case "oficios_6h":
          this.multipleActivityService.saveModel.Oficios6H =
            this.stepform6.controls[controlName].value;
          break;
        case "admin_metal_obra_20h":
          this.multipleActivityService.saveModel.AdminMetalObra20H =
            this.stepform6.controls[controlName].value;
          break;
        case "directivos_metal_obra_10h":
          this.multipleActivityService.saveModel.DirectivosMetalObra10H =
            this.stepform6.controls[controlName].value;
          break;
        case "mandos_intermedios_metal_obra_20h":
          this.multipleActivityService.saveModel.MandosIntermediosMetalObra20H =
            this.stepform6.controls[controlName].value;
          break;
        case "responsables_tecnicos_metal_obra_20h":
          this.multipleActivityService.saveModel.ResponsablesTecnicosMetalObra20H =
            this.stepform6.controls[controlName].value;
          break;
        case "nivel_basico_prl_metal_obra_60h":
          this.multipleActivityService.saveModel.NivelBasicoPRLMetalObra60H =
            this.stepform6.controls[controlName].value;
          break;
        case "delegados_prevencion_metal_obra_50h":
          this.multipleActivityService.saveModel.DelegadosPrevencionMetalObra50H =
            this.stepform6.controls[controlName].value;
          break;
        case "oficios_metal_obra_20h":
          this.multipleActivityService.saveModel.OficiosMetalObra20H =
            this.stepform6.controls[controlName].value;
          break;
        case "oficios_metal_obra_6h":
          this.multipleActivityService.saveModel.OficiosMetalObra6H =
            this.stepform6.controls[controlName].value;
          break;
        case "reciclaje_oficios_metal_obra_4h":
          this.multipleActivityService.saveModel.ReciclajeOficiosMetalObra4H =
            this.stepform6.controls[controlName].value;
          break;
        case "personal_oficines":
          this.multipleActivityService.saveModel.PersonalDOficines =
            this.stepform6.controls[controlName].value;
          break;
        case "directius":
          this.multipleActivityService.saveModel.Directius =
            this.stepform6.controls[controlName].value;
          break;
        case "delegats_de_prevenció":
          this.multipleActivityService.saveModel.DelegatsDePrevencio =
            this.stepform6.controls[controlName].value;
          break;
        case "nivell_bàsic":
          this.multipleActivityService.saveModel.NivellBasic =
            this.stepform6.controls[controlName].value;
          break;
        case "oficis_producció":
          this.multipleActivityService.saveModel.OficisProduccioIManteniment =
            this.stepform6.controls[controlName].value;
          break;
        case "conductor_grues":
          this.multipleActivityService.saveModel.ConductorGruesMobilsAutopropulsades =
            this.stepform6.controls[controlName].value;
          break;
        case "altres_oficis":
          this.multipleActivityService.saveModel.AltresOficisNoEspecificatsEnConveni =
            this.stepform6.controls[controlName].value;
          break;
        case "producció":
          this.multipleActivityService.saveModel.OficisProduccioIMantenimentYConductorGruesMobilsAutopropulsades =
            this.stepform6.controls[controlName].value;
          break;
        case "reciclatge_oficis":
          this.multipleActivityService.saveModel.ReciclatgeOficisOficinesIDirectius =
            this.stepform6.controls[controlName].value;
          break;
        case "directivos_10":
          this.multipleActivityService.saveModel.Directius10 =
            this.stepform6.controls[controlName].value;
          break;
        case "administrativos_10":
          this.multipleActivityService.saveModel.Administratius =
            this.stepform6.controls[controlName].value;
          break;
        case "responsables_20":
          this.multipleActivityService.saveModel.ResponsablesObraITècnicsExecució =
            this.stepform6.controls[controlName].value;
          break;
        case "mandos_20":
          this.multipleActivityService.saveModel.ComandamentsIntermedis =
            this.stepform6.controls[controlName].value;
          break;
        case "delegados_50":
          this.multipleActivityService.saveModel.DelegatsDePrevenció =
            this.stepform6.controls[controlName].value;
          break;

        case "Oficios_20h":
          this.multipleActivityService.saveModel.OficisVintHores =
            this.stepform6.controls[controlName].value;
          break;
        case "Oficinas_6":
          this.multipleActivityService.saveModel.OficisSisHores =
            this.stepform6.controls[controlName].value;
          break;
        case "nivel_básico":
          this.multipleActivityService.saveModel.NivellBàsic =
            this.stepform6.controls[controlName].value;
          break;
        case "administración_6h":
          this.multipleActivityService.saveModel.Administratius10 =
            this.stepform6.controls[controlName].value;
          break;
        case "directivos_10hora":
          this.multipleActivityService.saveModel.Directius10H =
            this.stepform6.controls[controlName].value;
          break;
        case "oficinas_20hora":
          this.multipleActivityService.saveModel.OficisVintHores20 =
            this.stepform6.controls[controlName].value;
          break;
        case "nivel_básico_60hora":
          this.multipleActivityService.saveModel.NivellBàsic60 =
            this.stepform6.controls[controlName].value;
          break;
        case "agentes_químicos":
          this.multipleActivityService.saveModel.ChemicalAgents =
            this.stepform6.controls[controlName].value;
          break;
        case "agentes_biológicos":
          this.multipleActivityService.saveModel.BiologicalAgents =
            this.stepform6.controls[controlName].value;
          break;
        case "sonometría":
          this.multipleActivityService.saveModel.Sonometria =
            this.stepform6.controls[controlName].value;
          break;
        case "dosimetría":
          this.multipleActivityService.saveModel.Dosimetria =
            this.stepform6.controls[controlName].value;
          break;
        case "radiaciones_ionizantes":
          this.multipleActivityService.saveModel.IonizingRadiations =
            this.stepform6.controls[controlName].value;
          break;
        case "campos_electromagnéticos":
          this.multipleActivityService.saveModel.ElectromagneticFields =
            this.stepform6.controls[controlName].value;
          break;
        case "electricidad_estática":
          this.multipleActivityService.saveModel.StaticElectricity =
            this.stepform6.controls[controlName].value;
          break;
        case "estrés_térmico":
          this.multipleActivityService.saveModel.ThermalStress =
            this.stepform6.controls[controlName].value;
          break;
        case "vibraciones":
          this.multipleActivityService.saveModel.Vibrations =
            this.stepform6.controls[controlName].value;
          break;
        case "otros":
          this.multipleActivityService.saveModel.Other =
            this.stepform6.controls[controlName].value;
          break;
        case "manipulación_manual_de_cargas":
          this.multipleActivityService.saveModel.ManipulacioManualDeCargues =
            this.stepform6.controls[controlName].value;
          break;
        case "movimientos_repetitivos":
          this.multipleActivityService.saveModel.MovimentsRepetitius =
            this.stepform6.controls[controlName].value;
          break;
        case "posturas_forzadas":
          this.multipleActivityService.saveModel.PosturesForçades =
            this.stepform6.controls[controlName].value;
          break;
        case "estudi_psicosocial":
          this.multipleActivityService.saveModel.EstudiPsicosocial =
            this.stepform6.controls[controlName].value;
          break;
        case "protocol_assetjament":
          this.multipleActivityService.saveModel.ProtocolAssetjament =
            this.stepform6.controls[controlName].value;
          break;

        case "pla_digualtat":
          this.multipleActivityService.saveModel.PlaDigualtat =
            this.stepform6.controls[controlName].value;
          break;
        case "emmagatzematge_productes_químics":
          this.multipleActivityService.saveModel.ChemicalStorage =
            this.stepform6.controls[controlName].value;
          break;
        case "document_proteccio_contra_explosions":
          this.multipleActivityService.saveModel.ExplosionProtectionDocument =
            this.stepform6.controls[controlName].value;
          break;
        case "informe_adequacio_maquines":
          this.multipleActivityService.saveModel.MachineryAdequacyReport =
            this.stepform6.controls[controlName].value;
          break;
        case "pla_mobilitat_seguretat_viaria":
          this.multipleActivityService.saveModel.MobilityAndRoadSafetyPlan =
            this.stepform6.controls[controlName].value;
          break;
        case "profesiograma":
          this.multipleActivityService.saveModel.Profesiogram =
            this.stepform6.controls[controlName].value;
          break;
        case "agentes_químicos_text":
          this.multipleActivityService.saveModel.AgentesQuimicosText =
            this.stepform6.controls[controlName].value;

          break;
        case "agentes_biológicos_text":
          this.multipleActivityService.saveModel.AgentesBiologicosText =
            this.stepform6.controls[controlName].value;

          break;

        case "otros_text":
          this.multipleActivityService.saveModel.OtrosText =
            this.stepform6.controls[controlName].value;
          break;

        case "estudi_psicosocial_text":
          this.multipleActivityService.saveModel.EstudiPsicosocialText =
            this.stepform6.controls[controlName].value;
          break;

        case "protocol_assetjament_text":
          this.multipleActivityService.saveModel.ProtocolAssetjamentText =
            this.stepform6.controls[controlName].value;
          break;

        case "pla_digualtat_text":
          this.multipleActivityService.saveModel.PlaDigualtatText =
            this.stepform6.controls[controlName].value;
          break;

        case "emmagatzematge_productes_químics_text":
          this.multipleActivityService.saveModel.EmmagatzematgeProductesQuimicsText =
            this.stepform6.controls[controlName].value;
          break;

        case "document_proteccio_contra_explosions_text":
          this.multipleActivityService.saveModel.DocumentProteccioContraExplosionsText =
            this.stepform6.controls[controlName].value;
          break;

        case "informe_adequacio_maquines_text":
          this.multipleActivityService.saveModel.InformeAdequacioMaquinesText =
            this.stepform6.controls[controlName].value;
          break;

        case "pla_mobilitat_seguretat_viaria_text":
          this.multipleActivityService.saveModel.PlaMobilitatSeguretatViariaText =
            this.stepform6.controls[controlName].value;
          break;

        case "profesiograma_text":
          this.multipleActivityService.saveModel.ProfesiogramaText =
            this.stepform6.controls[controlName].value;
          break;
        default:
          break;
      }
    });
  }

  updateFormData2() {
    Object.keys(this.stepform7.controls).forEach((controlName) => {
      switch (controlName) {
        case "llistat_personal":
          this.multipleActivityService.saveModel.LlistatPersonal =
            this.stepform7.controls[controlName].value;
          break;
        case "llistat_maquinaria":
          this.multipleActivityService.saveModel.LlistatMaquinaria =
            this.stepform7.controls[controlName].value;
          break;
        case "fitxes_dades":
          this.multipleActivityService.saveModel.FitxesDades =
            this.stepform7.controls[controlName].value;
          break;
        case "procediments_instr":
          this.multipleActivityService.saveModel.ProcedimentsInstr =
            this.stepform7.controls[controlName].value;
          break;
        case "declaracio_conformitat":
          this.multipleActivityService.saveModel.DeclaracioConformitat =
            this.stepform7.controls[controlName].value;
          break;
        case "manuals_instr":
          this.multipleActivityService.saveModel.ManualsInstr =
            this.stepform7.controls[controlName].value;
          break;
        case "llibres_registre":
          this.multipleActivityService.saveModel.LlibresRegistre =
            this.stepform7.controls[controlName].value;
          break;
        case "informes_adequacio":
          this.multipleActivityService.saveModel.InformesAdequacio =
            this.stepform7.controls[controlName].value;
          break;
        case "requeriments_inspeccio":
          this.multipleActivityService.saveModel.RequerimentsInspeccio =
            this.stepform7.controls[controlName].value;
          break;
        case "part_accident":
          this.multipleActivityService.saveModel.PartAccident =
            this.stepform7.controls[controlName].value;
          break;
        case "informe_sinistralitat":
          this.multipleActivityService.saveModel.InformeSinistralitat =
            this.stepform7.controls[controlName].value;
          break;
        case "registre_accidents":
          this.multipleActivityService.saveModel.RegistreAccidents =
            this.stepform7.controls[controlName].value;
          break;
        case "documentacio_prevencio":
          this.multipleActivityService.saveModel.DocumentacioPrevencio =
            this.stepform7.controls[controlName].value;
          break;
        case "certificats_formacio":
          this.multipleActivityService.saveModel.CertificatsFormacio =
            this.stepform7.controls[controlName].value;
          break;
        case "estudis_ergonomics":
          this.multipleActivityService.saveModel.EstudisErgonomics =
            this.stepform7.controls[controlName].value;
          break;
        case "estudis_higienics":
          this.multipleActivityService.saveModel.EstudisHigienics =
            this.stepform7.controls[controlName].value;
          break;
        case "estudis_psicosocials":
          this.multipleActivityService.saveModel.EstudisPsicosocials =
            this.stepform7.controls[controlName].value;
          break;
        case "certificat_reo":
          this.multipleActivityService.saveModel.CertificatReo =
            this.stepform7.controls[controlName].value;
          break;
        case "fullet_epis":
          this.multipleActivityService.saveModel.FulletEpis =
            this.stepform7.controls[controlName].value;
          break;
        case "altra_documentacio":
          this.multipleActivityService.saveModel.AltraDocumentacio =
            this.stepform7.controls[controlName].value;
          break;

        case "manuals_instr_text":
          this.multipleActivityService.saveModel.ManualsInstrText =
            this.stepform7.controls[controlName].value;
          break;

        case "informe_sinistralitat_text":
          this.multipleActivityService.saveModel.InformeSinistralitatText =
            this.stepform7.controls[controlName].value;
          break;

        case "registre_accidents1_text":
          this.multipleActivityService.saveModel.RegistreAccidents1Text =
            this.stepform7.controls[controlName].value;
          break;

        case "registre_accidents2_text":
          this.multipleActivityService.saveModel.RegistreAccidents2Text =
            this.stepform7.controls[controlName].value;

          break;

        case "altra_documentacio_text":
          this.multipleActivityService.saveModel.AltraDocumentacioText =
            this.stepform7.controls[controlName].value;

          break;

        default:
          break;
      }
    });
  }
}
