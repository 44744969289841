export class ActivityFilterModel {
    tipo_consulta: string;
    page_size: number;
    page_number: number;
    id_tecnico: string;
    id_empresa: string;
    id_centro: string;
    id_actuaciontipo: string;
    id_actuacionestado: string;
    fecha_desde: string;
    fecha_hasta: string;
    id_tecnico_selected: string;
}
