import { Component, OnInit, DefaultIterableDiffer } from '@angular/core';
import { Router } from '@angular/router';
import { GAuthService } from 'src/app/services/_authService/gauth.service';
import { LogActivityService } from '../../services/_logActivity/log-activity.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-signin-callback',
    template: ``
})

export class SignoutRedirectCallbackComponent implements OnInit {
   
    constructor(private _authService: GAuthService,
      private _router: Router, private logActivityService: LogActivityService, private cookieService: CookieService) {
  
                }

  ngOnInit() {   
    this.cookieService.deleteAll();
    let loggedUser = JSON.parse(localStorage.getItem("loggeduser")); 
      this._authService.completeLogout().then(user => {
        var indexedDBRecord = { userId: loggedUser.id, activityId: "", loggedInTime: "", loggedOutTime: this.logActivityService.getDateInCustomFormat(new Date()), committedAction: "LOGOUT Success", requestParam: "", responseParam: "", logDate: this.logActivityService.getDateInCustomFormat(new Date()) }
        this.logActivityService.saveDataToIndexedDB('useractivity', indexedDBRecord).subscribe(
          res => {
            this._router.navigate(['/login'], { replaceUrl: true }).then(() => { window.location.reload(); });
          }
        );   
     });  
    }   
}
