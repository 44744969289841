import { Component, OnInit } from '@angular/core';
import { SelectOptions } from 'src/app/models/select-options.model';
import { DropdownService } from 'src/app/services/dropdown.service';
import { ActivityService } from 'src/app/services/activity.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { Router, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FilterService } from 'src/app/services/filter.service';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { Constants } from 'src/app/Helpers/constant';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {

  navigationList$: SelectOptions[];
  selectedNavigationOption: string;
  activityTypes$: SelectOptions[];
  companies$: SelectOptions[];
  workingCentres$: SelectOptions[];
  users$: SelectOptions[];
  activityStatusTypes$: SelectOptions[];
  radioButtonOption = 'AssignedToMe';
  disableGetAll=true;
  isRadioButtonChange = false;
  isCompanyDropdownClicked = false;
  disableGetAssigna=false;
  
  private companySubject: Subject<string> = new Subject();
  private centerSubject: Subject<string> = new Subject();
  private typeOfActivitySubject: Subject<string> = new Subject();
  private userSubject: Subject<string> = new Subject();

  constructor(
    private dropdownService: DropdownService,
    private errorHandler: ErrorHandlerService,
    private activityService: ActivityService,
    private router: Router,
    private spinner: NgxSpinnerService,
    public filterService: FilterService
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.setCurrentNavigation();
      }
    });
  }

  ngOnInit() {
    // Commmented on client request=> Bug ID:74306
    // Load only 'all' option
    this.loadAllUsers();
    this.LoadAllActivityTypes();
    this.LoadAllCompanies();
    this.LoadAllActivityStatusTypes();
    this.LoadNavigationList();
    this.clearDropdownValues();

    this.companySubject.pipe(    
    debounceTime(Constants.DEBOUNCE_TIME),
    distinctUntilChanged()
    ).subscribe(companyName =>  this.loadTopMatchingCompanies(companyName));

  this.centerSubject.pipe(
    debounceTime(Constants.DEBOUNCE_TIME),
    distinctUntilChanged()
  ).subscribe(centerName =>  this.loadTopMatchingWorkcentres(centerName));

  this.typeOfActivitySubject.pipe(
    debounceTime(Constants.DEBOUNCE_TIME),
    distinctUntilChanged()
  ).subscribe(typeOfActivity =>  this.loadTopMatchingActivityTypes(typeOfActivity));
    this.filterService.filterObject.id_tecnico=this.filterService.filterObject.id_tecnico_selected;
  }

  isCompanyClicked() {
    this.isCompanyDropdownClicked = true;
  }


  /*NOTE: There are 4 types of filters:
  1.) TODAS =>If the ROLE of id_tecnico IS NOT ‘1’ nor ‘3’, returns the actuations relevant to him/her:
      the actuations assigned to him/her, plus the actuations  created by him/her,
      plus the actuations from work centers assigned to him/her (centers where he/she is the “técnico de referencia”).
  2.) ASIGNADASA =>It returns the actuations assigned to id_tecnico(Assigned user)
  3.) DEMISCENTROS =>It returns the actuations from work centers assigned to id_tecnico
      (centers where he/she is the “técnico de referencia”).
  4.) DEUNCENTRO  =>Returns the actuations of the specified work center
  */

  public refreshActivityList(): void {
    this.filterService.resetPageDetails();

    if (this.filterService.filterObject.id_actuaciontipo === '0') {
      this.LoadAllActivityTypes(false);
    }

    if (this.filterService.filterObject.id_empresa === '0') {
      this.LoadAllCompanies(false);
      this.getCompanyWorkCentres('0', false);
    }

    this.filterService.setFilterValuesForAPI();

    // Get radio button selection and change filter mode
    if (this.radioButtonOption === 'GetAll') {
      this.filterService.filterObject.tipo_consulta = 'TODAS';
    } else if (this.radioButtonOption === 'AssignedToMe') {
      this.filterService.filterObject.tipo_consulta = 'ASIGNADASA';
    } else if (this.radioButtonOption === 'FromMyCentre') {
      this.filterService.filterObject.tipo_consulta = 'DEMISCENTROS';
    }

    this.spinner.show();
    const currentuser = JSON.parse(localStorage.getItem('loggeduser'));
    // Refresh the activity list only if the logged in user is valid(Prevent error on authentication failed scenario)
    if (currentuser) {
      
      this.activityService.getFilteredActivities(this.filterService.filterObject)
        .subscribe(response => {
          if (response.webError != null) {
            this.spinner.hide();
            this.errorHandler.handle(response.webError.messageToUser, true);
          } else {

            if (response.links.rowcount <= 25) {
              this.activityService.pageCount = 1;
            } else {
              this.activityService.pageCount = Math.floor(response.links.rowcount / response.data.length) + 1;
            }

            this.activityService.pageNumberList = Array.from(Array(this.activityService.pageCount).keys()).map(i => i + 1);
            const paginationRangeArr: any[] = this.activityService.getListViewPaginationRanges(this.activityService.pageNumberList);
            this.activityService.mapActivityToCalendarEvent(response.data, paginationRangeArr);
            this.activityService.activityCalendarRefreshSubject.next('');
            this.activityService.pageRangeSubject.next('');
            this.activityService.totalActionCountSubject.next(response.links.rowcount);
            this.activityService.selectedPageValueSubject.next('1');

            if (this.isRadioButtonChange) {
              this.isRadioButtonChange = false;
            }
            this.filterService.setFilterValuesForView();
            this.spinner.hide();
          }

        }, err => {
          this.spinner.hide();
          this.filterService.setFilterValuesForView();
          this.errorHandler.handle(err);
        });
    }
  }

  public onRadioButtonChange() {
    this.isCompanyDropdownClicked = false;

    if (this.radioButtonOption === 'GetAll') {
      this.disableGetAssigna=true;
      const currentUser = JSON.parse(localStorage.getItem('loggeduser'));
      this.filterService.filterObject.id_tecnico = currentUser.id;
      this.filterService.filterObject.tipo_consulta = 'TODAS';

    } else if (this.radioButtonOption === 'AssignedToMe') {
      this.disableGetAssigna=false;
      this.filterService.filterObject.id_tecnico=this.filterService.filterObject.id_tecnico_selected;
      this.filterService.filterObject.tipo_consulta = 'ASIGNADASA';

    } else if (this.radioButtonOption === 'FromMyCentre') {
      this.disableGetAssigna=true;
      this.filterService.filterObject.tipo_consulta = 'DEMISCENTROS';
      const currentUser = JSON.parse(localStorage.getItem('loggeduser'));
      this.filterService.filterObject.id_tecnico = currentUser.id;
      
      if(this.filterService.filterObject.id_tecnico_selected!="00000000000000-0000000-0000-W")
      {
        this.filterService.filterObject.id_tecnico_selected=currentUser.id
      }
    }
    this.isRadioButtonChange = true;
    this.refreshActivityList();
  }

  // Load Dropdowns
  private LoadAllActivityTypes(setViewValue = true) {
    this.dropdownService.getActivityTypes('-99', 100).subscribe(response => {
      this.activityTypes$ = this.dropdownService.WrappedToDropDownList(response, 'name', 'activityTypeId', true);
      if (setViewValue) {
        this.filterService.filterObject.id_actuaciontipo = !this.filterService.filterObject.id_actuaciontipo
          ? '0' : this.filterService.filterObject.id_actuaciontipo;
      }
    }, error => {
      this.errorHandler.handle(error);
    });
  }

  private LoadAllCompanies(setViewValue = true) {
    this.dropdownService.getCompanies('-99', 0, '').subscribe(response => {
      this.companies$ = this.dropdownService.WrappedToDropDownList(response, 'name', 'companyId', true);
      if (setViewValue) {
        this.filterService.filterObject.id_empresa = !this.filterService.filterObject.id_empresa
          ? '0' : this.filterService.filterObject.id_empresa;
      }
    }, error => {
      this.errorHandler.handle(error);
    });
  }

  private LoadAllActivityStatusTypes(setViewValue = true) {
    this.dropdownService.getActivityStatusTypes().subscribe(response => {
      response = response.filter(x=>x.deleted == false);
      this.activityStatusTypes$ = this.dropdownService.WrappedToDropDownList(response, 'statusTypeName', 'id', true, false, true, true,true);
      if (setViewValue) {
        this.filterService.filterObject.id_actuacionestado = !this.filterService.filterObject.id_actuacionestado
          ? '0' : this.filterService.filterObject.id_actuacionestado;
      }
    }, error => {
      this.errorHandler.handle(error);
    });
  }


  public getCompanyWorkCentres(selectedOption: any, setViewValue = true) {
    this.filterService.filterObject.id_centro = setViewValue ? '0' : null;
    if (selectedOption) {
      this.dropdownService.getWorkingCentresByCompany(selectedOption.value, '-99', 100, '', false).subscribe(response => {
        this.workingCentres$ = this.dropdownService.WrappedToDropDownList(response, 'centreName', 'id', true);
        if (this.isCompanyDropdownClicked) {
          this.refreshActivityList();
          this.isCompanyDropdownClicked = false;
        }
        if (setViewValue) {
          this.filterService.filterObject.id_centro = !this.filterService.filterObject.id_centro
            ? '0' : this.filterService.filterObject.id_centro;
        }
      }, error => {
        this.errorHandler.handle(error);
      });
    } else {
      this.workingCentres$ = null;
    }
  }

  private LoadNavigationList() {
    this.navigationList$ = [
      { label: 'Vista Calendario', value: '1' },
      { label: 'Vista Listado', value: '2' }
    ];
  }

  navigateToPage(item) {
    switch (item.value) {
      case '1':
        this.router.navigate(['/home/calendar']);
        break;
      case '2':
        this.router.navigate(['/home/list']);
        break;
      default:
        this.router.navigate(['/home/calendar']);
    }
  }

  setCurrentNavigation() {
    let usePagination: boolean;
    if (this.router.url === '/home/calendar') {
      this.selectedNavigationOption = '1';
      this.disableGetAll = true;
      if(this.radioButtonOption==='GetAll'){this.radioButtonOption = 'AssignedToMe'}
    } else if (this.router.url === '/home/list') {
      this.selectedNavigationOption = '2';
      this.disableGetAll = false;
      usePagination = true;
    }
    this.filterService.resetDateFilters();
    this.filterService.setPageSize(usePagination);
  }

  clearDropdownValues() {
    this.LoadAllCompanies();
    this.LoadAllActivityTypes();
    this.getCompanyWorkCentres('0');
  }

  setDropDownValues() {
    this.filterService.filterObject.id_actuaciontipo = !this.filterService.filterObject.id_actuaciontipo
      ? '0' : this.filterService.filterObject.id_actuaciontipo;
    this.filterService.filterObject.id_empresa = !this.filterService.filterObject.id_empresa
      ? '0' : this.filterService.filterObject.id_empresa;
    this.filterService.filterObject.id_centro = !this.filterService.filterObject.id_centro
      ? '0' : this.filterService.filterObject.id_centro;
    this.filterService.filterObject.id_actuacionestado = !this.filterService.filterObject.id_actuacionestado
      ? '0' : this.filterService.filterObject.id_actuacionestado;
  }

 
  //#region "Limited value loading for main dropdowns "

  // Company
  loadTopMatchingCompanies(companyNameTarget: any) {
    // Trigger the loading only if string length is>=4
    if (companyNameTarget.length >= 4) { 
      this.dropdownService.getCompanies(companyNameTarget, 100, '', true).subscribe(response => {
        this.companies$ = this.dropdownService.WrappedToDropDownList(response, 'name', 'companyId', true);
      }, error => {
        this.errorHandler.handle(error);
      });
    } else {
      this.filterService.filterObject.id_empresa = null;
      this.companies$ = null;
      this.workingCentres$ = null;
      if (companyNameTarget.length === 0) {
        this.filterService.filterObject.id_empresa = '0';
        this.filterService.filterObject.id_centro = '0';
        this.refreshActivityList();
      }
    }
  }
  onKeyUpCompany(companyNameTarget:any){
    if(companyNameTarget.target.value != null){
      this.companySubject.next(companyNameTarget.target.value);
    }
  }

  // workCentres
  loadTopMatchingWorkcentres(wcNameTarget: any) {
    // Trigger the loading only if string length is>=4
    if (wcNameTarget.length >= 4) { 
      const empresaId = this.filterService.filterObject.id_empresa;
      this.dropdownService.getWorkingCentresByCompany(empresaId, wcNameTarget, 100, '', true).subscribe(response => {
        this.workingCentres$ = this.dropdownService.WrappedToDropDownList(response, 'centreName', 'id', true);
      }, error => {
        this.errorHandler.handle(error);
      });
    } else {
      this.filterService.filterObject.id_centro = null;
      this.workingCentres$ = this.workingCentres$ ? this.workingCentres$ : null;
      if (wcNameTarget.length === 0) {
        this.filterService.filterObject.id_centro = '0';
        this.refreshActivityList();
      }
    }
  }

  onKeyUpCenter(centerNameTarget:any){
    if(centerNameTarget.target.value != null){
      this.centerSubject.next(centerNameTarget.target.value);
    }
  }
  // acivity types
  loadTopMatchingActivityTypes(activityTypeNameTarget: any) {
    // Trigger the loading only if string length is>=4
    if (activityTypeNameTarget.length >= 4) { 
      this.dropdownService.getActivityTypes(activityTypeNameTarget, 100, '', false, true).subscribe(response => {
        this.activityTypes$ = this.dropdownService.WrappedToDropDownList(response, 'name', 'activityTypeId', true);
      }, error => {
        this.errorHandler.handle(error);
      });
    } else {
      this.activityTypes$ = null;
      this.filterService.filterObject.id_actuaciontipo = null;
      if (activityTypeNameTarget.length === 0) {
        this.filterService.filterObject.id_actuaciontipo = '0';
        this.refreshActivityList();
      }
    }
  }

  onKeyUpTypeOfActivity(typeOfActivityTarget:any){
    if(typeOfActivityTarget.target.value != null){
      this.typeOfActivitySubject.next(typeOfActivityTarget.target.value);
    }
  }

  loadAllUsers()
  {
    const userId =  JSON.parse(localStorage.getItem('loggeduser')).id;
    this.filterService.filterObject.id_tecnico = userId;
    this.filterService.filterObject.id_tecnico_selected=userId
    this.dropdownService.getAssociatedTechs('', 100, userId).subscribe(response => {
      this.users$ = this.dropdownService.WrappedToDropDownListAsignadasa(response, 'nombre_apellidos', 'id');
      response.forEach((keys : any, vals :any)=>{
        if (keys.nombre_apellidos == 'técnico inexistente') {
          this.filterService.filterObject.id_tecnico_selected=keys.id;
          this.filterService.filterObject.id_tecnico=keys.id;
        }
      });
     this.refreshActivityList();
    }, error => {
      this.errorHandler.handle(error);
    })
  }

  onKeyUpUser(userTarget:any){
    if(userTarget.target.value != null){
      this.userSubject.next(userTarget.target.value);
    }
  }
  
  setAssignedUser(selectedOption: any) {
    this.filterService.filterObject.id_tecnico = selectedOption.value;
    this.refreshActivityList();
  }
  // #endregion

}
