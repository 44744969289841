import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.sass']
})
export class ConfirmationPopupComponent {

  public confirmationMessage = '';

  constructor(public modal: NgbActiveModal) { }

  

}
