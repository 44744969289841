import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PowerBIPanelConfig } from 'src/app/models/powerBi/PowerBIPanelConfig';
import { environment } from 'src/environments/environment';
import { MasterDetailsApiService } from '../masterdetailsapi.service';

@Injectable({
  providedIn: 'root'
})
export class PowerbiService {

  constructor(private apiService: MasterDetailsApiService) {
    this.apiService.baseUrl = environment.baseUrl;
  }

  getEmbededInfo(reportId,workSpaceId){
    const url='PowerBi/GetEmbedInfo';
    return this.apiService.get(url+`?UserName=${workSpaceId}&ReportId=${reportId}`);
  }

  getAllConfigPanels():Observable<PowerBIPanelConfig[]>{
    const url='PowerBi/GetAllpowerBiConfig';
    return this.apiService.get(url);
  }
}
