import { ActivityRoot } from "./activity.save.activityroot";

export class MultipleActivitySaveModel {
  activityPartId: number;
  companyId: string;
  companyName: string;
  workCentreId: string;
  workCentreName: string;
  assignedUserId: string;
  assignedUsername: string;
  activityPartDate: any;
  activityPartDateStr: string;
  activityPartTime: string;
  activityPartFinishTime: string;
  activityPartDuration: string;
  activityTypeId: string;
  activityTypeName: string;
  signedUsername: string;
  signedUserDNI: string;
  preventionDelegateName: string;
  preventionDelegateDNI: string;
  recipientEmail: string[];
  userId: string;
  username: string;
  sendEmailToUserOnly: boolean;
  description: string;
  signatureImage: string;
  preventionDelegateSignature: string;
  firmaTecSignature: string;
  emailSubject: string;
  emailBody: string;
  isAccidentOccured: boolean;
  totalAccidents: number;
  visitedAccidents: number;
  investigatedAccidents: number;
  isNewWorkerAdded: boolean;
  isJobInfoGiven: boolean;
  isEPIGiven: boolean;
  isWorkTeamJoined: boolean;
  isPregnantWomenAvl: boolean;
  pregnantWomenRemark: string;
  isMinorsAvl: boolean;
  minorsRemark: string;
  isESensitiveAvl: boolean;
  eSensitiveRemark: string;
  isMedicalCertificateAvl: boolean;
  isCoordinationDone: boolean;
  noOfWorkCenterWorkers: number | null;
  trainingProgramRemark: string;
  activityList: Array<ActivityModel>;
  activityListForClientAPI: Array<ActivityRoot>;
  isValidated: boolean;
  templateLanguage: string;
  currentStepTitle: string;
  noMen: number;
  noWomen: number;
  staffWomen: number;
  staffMen: number;
  ettWomen: number;
  ettMen: number;
  isTrainedRiskPrevention: boolean;
  isUnderMedicalExamination: boolean;
  isOccupationDiseases: boolean;
  menOccupationDiseases: number;
  womenOccupationDiseases: number;
  investigatedOccupationDiseases: number;
  visitedOccupationDiseases: number;
  isChemicalSubstances: boolean;
  isWorkPlaceChanged: boolean;
  islegalProsecution: boolean;
  isRiskNotifications: boolean;
  menAccidents: number;
  womenAccidents: number;
  planActionList: Array<PlanActionModel>;
  title?: string;
  action?: string;
  estimatedDate?: string;
  cost?: string;
  canMitigateRisk?: boolean;
  canEliminateRisk?: boolean;
  actionChekedInVisit?: boolean;

  // General training properties
  TrabajosAltura: boolean;
  TrabajosVerticales: boolean;
  PlataformasElevadoras: boolean;
  CarretillasElevadoras: boolean;
  TranspaletasApiladores: boolean;
  Incendios: boolean;
  PrimerosAuxilios: boolean;
  EspaciosConfinados: boolean;
  ConsignacionMaquinas: boolean;
  RiesgoElectrico: boolean;
  RiesgoQuimico: boolean;
  RiesgoBiologico: boolean;
  RiesgoErgonomico: boolean;
  NivelBasicoPRL: boolean;
  DelegadosPrevencion: boolean;
  SeguridadVial: boolean;
  Teletrabajo: boolean;
  OtrosFormacion: boolean;

  // Specific training properties
  Admin20H: boolean;
  Directivos10H: boolean;
  MandosIntermedios20H: boolean;
  ResponsablesTecnicos20H: boolean;
  NivelBasicoPRL60H: boolean;
  DelegadosPrevencion70H: boolean;
  Oficios20H: boolean;
  Oficios6H: boolean;

  // Metal obra training properties
  AdminMetalObra20H: boolean;
  DirectivosMetalObra10H: boolean;
  MandosIntermediosMetalObra20H: boolean;
  ResponsablesTecnicosMetalObra20H: boolean;
  NivelBasicoPRLMetalObra60H: boolean;
  DelegadosPrevencionMetalObra50H: boolean;
  OficiosMetalObra20H: boolean;
  OficiosMetalObra6H: boolean;
  ReciclajeOficiosMetalObra4H: boolean;

  // Main properties
  PersonalDOficines: boolean;
  Directius: boolean;
  DelegatsDePrevencio: boolean;
  NivellBasic: boolean;
  OficisProduccioIManteniment: boolean;
  ConductorGruesMobilsAutopropulsades: boolean;
  AltresOficisNoEspecificatsEnConveni: boolean;
  OficisProduccioIMantenimentYConductorGruesMobilsAutopropulsades: boolean;
  ReciclatgeOficisOficinesIDirectius: boolean;

  // Vidrio rotulacion training properties
  Directius10: boolean;
  Administratius: boolean;
  ResponsablesObraITècnicsExecució: boolean;
  ComandamentsIntermedis: boolean;
  DelegatsDePrevenció: boolean;
  OficisVintHores: boolean;
  OficisSisHores: boolean;
  NivellBàsic: boolean;

  // Madera mueble training properties
  Administratius10: boolean;
  Directius10H: boolean;
  OficisVintHores20: boolean;
  NivellBàsic60: boolean;

  // General health and safety properties
  ChemicalAgents: boolean;
  ChemicalAgentsDetails: string;
  BiologicalAgents: boolean;
  BiologicalAgentsDetails: string;
  Sonometria: boolean;
  Dosimetria: boolean;
  IonizingRadiations: boolean;
  ElectromagneticFields: boolean;
  StaticElectricity: boolean;
  ThermalStress: boolean;
  Vibrations: boolean;
  Other: boolean;
  OtherDetails: string;

  // Musculoskeletal risk properties
  ManipulacioManualDeCargues: boolean;
  MovimentsRepetitius: boolean;
  PosturesForçades: boolean;

  // Psychosocial risk properties
  EstudiPsicosocial: boolean;
  ProtocolAssetjament: boolean;
  PlaDigualtat: boolean;

  // Specific safety documents
  ChemicalStorage: boolean;
  ExplosionProtectionDocument: boolean;
  MachineryAdequacyReport: boolean;
  MobilityAndRoadSafetyPlan: boolean;
  Profesiogram: boolean;

  //text propeties
  AgentesQuimicosText: string = "";
  AgentesBiologicosText: string = "";
  OtrosText: string = "";
  EstudiPsicosocialText: string = "";
  ProtocolAssetjamentText: string = "";
  PlaDigualtatText: string = "";
  EmmagatzematgeProductesQuimicsText: string = "";
  DocumentProteccioContraExplosionsText: string = "";
  InformeAdequacioMaquinesText: string = "";
  PlaMobilitatSeguretatViariaText: string = "";
  ProfesiogramaText: string = "";

  // Other properties
  LlistatPersonal: boolean;
  LlistatMaquinaria: boolean;
  FitxesDades: boolean;
  ProcedimentsInstr: boolean;
  DeclaracioConformitat: boolean;
  ManualsInstr: boolean;
  LlibresRegistre: boolean;
  InformesAdequacio: boolean;
  RequerimentsInspeccio: boolean;
  PartAccident: boolean;
  InformeSinistralitat: boolean;
  RegistreAccidents: boolean;
  DocumentacioPrevencio: boolean;
  CertificatsFormacio: boolean;
  EstudisErgonomics: boolean;
  EstudisHigienics: boolean;
  EstudisPsicosocials: boolean;
  CertificatReo: boolean;
  FulletEpis: boolean;
  AltraDocumentacio: boolean;

  ManualsInstrText: string = "";
  InformeSinistralitatText: string = "";
  RegistreAccidents1Text: string = "";
  RegistreAccidents2Text: string = "";
  AltraDocumentacioText: string = "";
  LoggedUserName: string;
}

export class ActivityModel {
  id: string;
  activityTypeId: string;
  companyId: string;
  workCentreId: string;
  assignedUserId: string;
  duration: string;
  description: string;
  statusTypeId: string;
  userId: string;
  activityDate: any;
  activityTime: string;
  isDeleted = false;
  entryDate: Date;
  activityTypeName: string;
  statusTypeName: string;
}

export class PlanActionModel {
  title: string = "";
  action: string = null;
  estimatedDate: string = "";
  description: string = "";
  cost: string = "";
  responsible: string = "";
  canMitigateRisk: boolean = null;
  canEliminateRisk: boolean = null;
  actionChekedInVisit: boolean = null;
  isMarked: boolean = true;
}
