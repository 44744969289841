import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { CustomEncoderService } from './custom-encoder.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './_authService/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  protected httpOptions: any;
  protected token: any;
  protected timeout_seconds = 60000;
  public baseUrl: string;

  constructor(private http: HttpClient, private customEncoder: CustomEncoderService,private authService : AuthenticationService) {
    this.baseUrl = environment.baseUrl;
  }

  private getRequestHttpParams(httpParams?: any) {
    let params = new HttpParams({ encoder: this.customEncoder });
    for (const key in httpParams) {
      if (httpParams.hasOwnProperty(key)) {
        params = params.append(key, httpParams[key]);
      }
    }
    return params;
  }


  uploadimage(formData: FormData) {
    return this.http.post(this.baseUrl +'upload', formData, {reportProgress: true, observe: 'events'});
  }
  deleteimage(filename, folder) {
    return this.http.delete(this.baseUrl +'upload/delete/' + folder + "/" + filename);
  }
  deleteAll(folder) {
    return this.http.delete(this.baseUrl +'upload/Deleteall/' + folder);
  }
}


