import { Component, OnInit } from '@angular/core';
import { GAuthService } from 'src/app/services/_authService/gauth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from 'oidc-client';
import { Observable } from 'rxjs';
import { LoggedUser } from 'src/app/services/_authModel/user';
import { LogActivityService } from 'src/app/services/_logActivity/log-activity.service';

@Component({
  selector: "app-signin-callback",
  template: `<div></div>`
})
export class SigninRedirectCallbackComponent implements OnInit {
  private _returnUrl: string;
  public currentUser: Observable<User>;
  private loggedUser: LoggedUser;

  constructor(private authService: GAuthService,
    private router: Router,
    private route: ActivatedRoute,
    private logActivityService: LogActivityService) {
  }

  ngOnInit(): void {
    this.authService.completeLogin().then(user => {
      let loginStatus = "";
      let responseData = "";
      this.loggedUser = new LoggedUser();

      if (user != null) {
        this.loggedUser = {
          username: (!user.profile.username) ? "" : user.profile.username,
          fullName: (!user.profile.full_name) ? "" : user.profile.full_name,
          id: user.profile.sub,
          sid: user.profile.sid,
          gesemeApiToken: user.access_token,
          permissionScope: user.scope,
          accessLevel: "" //access level is determined in header.component.ts
        };

        loginStatus = "LOGIN Success";
        responseData = JSON.stringify({ userId: this.loggedUser.id, username: this.loggedUser.username, fullName: this.loggedUser.fullName, permission: this.loggedUser.permissionScope });
        localStorage.setItem("loggeduser", JSON.stringify(this.loggedUser));
        this._returnUrl = '/home/calendar';
      }
      else {
        loginStatus = "LOGIN Fail";
        this._returnUrl = this.route.snapshot.queryParams['returnUrl'];
      }

      var indexedDBRecord = { userId: this.loggedUser.id, username: "", userFullName: "", activityId: "", loggedInTime: this.logActivityService.getDateInCustomFormat(new Date()), loggedOutTime: "", committedAction: loginStatus, requestParam: "", responseParam: responseData, logDate: this.logActivityService.getDateInCustomFormat(new Date()), permissionLevel: this.loggedUser.permissionScope };
      this.logActivityService.saveDataToIndexedDB('useractivity', indexedDBRecord);
      this.router.navigate([this._returnUrl]);
    }).catch(alert => {
      var indexedDBRecord = { userId: "0", username: "", userFullName: "", activityId: "", loggedInTime: "", loggedOutTime: this.logActivityService.getDateInCustomFormat(new Date()), committedAction: "Login cancelled", requestParam: "", responseParam: JSON.stringify({ alert }), logDate: this.logActivityService.getDateInCustomFormat(new Date()), permissionLevel: "" };
      this.logActivityService.saveDataToIndexedDB('useractivity', indexedDBRecord).subscribe(a => {
        this._returnUrl = '/login';
        this.router.navigate([this._returnUrl]);
      });
    });
  }

}
