import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivityStatusService } from 'src/app/services/common/activity-status.service';
import { DropdownService } from 'src/app/services/dropdown.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { DecimalToHex } from '../../../Helpers/DecimalToHex';

@Component({
  selector: 'app-dashboard-calendar',
  templateUrl: './dashboard-calendar.component.html',
  styleUrls: ['./dashboard-calendar.component.scss']
}) 
 
export class DashboardCalendarComponent implements OnInit {

  listOfActivityStatus = [];
  constructor(private dropdownService: DropdownService,
    private errorHandler: ErrorHandlerService,   
    private spinner: NgxSpinnerService, private activityStatus : ActivityStatusService) { 
  

    }

  
  async ngOnInit() {
    await this.activityStatus.getColorsOfActivityStatus().toPromise().then();
    this.listOfActivityStatus = this.activityStatus.listOfActivityStatus.filter(x=>x.deleted == false);
  }

 
}
