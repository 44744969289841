import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { AuthenticationService } from 'src/app/services/_authService/authentication.service';
import { GAuthService } from '../_authService/gauth.service';
import { environment } from 'src/environments/environment'
import { LoggedUser } from '../_authModel/user';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authService: AuthenticationService, private gAuthService: GAuthService, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return from(this.gAuthService.getAccessToken().then(token => {
            let headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
            let authReq = request.clone({ headers });
            return next.handle(authReq).pipe(tap(_ => { }, error => {       // tap <=> nothing to do in the normal case
                var respError = error as HttpErrorResponse;
              if (respError && (respError.status === 401 || respError.status === 403)) {
                  localStorage.setItem("sessionErrorMsg", "Su sesión ha terminado.Por favor,inicie sesión de nuevo.");
                  this.router.navigate(['/login']);
                }
            })).toPromise();
        }));

    }
}
