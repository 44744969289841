import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DecimalToHex } from 'src/app/Helpers/DecimalToHex';
import { ActivityStatusService } from 'src/app/services/common/activity-status.service';
import { DropdownService } from 'src/app/services/dropdown.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';


@Component({
  selector: 'app-dashboard-list',
  templateUrl: './dashboard-list.component.html',
  styleUrls: ['./dashboard-list.component.scss']
})
export class DashboardListComponent implements OnInit {

  constructor(private dropdownService: DropdownService,
    private errorHandler: ErrorHandlerService,   
    private spinner: NgxSpinnerService, private activityStatus : ActivityStatusService){

  }
  listOfActivityStatus = [];
  
  async ngOnInit() {
    await this.activityStatus.getColorsOfActivityStatus().toPromise().then();
    this.listOfActivityStatus = this.activityStatus.listOfActivityStatus.filter(x=>x.deleted == false);

  }


}
