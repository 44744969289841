import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DropDownList } from '@syncfusion/ej2-angular-dropdowns';
import { Column, DataStateChangeEventArgs, EditEventArgs, EditSettingsModel, GridComponent, IEditCell, SelectionSettingsModel, TextWrapSettingsModel, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { EmitType } from '@syncfusion/ej2-base';
import { ToastrService } from 'ngx-toastr';
import { UserConfigService } from 'src/app/services/common/user-config.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import Swal from 'sweetalert2';
import { L10n, setCulture } from '@syncfusion/ej2-base';

setCulture('es-ES');

L10n.load({
    'es-ES': {
        'grid': {
            'EmptyRecord': 'No hay registros que mostrar',            
            'Item': 'Artículo',
            'Items': 'Elementos',
            'Edit': 'Editar',
            'Add' : 'Agregar',
            'Cancel': 'Cerrar',
            'Update': 'Actualizar',
            'EditOperationAlert' : 'No hay registros seleccionados',
            'SaveButton' : 'Guardar' ,
            'OKButton' : 'De acuerdo' ,
            'CancelButton' : 'Cerrar',
            'ClearButton' : 'Limpiar',
            'FilterButton' : 'Filtrar',
            'StartsWith' : 'Comienza con',
            'EndsWith' :	'Termina con' ,
            'Contains' :	'Contiene',
            'Equal' :	'Igual',
            'NotEqual' :	'No es igual',
            'LessThan' :	'Menos que',
            'LessThanOrEqual'	: 'Menor o igual',
            'GreaterThan'	: 'Mas grande que',
            'GreaterThanOrEqual':	'Mayor que o igual',
            'Empty' : 'Vacío',
            'Not Empty' : 'No Vacío',
            'InvalidFilterMessage': 'Mensaje de filtro no válido',
            'CustomFilterPlaceHolder' : 'Introduzca el valor',
            'NoResult': 'No se encontraron coincidencias',
            'True': 'Verdadero',
            'False' : 'Falso',
            'Matchs' :	'No se encontraron coincidencias',
            'FilterTrue' :	'Verdadero',
            'FilterFalse' :	'Falso',
            'CustomFilter' :	'Filtro personalizado',
        },
        'pager':{
            'currentPageInfo': '{0} Anterior {1} Siguiente ',
            'totalItemsInfo': ' (Total de {0} documentos encontrados.)'           
        }
    }
});

@Component({
  selector: 'app-role-config-popup',
  templateUrl: './role-config-popup.component.html',
  styleUrls: ['./role-config-popup.component.scss']
})
export class RoleConfigPopupComponent implements OnInit {

  @ViewChild('ejRolesDialog') ejRolesDialog: DialogComponent;
  @ViewChild('ejUserDialog') ejUserDialog: DialogComponent;
  @ViewChild('ejPermitDialog') ejPermitDialog: DialogComponent;
  // Create element reference for dialog target element.
  @ViewChild('container', { read: ElementRef, static: true }) container: ElementRef;
  @ViewChild('roleGrid') roleGrid?: GridComponent;
  @ViewChild('userGrid') userGrid?: GridComponent;
  @ViewChild('permissionGrid') permissionGrid?: GridComponent;
  modalOptions: NgbModalOptions;

  public targetElement: HTMLElement;
  public height: string = '300px';
  public width: string = '900px';
  public rolefilterSettings: object;
  public userfilterSettings: object;
  public permitfilterSettings: object;

  public roleEditSettings?: EditSettingsModel;
  public userEditSettings?: EditSettingsModel;
  public permitEditSettings?: EditSettingsModel;
  public roletoolbar?: ToolbarItems[];
  public usertoolbar?: ToolbarItems[];
  public permittoolbar?: ToolbarItems[];  
  public userSelectionOptions?: SelectionSettingsModel;
  public permitselectionOptions?: SelectionSettingsModel;

  public requiredRule?: object;
  public permissionRule?: object;
  public state: DataStateChangeEventArgs = { skip: 0, take: 10 };
  public roleList: any;
  public userList: any;
  public allUserList: any;
  allPermissions: any;
  selectedrecords: any;
  permissionList: any;
  roleTitle: string = "";
  permissionRules?: object;
  userRules?: object;

  public userstateParams?: IEditCell;
  public userstateElem?: HTMLElement;
  public userstateObj?: DropDownList;
  public permitStateParams?: IEditCell;
  public permitStateElem?: HTMLElement;
  public permitStateObj?: DropDownList;
  public valPermissionChange(args: any) {
    (window as any)['permisionCode'] = (args as any).value;
  }

  public valUserChange(args: any) {
    (window as any)['fullName'] = (args as any).value;
  }

  public wrapSettings: TextWrapSettingsModel = { wrapMode: 'Header' };
  public isModal: Boolean = true;
  roleId: number = 0;
  public value = 'value';

  public dialogAnimation: Object = { effect: 'None' };
  public closeOnEscape: boolean = false;
  public visibleUserDialog: Boolean = false;
  public visiblePermitDialog: Boolean = false;

  constructor(public modal: NgbActiveModal,
    public service: UserConfigService,
    private toastrService: ToastrService,
    private errorHandler: ErrorHandlerService) {
    this.modalOptions = {
      backdrop: 'static',
      windowClass: 'text-left modal-fade-animation',
      centered: true,
      size: 'lg'
    };
  }

  ngOnInit() {
    this.setGridSettings();
    this.getAllRoles();
    this.getAllUserList();
    this.getAllPermissionList();
  } 

  setGridSettings() {
    this.rolefilterSettings = { type: 'Menu' , operators: {
      stringOperator: [
          { value: 'startsWith', text: 'Comienza con' },
          { value: 'endsWith', text: 'Termina con' },
          { value: 'contains', text: 'Contiene' }
       ],
   }};
    this.roleEditSettings = { allowEditing: true, allowAdding: true, mode: 'Normal' };
    this.roletoolbar = ['Add', 'Edit', 'Update', 'Cancel'];
    this.requiredRule = { required: true };

    this.userfilterSettings = { type: 'Menu' };
    this.userEditSettings = { allowAdding: true, mode: 'Normal' };
    this.usertoolbar = ['Add' , 'Update', 'Cancel'];
    this.userSelectionOptions = { checkboxMode: 'ResetOnRowClick'}; 
    this.userRules = { required: [this.comboValidationFn, 'El campo es obligatorio'] };        

    this.permitfilterSettings = { type: 'Menu' };
    this.permitEditSettings = { allowAdding: true, mode: 'Normal' };
    this.permittoolbar = ['Add' , 'Update', 'Cancel'];
    this.permitselectionOptions = { checkboxMode: 'ResetOnRowClick'};  
    this.permissionRules = { required: [this.comboValidationFn, 'El campo es obligatorio'] };
  }

  public comboValidationFn: (args: { [key: string]: string }) => boolean = (args: { [key: string]: string }) => {
    return args["value"] != '';
  }

  // Hide the Dialog when click the footer button.
  public hideDialog: EmitType<object> = () => {
    this.ejRolesDialog.hide();
  }

  getAllRoles() {
    this.service.getAllRoles().subscribe(result => {
      this.roleList = result;
    }, error => {
      this.errorHandler.handle(error);
    });
  }

  public openUserDialog = (event: any): void => {    
    this.roleId = event.roleId;
    this.roleTitle = "Role :- " + event.roleCode;
    this.getRoleUserList();
  }

  public openPermissionDialog = (event: any): void => {
    this.roleId = event.roleId;
    this.roleTitle = "Role :- " + event.roleCode;
    this.getRolePermissions();
  }

  public getRolePermissions() {
    this.permissionList = [];
    this.service.getRolePermissions(this.roleId).subscribe(result => {
      if (result.length > 0) {
        this.permissionList = result;
        this.ejPermitDialog.show();
      } else {
        this.ejPermitDialog.show();
      }
    })
  }

  public getRoleUserList() {
    this.userList = [];
    this.service.getRoleUserList(this.roleId).subscribe(result => {
      if (result.length > 0) {
        this.userList = result;
        this.ejUserDialog.show();
      } else {
        this.ejUserDialog.show();
      }
    })
  }

  userGridSettings() {
    this.userstateParams = {
      create: () => {
        this.userstateElem = document.createElement('input');
        return this.userstateElem;
      },
      read: () => {
        return (this.userstateObj as any).text;
      },
      destroy: () => {
        (this.userstateObj as any).destroy();
      },
      write: () => {
        this.userstateObj = new DropDownList({
          dataSource: this.allUserList,
          fields: { value: 'userId', text: 'fullName'  },
          enabled: true,
          placeholder: 'seleccionar',
          floatLabelType: 'Never',
          allowFiltering: true,
          change: this.valUserChange
        });
        this.userstateObj.appendTo(this.userstateElem);
      }
    }
  }

  permitGridSettings() {
    this.permitStateParams = {
      create: () => {
        this.permitStateElem = document.createElement('input');
        return this.permitStateElem;
      },
      read: () => {
        return (this.permitStateObj as any).text;
      },
      destroy: () => {
        (this.permitStateObj as any).destroy();
      },
      write: () => {
        this.permitStateObj = new DropDownList({
          dataSource: this.allPermissions,
          fields: { value: 'permissionId', text: 'permisionCode'  },
          enabled: true,
          placeholder: 'seleccionar',
          floatLabelType: 'Never',
          allowFiltering: true,
          change: this.valPermissionChange
        });
        this.permitStateObj.appendTo(this.permitStateElem);
      }
    }
  }

  getAllUserList() {
    this.service.getAllUsers().subscribe(res => {
      if(res.length > 0) { 
        this.allUserList = res.filter(x => x.roleId == 0 && x.deleted == false);
        this.userGridSettings();
      }
    });
  }

  getAllPermissionList() {
    this.service.getAllPermission().subscribe(res => {
      if(res.length > 0) {
        this.allPermissions = res.filter(x => x.deleted == false);        
        this.permitGridSettings();
      }
    });
  }

  // Enables the footer buttons
  public buttons: Object = [
    {
      'click': this.hideDialog.bind(this),
      buttonModel: {
        content: 'Cerrar'
      }
    }
  ];

  onClickClose(_event) {
    this.modal.close();
  }

  actionRoleBegin(args: EditEventArgs) {
    if ((args as any).requestType === 'add') {
      for (const cols of (this.roleGrid as any).columns) {
        if ((cols as Column).field === 'roleCode') {
          (cols as Column).allowEditing = true;
        }
      }
    } else if ((args as any).requestType === 'beginEdit') {
      for (const cols of (this.roleGrid as any).columns) {
        if ((cols as Column).field === 'roleCode') {
          (cols as Column).allowEditing = false;
        }
      }
    }
    // if ((args as any).requestType === 'refresh' || (args as any).requestType === 'paging') {
    //   this.roleGrid.hideColumns('RoleId');
    // }
  }

  actionRoleComplete(args: any) {
    if ((args as any).requestType === 'save') {
      const editData = args.data;
      this.service.saveUpdateRoles(editData).subscribe(_result => {
        if (_result && _result.result == "success") {
          this.toastrService.success('Guardado exitosamente', 'Éxito');
          this.getAllRoles();          
        }
        this.roleGrid.endEdit();
      }, (e) => {
        console.error(e);
        this.roleGrid.closeEdit();
      });
    }
  }

  actionUserComplete(args: any) {    
    if ((args as any).requestType === 'save') {
      const editData = args.data;
      
      const user = this.allUserList.filter(x => x.fullName == editData["fullName"]);
      if (user.length > 0) {
        const userRoles = {
          roleId: this.roleId,
          userId: user[0]["userId"]
        };
        this.service.saveuserRoles(userRoles).subscribe(_result => {
          if (_result && _result.result == "success") {
            this.toastrService.success('Guardado exitosamente', 'Éxito');
            this.getRoleUserList();
            this.getAllUserList();
          }
          this.userGrid.endEdit();        
        }, (e) => {
          console.error(e);
          this.userGrid.closeEdit();
        });
      } else {
        this.userGrid.closeEdit();
      }    
    }
  } 

  actionPermitComplete(args: any) {
    if ((args as any).requestType === 'save') {
      const editData = args.data;
      
      const user = this.allPermissions.filter(x => x.permisionCode == editData["permisionCode"]);
      if (user.length > 0) {
        const rolePermission = {
          roleId: this.roleId,
          permissionId: user[0]["permissionId"]
        };
        this.service.saveRolePermisssion(rolePermission).subscribe(_result => {
          if (_result && _result.result == "success") {
            this.toastrService.success('Guardado exitosamente', 'Éxito');
            this.getRolePermissions();
          } else {
            this.getRolePermissions();
            this.toastrService.warning('El permiso ya existe', 'Advertencia');
          }
          this.permissionGrid.endEdit();        
        }, (e) => {
          console.error(e);
          this.permissionGrid.closeEdit();
        });
      } else {
        this.permissionGrid.closeEdit();
      }    
    }
  }

  onClickUserDelete(selectedrecords: any) {
    let userList = [];
    selectedrecords.forEach(element => {
      var obj = {RoleId: element.roleId , userId: element.userId};
      userList.push(obj);      
    });

    this.service.DeleteRoleUser(userList).subscribe(result => {
      if (result && result.result == "success") {
        this.toastrService.success('Borrado exitosamente !', 'Éxito');
        this.getRoleUserList();
      }        
    }, (e) => {
      console.error(e);
    });
  }


  public onDeleteUsers(_event: any): void {    
    let selectedrecords = (this.userGrid as any).getSelectedRecords();   

    if (selectedrecords.length > 0) {
      Swal.fire({
        title: '¿Estás seguro de borrar la acción?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Comienza a borrar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          this.onClickUserDelete(selectedrecords);
        }        
      });  
    } else {
      this.toastrService.info('seleccione una fila para eliminar !', 'Información');
    }    
  }

  public onDeletePermission(_event: any): void {    
    let selectedrecords = (this.permissionGrid as any).getSelectedRecords();   

    if (selectedrecords.length > 0) {
      Swal.fire({
        title: '¿Estás seguro de borrar la acción?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Comienza a borrar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          this.onClickPermissionDelete(selectedrecords);
        }        
      });  
    } else {
      this.toastrService.info('seleccione una fila para eliminar !', 'Información');
    }    
  }

  onClickPermissionDelete(selectedrecords: any) {
    let userList = [];
    selectedrecords.forEach(element => {
      var obj = {roleId: element.roleId , permissionId: element.permissionId};
      userList.push(obj);      
    });

    this.service.DeleteRolePermission(userList).subscribe(result => {
      if (result && result.result == "success") {
        this.toastrService.success('Borrado exitosamente !', 'Éxito');
        this.getRolePermissions();
      }        
    }, (e) => {
      console.error(e);
    });
  }


}
