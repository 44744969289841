

export const environment = {
  production: false,
  //baseUrl: 'http://localhost:63106/api/', // LOCAL URL
  // baseUrl: 'https://v3.qa.api.actuapp.qualitapps.com/api/', // QA URL
  // baseUrl: 'https://uat.api.actuapp.qualitapps.com/api/', // UAT URL
  baseUrl: 'https://actapi.geseme.com/api/', // LIVE
  tokenUrl: null,
  loginUrl: '/login',
  gesemeApiUrl: 'https://api.geseme.com/',
  gesemeIdentityServerUrl: 'https://it.geseme.com/',
  gesemeLoginScope: 'openid profile prl_read prl_write usr_read',
  gesemeApiClientId: 'actuacweb',
  // local
  //webRootUrl: 'http://localhost:4200/',
  // QA
  // webRootUrl: 'https://v3.qa.actuapp.qualitapps.com/',
  // UAT
  // webRootUrl:https://uat.actuapp.qualitapps.com/
  // LIVE
  webRootUrl: 'https://act.geseme.com/',
  authNoAccessCode: '-1', // DENIED ACCESS for any app
  authAdminCode: '0', // ADMIN for all apps
  authActuacWebAdminCode: '100', // edit any actuacion and many other admin functions (i.e. admin permissions)
  authActuacWebNoAccessCode: '101', // cannot read/write/access any functionality of actuaciones
  authActuacWebFullEditCode: '102', // technical responsible: edit any actuacion
  authActuacWebOnlyMine: '103' // only can edit actuaciones assigned/created to/by him or where he is the technical of reference from associated workcenter

};

