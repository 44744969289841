import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DropdownService } from "src/app/services/dropdown.service";
import { ErrorHandlerService } from "src/app/services/error-handler.service";
import { MasterDataService } from "src/app/services/master-data.service";
import { WizardComponent } from "angular2-wizard";
import { ParteDeVisitaState } from "src/app/models/Enum/ParteDeVisitaState";
import { GeneratePdfService } from "src/app/services/common/generate-pdf.service";
import { SelectOptions } from "src/app/models/select-options.model";
import { ActiveContract } from "src/app/models/active-contracts";
import { switchMap } from "rxjs/operators";
import { Instance, NgTippyService, Options } from "angular-tippy";
import {
  ProgramacionAnualActions,
  ProgramacionAnualRegisters,
  ProgramacionData,
  ProgramacionDataInsert,
} from "src/app/models/Programacion";
import { MemoriaAnualService } from "src/app/services/common/memoria-anual.service";
import moment from "moment";

@Component({
  selector: "app-annual-shipping-micro-sme",
  templateUrl: "./annual-shipping-micro-sme.component.html",
  styleUrls: ["./annual-shipping-micro-sme.component.scss"],
})
export class AnnualShippingMicroSMEComponent implements OnInit, AfterViewInit {
  public shippingSmeForm2: FormGroup;
  activityTypes$: SelectOptions[];
  @Input() public selectedActivity;
  contracts = [
    { id: 1, name: 'abc-abc' }
  ];
  disableSendButton: boolean = true;

  visitsList = [
    { id: 0 },
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
    { id: 9 },
    { id: 10 },
    { id: 11 },
    { id: 12 }
  ];

  period: any = [];

  selectedValue: string;
  public ActivityTypeForm = new FormGroup({
    ActivityType: new FormControl(null, Validators.required),
  });
  userEmailAddress = "";
  emailAddresses = [];
  public emailValidators = [Validators.email];
  public emailPlaceholder =
    "ingrese la (s) dirección (s) de correo electrónico";
  public emailAddMoreText = "";
  public selectedCompanyLanguage = "es";
  public selectedFirstYear = "sí";
  min: any;
  max: any = 45;
  public contractDate = "";
  tableData: any[] = [];
  date = new Date();
  tooltipString: string;
  infoTooltipConfig: Options;
  newInsertProgramacionAnual: ProgramacionData[];
  programacionIds = [];
  lastGenId: number;
  actuacionTypes: any;
  isPeriodDisabled: boolean = false;
  activityRequest = {
    page_number: 0,
    page_size: 100,
    incl: "-99"
  }
  userDetailsId: any;

  @ViewChild("tippyelement") tippyElement: ElementRef;

  private tippyInstance: Instance;

  getFormattedDate2(date1) {
    let year = date1.getFullYear();
    let month = (1 + date1.getMonth()).toString().padStart(2, "0");
    let day = date1.getDate().toString().padStart(2, "0");
    return day + "/" + month + "/" + year;
  }

  getFormattedDateReturn(date1) {
    let year = date1.getFullYear();
    let month = (1 + date1.getMonth()).toString().padStart(2, "0");
    let day = date1.getDate().toString().padStart(2, "0");

    return year + "-" + month + "-" + day;
  }

  @Input() public addEditActivityForm = new FormGroup({
    ActivtyTypeName: new FormControl(null),
  });

  @ViewChild("wizardComponent") wizardComponent: WizardComponent;

  public ParteDeVisitaStates = ParteDeVisitaState;
  public SelectedParteDeVisitaState: ParteDeVisitaState;

  public IsValidated = false;

  public sortedActiveContracts: ActiveContract[] = [];
  testx: string = "dd";
  constructor(
    public shippingSmeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private dropdownService: DropdownService,
    private errorHandler: ErrorHandlerService,
    private masterDataService: MasterDataService,
    private memoriaAnualService: MemoriaAnualService,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private generatePdfService: GeneratePdfService,
    private changeDetectorRef: ChangeDetectorRef,
    private tippyService: NgTippyService,
    private cdr: ChangeDetectorRef
  ) {
    this.changeDetectionShippingSmeForm();
  }

  async ngOnInit(): Promise<void> {
    this.generatePdfService.shareData = this.selectedActivity;
    this.activityRequest.page_number = 0;
    await this.getAllContacts();
    this.changeWizardText();
    //this.getToolTipData();
    this.InitFormWizard3();
    this.initializeForm();
    this.setEmailContent();
    this.IsValidated =
      this.SelectedParteDeVisitaState === this.ParteDeVisitaStates.Validated;
    this.initDropdowns();    
    this.setShippingModelValues();
    this.changeDetectionShippingSmeForm();

    const workCenterId = this.selectedActivity.WorkCentreId;

    this.dropdownService.getContactEmailAddress(workCenterId).subscribe(response => {
      if (response.length > 0) {
        response.forEach(element => {
          const emailObj = { display: element, value: element }
          this.emailAddresses.push(emailObj);
        });
      }
    });

  }

  changeDetectionShippingSmeForm() {
    this.shippingSmeForm.get("TemplateLanguage").valueChanges.subscribe((x) => {
      this.initDropdowns();
    });
  }

  setlang(event) {
    this.shippingSmeForm.patchValue({
      TemplateLanguage: event.target.value,
    });
    this.setShippingModelValues();
  }

  onStep1Next() {
    if (this.contracts.length === 0) {
      return;
    }
    this.min = this.shippingSmeForm?.value?.Inicio;
    this.getTableData();
    this.setEmailContentAnual();
    this.selectedCompanyLanguage = this.shippingSmeForm.value.TemplateLanguage;   
  }

  removegridData() {
    // this.tableData.sort((a: any, b: any) => {
    //   return <any>new Date(a.date) - <any>new Date(b.date);
    // });
    if (this.shippingSmeForm.value.PrimerAño == "sí") {
      let i = this.tableData.findIndex((x) => x.es === "Visita de Seguimiento");

      if(i > 0){
        this.tableData.splice(i, 1);
      }  
      i = this.tableData.findIndex((x) => x.es === "Simulacro de Emergencias");
      if (i > 0) {
        this.tableData.splice(i, 1);
      }
    } else {
      let i = this.tableData.findIndex((x) => x.ca === "Visita Inicial de Presa de Dades");
      if (i > 0) {
        this.tableData.splice(i, 1);
      }
      i = this.tableData.findIndex((x) => x.ca === "Manual de Prevenció");
      if (i > 0) {
        this.tableData.splice(i, 1);
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.IsValidated) {
      this.setParteDeVisitaState();
    }
  }

  removeData(index) {
    this.tableData = this.tableData.filter((v, i) => i != index);
  }

  dateChange(i, e) {
    this.tableData = this.tableData.map((obj, index) =>
      index === i ? { ...obj, date: e.toISOString() } : obj
    );
  }

  onDuration(i, e) {
    if (e <= 365 && e > 0) {
    this.tableData = this.tableData.map((obj, index) =>
      index === i ? { ...obj, duration: e } : obj
    );
    } else {
      this.tableData = this.tableData.map((obj, index) =>
        index === i ? { ...obj, duration: null } : obj);
    }
  }

  getToolTipData() {
    this.dropdownService.getWorkCentreDetails(this.selectedActivity.WorkCentreId).pipe(
      switchMap((response: any) => {
        return this.dropdownService.GetActiveContractsByCentroCode(response.codigoCentro);
      })).subscribe((res: any) => {
        if (res.length > 0) {
          let formattedDatesArray = res.map((obj) => {
            return {
              ...obj,
              dateBeginContract: this.getFormattedDateReturn(
                new Date(obj.dateBeginContract)
              ),
              dateEndContract: this.getFormattedDateReturn(
                new Date(obj.dateEndContract)
              ),
              dateFirstContact: this.getFormattedDateReturn(
                new Date(obj.dateFirstContact)
              ),
            };
          });
          formattedDatesArray = formattedDatesArray.map((obj) => {
            return {
              ...obj,
              dateBeginContract: new Date(obj.dateBeginContract),
              dateEndContract: new Date(obj.dateEndContract),
              dateFirstContact: new Date(obj.dateFirstContact),
            };
          });
          formattedDatesArray = formattedDatesArray.sort(
            (a, b) => Number(b.dateBeginContract) - Number(a.dateBeginContract)
          );
          var dateFirst = formattedDatesArray[0].dateEndContract;
          var dateBegin = formattedDatesArray[0].dateBeginContract;

          this.min = this.getFormattedDate2(new Date(dateFirst));
          this.max = this.getFormattedDate2(new Date(dateBegin));
          const contractDate = this.contracts[0]?.name?.split('-');
          this.contractDate = contractDate[0]?? '';
          this.date = new Date(dateFirst);

          this.sortedActiveContracts = formattedDatesArray.map((obj) => {
            return {
              ...obj,
              dateBeginContract: this.getFormattedDate2(obj.dateBeginContract),
              dateEndContract: this.getFormattedDate2(obj.dateEndContract),
              dateFirstContact: this.getFormattedDate2(obj.dateFirstContact),
            };
          });
              const { Inicio, Fin } = this.shippingSmeForm.value;
              const initialContract = { dateBeginContract: Inicio, dateEndContract: Fin };

              if (this.sortedActiveContracts.length > 0) {
                const topContract = this.sortedActiveContracts[0];
                
                const isContractDifferent = Inicio !== topContract.dateBeginContract || Fin !== topContract.dateEndContract;
                
                localStorage.setItem("topContracts", JSON.stringify(isContractDifferent ? initialContract : topContract));
              } else {
                localStorage.setItem("topContracts", JSON.stringify(null));
              }
        }
        else {
          this.min = '01/01/' + new Date().getFullYear();
          this.max = '01/01/' + new Date().getFullYear();
          this.contractDate = this.max;
          this.date = new Date();
        }

        if (this.sortedActiveContracts.length > 0) {
          var newActiveContracts = this.sortedActiveContracts.filter(function (el) {
            return el.especialidades_Preven.includes('S') ||
              el.especialidades_Preven.includes('H') ||
              el.especialidades_Preven.includes('P')
          });
          if (newActiveContracts.length > 1) {
            this.shippingSmeForm.get("PrimerAño").setValue("no");
          }
          else { // 0 , 1, null
            this.shippingSmeForm.get("PrimerAño").setValue("sí");
          }
        } else { // 0 , 1, null
          this.shippingSmeForm.get("PrimerAño").setValue("sí");
        }

        this.tooltipString =
          "<div class='row ml-0 mb-1 mr-1'>" +
          "<label class='font-weight-bold'>Primer contrato: </label>" +
          "<span class='ml-1'>";
        this.tooltipString +=
          this.min + "</span>" + "</div>" + "<table>" + "<tbody>";
        this.sortedActiveContracts.forEach((element) => {
          this.tooltipString += "<tr>";
          this.tooltipString += "<td>" + element.dateBeginContract + "</td>";
          this.tooltipString += "<td>" + element.dateEndContract + "</td>";
          this.tooltipString +=
            "<td>" + element.especialidades_Preven + "</td>";
          this.tooltipString += "<td>" + element.especialidad_VS + "</td>";
          this.tooltipString += "</tr>";
        });
        this.tooltipString += "</tbody></table>";

        document.getElementById("tooltipContent").innerHTML =
          this.tooltipString;
        this.setToolTipoption();
      });
  }

  async DownloadHtmlReport(ids): Promise<void> {
    this.generatePdfService.convertHtmlToPdf(ids);
  }

  initDropdowns(incl: string = "-99", isScroll = false) {
   
    this.activityRequest.incl = incl ? incl : null;
    this.activityRequest.page_number = isScroll ? (this.activityRequest.page_number + 1) : 0;

    this.dropdownService.getActivityTypes(this.activityRequest.incl , 100, this.addEditActivityForm.value.ActivityTypeId , false, false, this.activityRequest.page_number).subscribe((response) => {
        this.actuacionTypes = response;

        if (isScroll) {
          this.activityTypes$ = this.activityTypes$.concat(this.dropdownService.WrappedToDropDownListWithLang(this.shippingSmeForm.get("TemplateLanguage").value,
          response,
          "name",
          "nameCatala",
          "activityTypeId"
        ));
        } else {
          this.activityTypes$ = this.dropdownService.WrappedToDropDownListWithLang(this.shippingSmeForm.get("TemplateLanguage").value,
            response,
            "name",
            "nameCatala",
            "activityTypeId"
          );
        }
      });
  }  

  onScrollActivity() { 
    this.initDropdowns("-99",true);
  }

  onChangeActivity() {
    this.activityRequest.page_number = 0;
  }

  onKeyUpTypeOfActivity(activityName) {
    let name = activityName.target.value;
    this.activityRequest.page_number = 0;
    if (name && name.length >= 4) {       
      this.initDropdowns(name ,false);
    } else {
      if (name.length == 0) {
        this.initDropdowns("-99",false);
      }
    }    
  }

  onCloseActivity() {
    this.ActivityTypeForm.controls.ActivityType.setValue(null);
  }


  addToArray() {
    let customeId = this.programacionIds[this.programacionIds.length - 1];
    customeId = Number(customeId + 1);
    this.newInsertProgramacionAnual = [];
    const actuacionType = this.actuacionTypes.filter((obj) => {
      return obj.activityTypeId === this.ActivityTypeForm.value.ActivityType.value;
    });
    if (this.shippingSmeForm.get("TemplateLanguage").value === "es") {
      this.tableData = [
        ...this.tableData,
        {
          id: customeId,
          ca: actuacionType[0].nameCatala,
          es: this.ActivityTypeForm.value.ActivityType.label,
          date: "",
          duration: "",
          equation: 0,
        },
      ];

      this.newInsertProgramacionAnual.push({
        id: customeId,
        ca: actuacionType[0].nameCatala,
        es: this.ActivityTypeForm.value.ActivityType.label,
        date: "",
        duration: "",
        equation: 0,
      });
    } else {
      this.tableData = [
        ...this.tableData,
        {
          id: customeId,
          ca: this.ActivityTypeForm.value.ActivityType.label,
          es: actuacionType[0].name,
          date: "",
          duration: "",
          equation: 0,
        },
      ];

      this.newInsertProgramacionAnual.push({
        id: customeId,
        ca: this.ActivityTypeForm.value.ActivityType.label,
        es: actuacionType[0].name,
        date: "",
        duration: "",
        equation: 0,
      });
    }
  }

  private setParteDeVisitaState(): void {
    this.shippingSmeForm2.disable();
    this.shippingSmeForm3.disable();

    this.wizardComponent.steps.forEach((v, i) => {
      v.isActive = false;
      v.isValid = true;
      v.isDisabled = false;
    });

    const step =
      this.wizardComponent.steps[this.wizardComponent.steps.length - 1];
    step.isActive = true;
    step.isValid = false;

    this.wizardComponent.goToStep(step);
    this.changeDetectorRef.detectChanges();
  }

  shippingSmeForm = new FormGroup({
    TemplateLanguage: new FormControl(
      this.selectedCompanyLanguage,
      Validators.required
    ),
    PrimerAño: new FormControl(this.selectedFirstYear, Validators.required),
    visits: new FormControl(),
    DateRange: new FormControl(),
    Inicio: new FormControl(),
    Fin: new FormControl(),
  });

  shippingSmeForm3 = new FormGroup({
    TemplateLanguage: new FormControl(
      this.selectedCompanyLanguage,
      Validators.required
    ),
    ContactEmail: new FormControl(
      this.selectedCompanyLanguage,
      Validators.required
    ),
    EmailSubject: new FormControl(this.selectedFirstYear, Validators.required),
    EmailBody: new FormControl("1"),
  });

  InitFormWizard3() {
    this.shippingSmeForm3 = this.formBuilder.group({
      EmailSubject: ["", Validators.required],
      EmailBody: ["", Validators.required],
      ContactEmail: [null],
    });
  }

  initializeForm(): void {
    const loggedUserId = JSON.parse(localStorage.getItem("loggeduser")).id;
    this.dropdownService.getUserEmailAddress(loggedUserId).subscribe(
      (response) => {
        this.userEmailAddress = response.userEmail ? response.userEmail : "";
        if (this.userEmailAddress) {
          const emailObj = {
            display: this.userEmailAddress,
            value: this.userEmailAddress,
          };
          this.emailAddresses.push(emailObj);
        }
      },
      (error) => {
        this.errorHandler.handle(error);
      }
    );
  }

  public downloadAttachment(): void {
    this.spinner.show();
    this.setShippingModelValues();
    this.masterDataService
      .getShippingActivityAttachment(this.selectedActivity)
      .subscribe(
        (response) => {
          const linkSource =
            "data:application/pdf;base64," + response.base64String + "\n";
          const downloadLink = document.createElement("a");
          const fileName = response.fileName;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          this.errorHandler.handle(error);
          this.toastrService.error(
            "No se pudo descargar el archivo adjunto",
            "Error"
          );
        }
      );
  }

  public async sendShippingSmeEmail(ids): Promise<void> {
    if(this.disableSendButton){
      
      this.toastrService.error('Opción deshabilitada temporalmente: descarga y revisa el PDF y envíalo manualmente, una vez comprobado.');
      return;
    }
    if (this.shippingSmeForm3.valid && this.emailAddresses.length > 0) {
      this.spinner.show();
      await this.generatePdfService.convertHtmlToPdf(ids, true);


      setTimeout(() => {
        this.setShippingModelValues();
        this.masterDataService
          .sendMeShiipingSmeEmail(this.selectedActivity)
          .subscribe(
            (response) => {
              this.spinner.hide();
              this.shippingSmeModal.close();
              this.toastrService.success(
                "El correo electrónico se ha enviado correctamente a las direcciones de correo electrónico proporcionadas.",
                "Éxito"
              );
            },
            (error) => {
              this.spinner.hide();
              this.errorHandler.handle(error);
              this.toastrService.error(
                "No se pudo enviar el correo electrónico",
                "Error"
              );
            }
          );
      }, 8000);
    } else {
      this.toastrService.warning(
        "Ingrese detalles válidos para continuar",
        "Advertencia"
      );
    }
    //save data
    this.addprogramacionAnualRegisters();
    this.addProgramacionAnualActions();
  }

  private setShippingModelValues(): void {
    if (this.shippingSmeForm.value.TemplateLanguage === "es") {
      this.selectedCompanyLanguage = "es";
      this.selectedActivity.EmailSubject =
        "[GESEME] Programación anual de la actividad preventiva";
      this.selectedActivity.EmailBody =
        `Estimado/a cliente. \n\n` +
        `Adjunto le remito la “Programación anual de la actividad preventiva” que recoge las actuaciones a llevar ` +
        `a cabo por GESEME en su empresa, a lo largo del presente período contractual. \n\n` +
        `Atentamente,`;
    } else {
      this.selectedCompanyLanguage = "ca";
      this.selectedActivity.EmailSubject =
        "[GESEME] Programació anual de l’activitat preventiva";
      this.selectedActivity.EmailBody =
        `Benvolgut/da client, \n\n` +
        `Li adjuntem la “Programació anual de l'activitat preventiva” ` +
        `que recull les actuacions a dur a terme per GESEME en la seva empresa, al llarg del present període contractual. \n\n` +
        `Atentament,`;
    }
    this.setEmailContent();
    this.selectedActivity.RecipientEmail = this.emailAddresses.map(
      (item) => item.display
    );
    this.selectedActivity.EmailSubject =
      this.shippingSmeForm3.value.EmailSubject;
    this.selectedActivity.EmailBody = this.shippingSmeForm3.value.EmailBody;
    this.selectedActivity.TemplateLanguage =
      this.shippingSmeForm3.value.TemplateLanguage;
    this.selectedActivity.base64String = localStorage.getItem("base64string");
    this.selectedActivity.filePath = localStorage.getItem("filename");
  }

  changeWizardText(isFirma = false): void {
    const modalElement = document.getElementById("modal-annual-report-1");
    const footer = modalElement.getElementsByClassName("card-footer")[0];
    const footerBtns = footer.getElementsByClassName("btn");

    for (let index = 0; index < footerBtns.length; index++) {
      footerBtns[index].classList.add("wizard-buttons");
      if (footerBtns[index].innerHTML === "Previous") {
        footerBtns[index].innerHTML = "Anterior";
      } else if (footerBtns[index].innerHTML === "Next") {
        footerBtns[index].innerHTML = "Siguiente";
      } else if (footerBtns[index].innerHTML === "Done") {
        const doneBtn = footerBtns[index] as HTMLElement | null;
        if (doneBtn != null) {
          doneBtn.style.display = "none";
        }
      }
    }
  }

  setEmailContent(): void {
    let emailSubject = "";
    let emailBody = "";
    if (this.shippingSmeForm.value.TemplateLanguage === "es") {
      this.selectedCompanyLanguage = "es";
      emailSubject = "[GESEME] Programación anual de la actividad preventiva";
      emailBody =
        `Estimado/a cliente. \n\n` +
        `Adjunto le remito la “Programación anual de la actividad preventiva” que recoge las actuaciones a llevar ` +
        `a cabo por GESEME en su empresa, a lo largo del presente período contractual. \n\n` +
        `Atentamente,`;
    } else {
      this.selectedCompanyLanguage = "ca";
      emailSubject = "[GESEME] Programació anual de l’activitat preventiva";
      emailBody =
        `Benvolgut/da client, \n\n` +
        `Li adjuntem la “Programació anual de l'activitat preventiva” ` +
        `que recull les actuacions a dur a terme per GESEME en la seva empresa, al llarg del present període contractual. \n\n` +
        `Atentament,`;
    }
    this.shippingSmeForm.patchValue({
      EmailSubject: emailSubject,
      EmailBody: emailBody,
    });
  }

  //This method email content will be changed when client gave the real content.
  setEmailContentAnual(): void {
    let emailSubject = "";
    let emailBody = "";
    if (this.shippingSmeForm.value.TemplateLanguage === "es") {
      this.selectedCompanyLanguage = "es";
      emailSubject = "[GESEME] Programación anual de la actividad preventiva";
      emailBody =
        `Estimado/a cliente. \n\n` +
        `Adjunto le remito la “Programación anual de la actividad preventiva” que recoge las actuaciones a llevar ` +
        `a cabo por GESEME en su empresa, a lo largo del presente período contractual. \n\n` +
        `Atentamente,`;
    } else {
      this.selectedCompanyLanguage = "ca";
      emailSubject = "[GESEME] Programació anual de l’activitat preventiva";
      emailBody =
        `Benvolgut/da client, \n\n` +
        `Li adjuntem la “Programació anual de l'activitat preventiva” ` +
        `que recull les actuacions a dur a terme per GESEME en la seva empresa, al llarg del present període contractual. \n\n` +
        `Atentament,`;
    }
    this.shippingSmeForm3.patchValue({
      EmailSubject: emailSubject,
      EmailBody: emailBody,
    });
  }

  setToolTipoption() {
    this.infoTooltipConfig = {
      content: this.tooltipString,
      allowHTML: true,
      theme: "info-tippy",
      trigger: "click",
      placement: "right",
      arrow: true,
    };
    this.tippyInstance = this.tippyService.init(
      this.tippyElement,
      this.infoTooltipConfig
    );
  }

  getTableData() {
    let filterData: any[];
    this.masterDataService.getProgramacionAnualDefaultActions().subscribe((res) => {
      if (res) {
        let dT: ProgramacionData[] = [];
        if (this.shippingSmeForm.value.PrimerAño == "sí") {
          filterData = res.filter(x => x.isFirstYear == true);
        }
        else {
          filterData = res.filter(x => x.isFirstYear == false);
        }

        filterData.forEach((item) => {
          this.programacionIds.push(item.id);
          dT.push({
            id: item.id,
            ca: item.titleCatalan,
            es: item.titleSpanish,
            date: this.calculateBeginDate(item.dateBegin).toString(),
            duration: item.duration,
            equation: 0,
          });
        });
        this.tableData = dT;
      }
    },
      err => console.error('Observer got an error: ' + err),
      () => {
        this.repeatVisitasSeguimiento();
      });
  }

  calculateBeginDate(Datebegin: number) {
    const trimmedMax = this.min.trim();
    const datePattern = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    const match = trimmedMax.match(datePattern);
  
    if (!match) {
      throw new Error("Invalid date format");
    }
  
    const day = match[1];
    const month = match[2];
    const year = match[3];
    const formatDate = `${year}-${month}-${day}`;
    
    const newDate = new Date(formatDate);
  
    if (isNaN(newDate.getTime())) {
      throw new Error("Invalid date");
    }
    newDate.setDate(newDate.getDate() + Datebegin);
    return newDate.toISOString();
  }

  repeatVisitasSeguimiento() {
    var tableCount = this.tableData.filter(x => x.ca == "Visita de Seguiment").length;
      
    for (let i = 0; i < tableCount; i++) {
      let index = this.tableData.findIndex(x => x.ca == "Visita de Seguiment");
      this.tableData.splice(index,1);
    }   

    let visits = Number(this.shippingSmeForm.value.visits);
    if(this.shippingSmeForm.value.PrimerAño == "sí"){
      visits += 1
    }
    let visitaDate = this.shippingSmeForm.controls.Inicio.value;
    if (visitaDate != null) {
      let formatDate = visitaDate.substring(6, 10) + "-" + visitaDate.substring(3, 5) + "-" + visitaDate.substring(0, 2);
      let newDate = new Date(formatDate);
  
      if (visits > 0) {
        let period = 365 / visits;
        for (let i = 0; i < visits; i++) {
          if (i != 0) {
            newDate.setDate(newDate.getDate() + period);
            visitaDate = newDate;
          }
  
          this.tableData.push({
            ca: "Visita de Seguiment",
            es: "Visita de Seguimiento",
            date: this.getFormattedDateReturn(newDate),
            duration: "30",
            equation: 0,
            id: 0,
          });
        }
      }
    }  

    this.handlePrimerAnoCondition();
    const reverseArray = this.tableData.reverse();
    this.tableData = reverseArray;
    this.removegridData();
    this.cdr.detectChanges();
  }

  private handlePrimerAnoCondition() {
    if (this.shippingSmeForm.value.PrimerAño === "sí") {
        let visitdataList = this.tableData.filter(item => item.ca === 'Visita de Seguiment');
        
        // Find the record with the lowest date
        let minDateRecord = visitdataList.reduce((min, current) => 
            new Date(min.date) < new Date(current.date) ? min : current
        );

        // Remove the record with the lowest date
        visitdataList = visitdataList.filter(item => item !== minDateRecord);

        this.tableData = this.tableData.filter(record => record.ca !== 'Visita de Seguiment');
        visitdataList.forEach(item => this.tableData.push(item));
    }
}

  //add new programacion register
  addProgramacionAnualActions() {
    let allProgramacions = [...this.tableData, this.newInsertProgramacionAnual];
    let data: ProgramacionAnualActions[] = [];

    allProgramacions.forEach((res) => {
      if (res) {
        data.push({
          ProgramacionId: res.id == undefined ? 0 : res.id,
          ActionId: this.selectedActivity.ActivityId,
          Datebegin: new Date(res.date),
          Duration: res.duration,
          GenerationDate: new Date(),
          TitleSpanish: res.es,
          TitleCatalan: res.ca,
        });
      }
    });
    this.masterDataService
      .saveProgramacionAnualActions(data)
      .subscribe((res) => {
        console.log(res);
      });
  }
  
  //add new programacion actions
  addprogramacionAnualRegisters() {
    let data: ProgramacionAnualRegisters = {
      ProgramacionIds: this.programacionIds.toString(),
      ActionId: this.selectedActivity.ActivityId,
      CompanyId: this.selectedActivity.CompanyId,
      UserId: this.selectedActivity.UserId,
      EmailSent: this.shippingSmeForm3.value.EmailBody,
      Emailcc: this.emailAddresses.toString(),
      EmailTo: this.emailAddresses.toString(),
    };
    this.masterDataService
      .saveProgramacionAnualRegisters(data)
      .subscribe((res) => {
        console.log(res);
      });
  }

  async getAllContacts() {
    this.contracts = [];
    var obj = {
      codigo_centro: this.selectedActivity.codigoCentro,
      id_actuaciontipo: this.selectedActivity.ActivityTypeId,
      incluye_prevencion: true,
      incluye_vigilancia: false
    }
    localStorage.setItem('codigoCentro', this.selectedActivity.codigoCentro);
    if(this.selectedActivity.ActivtyTypeName.toLowerCase().includes("programación anual") &&
      ((this.selectedActivity.ActivtyTypeName.toLowerCase().includes("envío") && 
      this.selectedActivity.ActivtyTypeName.toLowerCase().includes("entrega"))
       || this.selectedActivity.ActivtyTypeName.toLowerCase().includes("elaboración")))
    {
      this.memoriaAnualService.getAllContracts(obj).subscribe(res => {
        if (res.webError != null) {
          this.spinner.hide();
          this.errorHandler.handle(res.webError.messageToUser, true);
        }
        else if (res.data.length > 0) {
          
            if(this.selectedActivity.ActivtyTypeName.toLowerCase().includes("envío") &&
            this.selectedActivity.ActivtyTypeName.toLowerCase().includes("entrega"))
            {
              var currentContract = res;
              {
                this.shippingSmeForm.patchValue({
                  DateRange: Number(currentContract.data[0].docEntry),
                  Inicio: moment(new Date(currentContract.data[0].dateBeginContract)).format('DD/MM/yyyy'),
                  Fin: moment(new Date(currentContract.data[0].dateEndContract)).format('DD/MM/yyyy')
                });
                
                currentContract.data.forEach((item) =>{
                  let obj = {
                    id: item.docEntry,
                    name: moment(new Date(item.dateBeginContract)).format('DD/MM/yyyy') + ' - ' + moment(new Date(item.dateEndContract)).format('DD/MM/yyyy')
                  }
                  this.period.push(obj);
                })
                this.contracts = this.period;
              }
            }
            else if (this.selectedActivity.ActivtyTypeName.toLowerCase().includes("elaboración")){
              var closedContracts = res
              if(closedContracts.data.length > 0)
              {
                
                this.shippingSmeForm.patchValue({
                  DateRange: Number(closedContracts.data[0].docEntry),
                  Inicio: moment(new Date(closedContracts.data[0].dateBeginContract)).format('DD/MM/yyyy'),
                  Fin: moment(new Date(closedContracts.data[0].dateEndContract)).format('DD/MM/yyyy')
                });
                
                closedContracts.data.forEach(element => {        
                  let obj = {
                    id: element.docEntry,
                    name: moment(new Date(element.dateBeginContract)).format('DD/MM/yyyy') + ' - ' + moment(new Date(element.dateEndContract)).format('DD/MM/yyyy')
                  }
                  this.period.push(obj);
                });
                this.contracts = this.period;
              }
            }
        }
        else {
          this.toastrService.warning("no hay contratos pasados para mostrar");
        }
      });
    }
    else {
      this.shippingSmeForm.controls.DateRange.disable();
      // this.isPeriodDisabled = false;
      this.memoriaAnualService.getAllClosedContracts(obj).subscribe(res => {
        if (res.webError != null) {
          this.spinner.hide();
          this.errorHandler.handle(res.webError.messageToUser, true);
        }
        else if (res.length > 0) {
          this.shippingSmeForm.patchValue({
            DateRange: Number(res[0].id),
            Inicio: moment(new Date(res[0].dateBeginContract)).format('DD/MM/yyyy'),
            Fin: moment(new Date(res[0].dateEndContract)).format('DD/MM/yyyy')
          });

          res.forEach(element => {
            let obj = {
              id: element.id,
              name: moment(new Date(element.dateBeginContract)).format('DD/MM/yyyy') + ' - ' + moment(new Date(element.dateEndContract)).format('DD/MM/yyyy')
            }
            this.period.push(obj);
          });
          this.contracts = this.period;
        }
        else {
          this.toastrService.warning("no hay contratos pasados para mostrar");
        }
      })
    }

  }



  // async getAllContacts() {
  //   this.contracts = [];
  //   debugger
  //   var obj = {
  //     codigo_centro: this.selectedActivity.codigoCentro,
  //     id_actuaciontipo: this.selectedActivity.ActivityTypeId,
  //     incluye_prevencion: true,
  //     incluye_vigilancia: false
  //   }
  //   localStorage.setItem('codigoCentro', this.selectedActivity.codigoCentro)
  //   if(this.selectedActivity.ActivtyTypeName.toLowerCase().includes("programación anual") &&
  //     ((this.selectedActivity.ActivtyTypeName.toLowerCase().includes("envío") && 
  //     this.selectedActivity.ActivtyTypeName.toLowerCase().includes("entrega"))
  //      || this.selectedActivity.ActivtyTypeName.toLowerCase().includes("elaboración")))
  //   {
  //     debugger
  //     this.memoriaAnualService.getAllContracts(obj).subscribe(res => {
  //       debugger
  //       if (res.webError != null) {
  //         this.spinner.hide();
  //         this.errorHandler.handle(res.webError.messageToUser, true);
  //       }
  //       else if (res.length > 0) {
          
  //           if(this.selectedActivity.ActivtyTypeName.toLowerCase().includes("envío") &&
  //           this.selectedActivity.ActivtyTypeName.toLowerCase().includes("entrega"))
  //           {
  //             var currentContract = res.filter(x=> {  return (new Date(x.dateEndContract) > new Date()) && (new Date(x.dateBeginContract) < new Date()) });
  //             if(currentContract.length > 0)
  //             {
  //               this.shippingSmeForm.patchValue({
  //                 DateRange: Number(currentContract[0].id),
  //                 Inicio: moment(new Date(currentContract[0].dateBeginContract)).format('DD/MM/yyyy'),
  //                 Fin: moment(new Date(currentContract[0].dateEndContract)).format('DD/MM/yyyy')
  //               });
                
  //               currentContract.forEach((item) =>{
  //                 let obj = {
  //                   id: item.id,
  //                   name: moment(new Date(item.dateBeginContract)).format('DD/MM/yyyy') + ' - ' + moment(new Date(item.dateEndContract)).format('DD/MM/yyyy')
  //                 }
  //                 this.period.push(obj);
  //               })
  //               this.contracts = this.period;
  //             }
  //           }
  //           else if (this.selectedActivity.ActivtyTypeName.toLowerCase().includes("elaboración")){
  //             var closedContracts = res.filter(x=> { 
  //               return (new Date(x.dateEndContract) < new Date()) });
  //             if(closedContracts.length > 0)
  //             {
                
  //               this.shippingSmeForm.patchValue({
  //                 DateRange: Number(closedContracts[0].id),
  //                 Inicio: moment(new Date(closedContracts[0].dateBeginContract)).format('DD/MM/yyyy'),
  //                 Fin: moment(new Date(closedContracts[0].dateEndContract)).format('DD/MM/yyyy')
  //               });
                
  //               closedContracts.forEach(element => {        
  //                 let obj = {
  //                   id: element.id,
  //                   name: moment(new Date(element.dateBeginContract)).format('DD/MM/yyyy') + ' - ' + moment(new Date(element.dateEndContract)).format('DD/MM/yyyy')
  //                 }
  //                 this.period.push(obj);
  //               });
  //               this.contracts = this.period;
  //             }
  //           }
  //       }
  //       else {
  //         this.toastrService.warning("no hay contratos pasados para mostrar");
  //       }
  //     });
  //   }
  //   else {
  //     this.shippingSmeForm.controls.DateRange.disable();
  //     // this.isPeriodDisabled = false;
  //     this.memoriaAnualService.getAllClosedContracts(obj).subscribe(res => {
  //       if (res.webError != null) {
  //         this.spinner.hide();
  //         this.errorHandler.handle(res.webError.messageToUser, true);
  //       }
  //       else if (res.length > 0) {
  //         this.shippingSmeForm.patchValue({
  //           DateRange: Number(res[0].id),
  //           Inicio: moment(new Date(res[0].dateBeginContract)).format('DD/MM/yyyy'),
  //           Fin: moment(new Date(res[0].dateEndContract)).format('DD/MM/yyyy')
  //         });

  //         res.forEach(element => {
  //           let obj = {
  //             id: element.id,
  //             name: moment(new Date(element.dateBeginContract)).format('DD/MM/yyyy') + ' - ' + moment(new Date(element.dateEndContract)).format('DD/MM/yyyy')
  //           }
  //           this.period.push(obj);
  //         });
  //         this.contracts = this.period;
  //       }
  //       else {
  //         this.toastrService.warning("no hay contratos pasados para mostrar");
  //       }
  //     })
  //   }

  // }

  onChangePeriod(period) {
    const [startDateStr, endDateStr] = period.name.split('-');
    const selectedDate = this.sortedActiveContracts.find(item => item.id === period.id);
    localStorage.setItem("topContracts", JSON.stringify(selectedDate));
  
    this.min = startDateStr;
    this.max = endDateStr;
    this.contractDate = this.min;
    this.date = new Date(startDateStr);
  
    this.shippingSmeForm.patchValue({
      Inicio: startDateStr,
      Fin: endDateStr
    });
  }


}
