import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Activity } from '../models/activity.model';
import { ActivityService } from './activity.service';
import { ActuacionItem } from '../models/actuacion-item';
import { ActuacionListResponse } from '../models/actuacion-list-response';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityNotificationService {

  filterObject: { tipo_consulta:string,page_size: number, page_number: number, id_tecnico: string, id_empresa: string, id_centro: string, id_actuaciontipo:string,id_actuacionestado:string,fecha_desde:string};
  

  constructor(private apiService: ApiService,private activityService:ActivityService,private errorHandler: ErrorHandlerService) {

    if(localStorage.getItem("loggeduser")!=null)
    {
      var currentUserId=JSON.parse(localStorage.getItem("loggeduser")).id;
      
      this.filterObject = {
        tipo_consulta:'TODAS',
        page_size: 100,
        page_number: 1,
        id_tecnico: currentUserId,
        id_empresa:null ,
        id_centro: null,
        id_actuaciontipo:null, 
        id_actuacionestado:"11",//For notifications, only the actions with status type='Realizada pdte validar' should be loaded
        fecha_desde:null
      };
    }    
   }

  getActivityNotification(): ActuacionItem[]
  {
    let activityList:ActuacionItem[];
    var result=this.activityService.getFilteredActivities(this.filterObject).subscribe(response => {  
          
      activityList=response.data
    }, error => {
       this.errorHandler.handle(error);
    });
    return activityList;    
  }

}
