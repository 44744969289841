import { Component, OnInit } from '@angular/core';
import { GAuthService } from 'src/app/services/_authService/gauth.service';

@Component({
    selector: 'app-unauthorized',
    templateUrl: 'unauthorized.component.html'
})

export class UnauthorizedComponent implements OnInit {
    constructor(private _authService: GAuthService) { }

    ngOnInit() { }

    logout(){
      this._authService.logout();
      return false;
    }

}
