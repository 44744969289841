import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WizardComponent } from 'angular2-wizard';
import { ToastrService } from 'ngx-toastr';
import { ProgramacionAnualData } from 'src/app/models/ProgramacionAnualData';
import { SelectOptions } from 'src/app/models/select-options.model';
import { DropdownService } from 'src/app/services/dropdown.service';
import { MasterDataService } from 'src/app/services/master-data.service';

@Component({
  selector: 'app-action-config-popup',
  templateUrl: './action-config-popup.component.html',
  styleUrls: ['./action-config-popup.component.scss']
})
export class ActionConfigPopupComponent implements OnInit {

  public shippingSmeForm: FormGroup;
  @ViewChild("wizardComponent") wizardComponent: WizardComponent;
  firstTableData: ProgramacionAnualData[];
  nextTableData: ProgramacionAnualData[];
  public selectedCompanyLanguage = "es";
  tabIndex = 0;
  activityTypes: SelectOptions[];
  activityRequest = {
    page_number: 0,
    page_size: 100,
    incl: "-99"
  }
  actuacionTypeList: any;
  public ActivityTypeForm = new FormGroup({
    ActivityType: new FormControl(null, Validators.required),
  });
  @Input() public addEditActivityForm = new FormGroup({
    ActivtyTypeName: new FormControl(null),
  });

  constructor(public actionModal: NgbActiveModal,
    private datePipe: DatePipe,
    private toastrService: ToastrService,
    private masterDataService: MasterDataService,
    private dropdownService: DropdownService) { }

  ngOnInit() {
    this.getTableData();

    this.shippingSmeForm = new FormGroup({
      TemplateLanguage: new FormControl(
        this.selectedCompanyLanguage,
        Validators.required
      )
    });
    this.initDropdowns();
  }

  getTableData() {
    this.masterDataService.getProgramacionAnualDefaultActions().subscribe((res) => {
      if (res) {
        this.firstTableData = res.filter(x => x.isFirstYear == true);
        this.nextTableData = res.filter(x => x.isFirstYear == false);
      }
    });
  }

  removeFirstData(index) {
    this.firstTableData = this.firstTableData.filter((v, i) => i != index);
  }

  removeNextData(index) {
    this.nextTableData = this.nextTableData.filter((v, i) => i != index);
  }

  
  setlang(event) {
      this.selectedCompanyLanguage = event.target.value;
  }


  onScrollActivity() {
    this.initDropdowns("-99", true);
  }

  onChangeActivity() {
    this.activityRequest.page_number = 0;
  }

  onKeyUpTypeOfActivity(activityName) {
    let name = activityName.target.value;
    this.activityRequest.page_number = 0;
    if (name && name.length >= 4) {
      this.initDropdowns(name, false);
    } else {
      if (name.length == 0) {
        this.initDropdowns("-99", false);
      }
    }
  }

  onCloseActivity() {
    this.ActivityTypeForm.controls.ActivityType.setValue(null);
  }

  initDropdowns(incl: string = "-99", isScroll = false) {
    this.activityRequest.incl = incl ? incl : null;
    this.activityRequest.page_number = isScroll ? (this.activityRequest.page_number + 1) : 0;

    this.dropdownService.getActivityTypes(this.activityRequest.incl, 100, this.addEditActivityForm.value.ActivityTypeId, false, false, this.activityRequest.page_number).subscribe((response) => {
      this.actuacionTypeList = response;
      if (isScroll) {
        this.activityTypes = this.activityTypes.concat(this.dropdownService.WrappedToDropDownListWithLang(this.shippingSmeForm.get("TemplateLanguage").value,
          response,
          "name",
          "nameCatala",
          "activityTypeId"
        ));
      } else {
        this.activityTypes = this.dropdownService.WrappedToDropDownListWithLang(this.shippingSmeForm.get("TemplateLanguage").value,
          response,
          "name",
          "nameCatala",
          "activityTypeId"
        );
      }
    });
  }

  onTabClick(id: number) {
    this.tabIndex = id;
  }

  addToArray() {
    // Define a reusable function to find the max ID and update the table data
    const updateTableData = (tableData: any, isFirstYear: any) => {
      // Find the maximum ID in the current table data
      const maxId = tableData.reduce((max: any, item: any) => (item.id > max ? item.id : max), 0);
  
      // Check if the actuacionType is not empty
      if (actuacionType.length === 0) {
        console.error('No matching actuacionType found');
        return;
      }
  
      // Update the table data
      return [
        ...tableData,
        {
          id: maxId + 1,
          titleCatalan: actuacionType[0].nameCatala,
          titleSpanish: actuacionType[0].name,
          dateBegin: 0,
          duration: null,
          isFirstYear: isFirstYear
        },
      ];
    };
  
    // Filter the actuacionType based on ActivityTypeForm value
    const actuacionType = this.actuacionTypeList.filter((obj: any) => 
      obj.activityTypeId === this.ActivityTypeForm.value.ActivityType.value
    );
  
    // Update the appropriate table data based on tabIndex
    if (this.tabIndex === 1) {
      this.firstTableData = updateTableData(this.firstTableData, true);
    } else if (this.tabIndex === 2) {
      this.nextTableData = updateTableData(this.nextTableData, false);
    }
  
    // Reset the ActivityTypeForm control value
    this.ActivityTypeForm.controls.ActivityType.setValue(null);
  }


  onFirstTabDateChange(i, e) {
    if (e < 365 && e >= 0) {
      this.firstTableData = this.firstTableData.map((obj, index) =>
        index === i ? { ...obj, dateBegin: Number(e) } : obj
      );
    } else {
      this.firstTableData = this.firstTableData.map((obj, index) =>
        index === i ? { ...obj, dateBegin: null } : obj
      );
    }
  }

  onFirstTabDurationChange(i, e) {
    if (e <= 365 && e > 0) {
      this.firstTableData = this.firstTableData.map((obj, index) =>
        index === i ? { ...obj, duration: Number(e) } : obj);
    } else {
      this.firstTableData = this.firstTableData.map((obj, index) =>
        index === i ? { ...obj, duration: null } : obj);
    }
  }

  onNextTabDateChange(i, e) {
    if (e < 365 && e >= 0) {
      this.nextTableData = this.nextTableData.map((obj, index) =>
        index === i ? { ...obj, dateBegin: Number(e) } : obj
      );
    } else {
      this.nextTableData = this.nextTableData.map((obj, index) =>
        index === i ? { ...obj, dateBegin: null } : obj
      );
    }
  }

  onNextTabDurationChange(i, e) {
    if (e <= 365 && e > 0) {
      this.nextTableData = this.nextTableData.map((obj, index) =>
        index === i ? { ...obj, duration: Number(e) } : obj
      );
    } else {
      this.nextTableData = this.nextTableData.map((obj, index) =>
        index === i ? { ...obj, duration: null } : obj
      );
    }
  }

  OnActionSave() {
    let actionTableData = [];

    let validateFirstData = this.firstTableData.filter(a => a.dateBegin == null || a.duration == null);
    let validateOtherData = this.nextTableData.filter(a => a.dateBegin == null || a.duration == null);

    if(validateFirstData.length == 0 && validateOtherData.length == 0 ) {
    this.firstTableData.forEach(a => actionTableData.push(
      {
        dateBegin: a.dateBegin,
        duration: a.duration,
        isFirstYear: a.isFirstYear,
        titleCatalan: a.titleCatalan,
        titleSpanish: a.titleSpanish
      }));

    this.nextTableData.forEach(a => actionTableData.push(
      {
        dateBegin: a.dateBegin,
        duration: a.duration,
        isFirstYear: a.isFirstYear,
        titleCatalan: a.titleCatalan,
        titleSpanish: a.titleSpanish
      }));
      this.masterDataService.addProgramacionAnualDefaultActions(actionTableData).subscribe((res) => {
        if (res == "success") {
          this.toastrService.success('Guardado exitosamente', 'Éxito');
          this.actionModal.close()
        } else {
          this.toastrService.error(res);
        }
      });
    } else{
      this.toastrService.error("Detalles no válidos");
    }
    
  }

  closeAction() {
    this.actionModal.close();
  }



}
