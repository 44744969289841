import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DropdownService } from 'src/app/services/dropdown.service';
import { SelectOptions } from 'src/app/models/select-options.model';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { Activity } from 'src/app/models/activity.model';
import { MultipleActivityService } from 'src/app/services/multiple-activity.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { Constants } from 'src/app/Helpers/constant';

@Component({
  selector: 'app-add-new-activity',
  templateUrl: './add-new-activity.component.html',
  styleUrls: ['./add-new-activity.component.scss']
})
export class AddNewActivityComponent implements OnInit {

  @Input() public updatedActivity = new Activity();
  @Input() public isUpdate = false;
  @Input() public activityArrayIndex = 0;
  private subs = new SubSink();

  NewActivityFormGroup: FormGroup;

  activityTypes$: SelectOptions[];

  private typeOfActivitySubject: Subject<string> = new Subject();

  constructor(public addNewActivityModal: NgbActiveModal,
    private dropdownService: DropdownService,
    private errorHandler: ErrorHandlerService,
    private multipleActivityService: MultipleActivityService,
    public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.InitForm();

    this.subs.add(
      this.dropdownService.getActivityTypes('-99', 100, '', true).subscribe(response => {
        this.activityTypes$ = this.dropdownService.WrappedToDropDownList(response, 'name', 'activityTypeId', false, true);
      }, error => {
        this.errorHandler.handle(error);
      }
      ));

      this.typeOfActivitySubject.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      distinctUntilChanged()
      ).subscribe(typeOfActivity =>  this.loadTopMatchingActivityTypes(typeOfActivity));

  }

  private InitForm() {
    this.NewActivityFormGroup = this.formBuilder.group({
      ActivityType: [{ value: this.updatedActivity.activityTypeId, label: this.updatedActivity.activityTypeName }, Validators.required],
      Description: [this.updatedActivity.description]
    });

    if (this.updatedActivity.activityTypeId === undefined) {
      this.NewActivityFormGroup.patchValue({
        ActivityType: null
      });
    }
  }

  private AddActivity() {
    const masterValues = JSON.parse(localStorage.getItem('newactivitymastervalues'));
    const activity = this.multipleActivityService.getActivityWithCommonValues();
    activity.activityTypeId = this.NewActivityFormGroup.value.ActivityType.value;
    activity.activityTypeName = this.NewActivityFormGroup.value.ActivityType.label;
    activity.description = this.NewActivityFormGroup.value.Description;
    activity.entryDate = new Date();
    activity.companyName = masterValues.companyName;
    activity.workCentreName = masterValues.workCentreName;
    activity.activityTime = 0;
    this.multipleActivityService.selectedActivity.push(activity);
    this.addNewActivityModal.close();
  }

  private UpdateActivity() {
    this.updatedActivity.activityTypeId = this.NewActivityFormGroup.value.ActivityType.value;
    this.updatedActivity.activityTypeName = this.NewActivityFormGroup.value.ActivityType.label;
    this.updatedActivity.description = this.NewActivityFormGroup.value.Description;
    this.multipleActivityService.selectedActivity.splice(this.activityArrayIndex, 1, this.updatedActivity);
    this.addNewActivityModal.close();
    this.isUpdate = false;
  }

  OnSave() {
    if (this.isUpdate) {
      this.UpdateActivity();
    } else {
      this.AddActivity();
    }
  }

  deleteActivity() {
    this.multipleActivityService.selectedActivity.splice(this.activityArrayIndex, 1);
    this.addNewActivityModal.close();
    this.isUpdate = false;
  }

  //#region "Limited value loading for main dropdowns "
  // acivity types
  loadTopMatchingActivityTypes(activityTypeNameTarget: any) {
    // Trigger the loading only if string length is>=4
    if (activityTypeNameTarget.length >= 4) { 
      this.dropdownService.getActivityTypes(activityTypeNameTarget, 100, '', true).subscribe(response => {
        this.activityTypes$ = this.dropdownService.WrappedToDropDownList(response, 'name', 'activityTypeId');
      }, error => {
        this.errorHandler.handle(error);
      });
    } else {
      this.activityTypes$ = null;

    }
  }

  onKeyUpTypeOfActivity(typeOfActivityTarget:any){
    if(typeOfActivityTarget.target.value != null){
      this.typeOfActivitySubject.next(typeOfActivityTarget.target.value);
    }
  }
  //#endregion

}
