import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MultipleActivitySaveModel } from '../models/multiple.activity.save.model';
import { MasterDetailsApiService } from './masterdetailsapi.service';
import { PlanDePrevWizardModel } from '../models/PlanDePrevWizardModel';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {

  constructor(private apiService: MasterDetailsApiService) {
    this.apiService.baseUrl = environment.baseUrl;
  }

  public getVisitRefrenceNumber(activityDate: string, workCenterId: string): Observable<any> {
    const activityData = {
      activityDate,
      workCenterId
    };
    return this.apiService.post('Activity/GetParteDeVisitaReference', activityData);
  }

  public getVisitState(referenceNo: string): Observable<any> {
    return this.apiService.get(`Activity/GetVisitState?referenceNo=${referenceNo}`);
  }

  public getActivityPart(referenceNo: string): Observable<any> {
    return this.apiService.get(`Activity/ActivityPart/ForRefNo/${referenceNo}`);
  }

  public async saveMultipleActivities(saveModel: MultipleActivitySaveModel): Promise<any> {
    return this.apiService.post('Activity/AddMultiple', saveModel).toPromise();
  }

  public async sendParteDeVisitaEmail(saveModel: MultipleActivitySaveModel): Promise<any> {
    return this.apiService.post('Activity/SendParteDeVisitaEmail', saveModel).toPromise();
  }

  public downloadParteDeVisitaAttachment(saveModel: MultipleActivitySaveModel): Observable<any> {
    return this.apiService.post('Activity/GetParteDeVisitaAttachment', saveModel);
  }

  public getShippingActivityAttachment(activity: any): Observable<any> {
    return this.apiService.post('Activity/GetShippingSMEAttachments', activity);
  }

  public sendShiipingSmeEmail(activity: any): Observable<any> {
    return this.apiService.post('Activity/SendShiipingSME', activity);
  }
  public sendMeShiipingSmeEmail(activity: any): Observable<any> {
    return this.apiService.post('Activity/SendMemoriaVSEmail', activity);
  }

  public getProgramacionAnualDefaultActions(): Observable<any[]> {
    return this.apiService.get('Activity/ProgramacionAnualDefault');
  }

  public addProgramacionAnualDefaultActions(data: any): Observable<any> {
    return this.apiService.post('Activity/SaveProgAction', data);
  }

  public saveProgramacionAnualRegisters(data: any): Observable<any[]> {
    return this.apiService.post('Activity/ProgramacionAnualRegisters', data);
  }

  public saveProgramacionAnualActions(data: any): Observable<any[]> {
    return this.apiService.post('Activity/ProgramacionAnualActions', data);
  }

  public getRolePermission(permissionReq: any): Observable<any[]> {
    return this.apiService.post('Workforce/GetRolePermission', permissionReq);
  }

  public getPlanDePrevencionDetails(companyId: string): Observable<PlanDePrevWizardModel> {
    return this.apiService.get(`Activity/getCompanyData/${companyId}`);
  }

  public exportPlanDePrevePDF(data: any): Observable<any> {
    return this.apiService.post(`Activity/getPlanPDFDoc`, data);
  }

  public exportPlanDePreveWord(data: any): Observable<any> {
    return this.apiService.post(`Activity/getPlanWordDoc`, data);
  }

  public sendPlanDePrevencionEmail(saveModel: any): Observable<any> {
    return this.apiService.post('Activity/emailPlanDePrev', saveModel);
  }

}
