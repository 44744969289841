import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ContactInfo } from '../models/contact-info.model';

@Injectable({
  providedIn: 'root'
})
export class ContactInfoService {

  constructor(private apiService: ApiService) { }

  getAllContactInfo(workCentreId: string) {
    return this.apiService.get('Common/WorkCentre/GetContacts?id=' + workCentreId);

    //  for (let index = 1; index < 6; index++) {
    //    const element = new ContactInfo();
    //    element.Id = index;
    //    element.Email = 'email' + index + '@gmail.com';
    //    element.ContactName = 'Name ' + index;
    //    contactInfo.push(element);
    //  }

    //  return contactInfo;
  }
}
