import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(
    private datePipe: DatePipe
  ) { }

  // CHECK THE API DATE FORMAT AND CHANGE ACCORDINGLY
  dateToString(dateToFormat: Date): string {
    return this.datePipe.transform(dateToFormat, 'yyyyMMdd');
  }

  dateToSpanish(dateToFormat: string): string {
    return this.datePipe.transform(dateToFormat, 'dd/MM/yyyy');
  }
}
