import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {

  public currentActuacionDataObject:any
  constructor() { }
}
