import { Compiler, Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { DropdownService } from '../dropdown.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LogActivityService {

  constructor(private dbService: NgxIndexedDBService,
    private dropdownservice: DropdownService,
    private compiler: Compiler) {
  }

  saveDataToIndexedDB(collectionName: string, collectionObject: any): Observable<boolean> {
    this.dbService.add(collectionName, collectionObject).then(
      () => {
        console.log("Added new user log activity record.");
      },
      error => {
        console.log(error);
      }
    );
    return of(true);
  }

  getIndexedDBData(collectionName: string) {
    return this.dbService.getAll(collectionName);
  }

  getDateInCustomFormat(dateParam: Date): string {
    let dateValStr: string = "";
    let actualMonth: number = dateParam.getMonth() + 1; //Javascript month starts with 0
    dateValStr = ('0' + (dateParam.getDate())).slice(-2) + "/" + ('0' + actualMonth).slice(-2) + "/" + dateParam.getFullYear() + " " + ('0' + (dateParam.getHours())).slice(-2) + ":" + ('0' + (dateParam.getMinutes())).slice(-2) + ":" + ('0' + (dateParam.getSeconds())).slice(-2) + " " + ((dateParam.getHours() >= 12) ? "PM" : "AM") + " " + "[" + Intl.DateTimeFormat().resolvedOptions().timeZone + "]";
    return dateValStr;
  }

  deleteAllCollectionData(collectionName: string): any {
    return this.dbService.clear(collectionName);
  }

  saveLogUserActivityToServer(logActivityList): any {
    return this.dropdownservice.saveLogUserActivity(logActivityList);
  }

  clearAndSaveSessionValues() {
    this.getIndexedDBData('useractivity').then(
      collectionlist => {
        this.saveLogUserActivityToServer(collectionlist).subscribe(_response => {
          //Clear log activity in in-built database
          this.deleteAllCollectionData('useractivity').then(
            () => {
              localStorage.removeItem("loggeduser");
              localStorage.clear();
              sessionStorage.clear();
              this.compiler.clearCache();
            },
            error => {
              console.log(error);
              localStorage.removeItem("loggeduser");
              localStorage.clear();
              sessionStorage.clear();
              this.compiler.clearCache();
            }
          );

        }, error => {
          console.log(error);
          localStorage.removeItem("loggeduser");
          localStorage.clear();
          sessionStorage.clear();
          this.compiler.clearCache();
        });
      },
      error => {
        console.log(error);
        localStorage.removeItem("loggeduser");
        localStorage.clear();
        sessionStorage.clear();
        this.compiler.clearCache();
      }
    );
  }


}
