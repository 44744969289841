import { Pipe, PipeTransform } from '@angular/core';
import { MemoriaAnualQuiz } from '../models/memoria-anual-quiz.model';

@Pipe({
    name: 'quizfilter',
    pure: false
})
export class QuizFilterPipe implements PipeTransform {
    transform(items: MemoriaAnualQuiz[], section: number): any {
        if (!items || !section) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        return items.filter(item => item.Section == section);
    }
}

@Pipe({
    name: 'resultPipe',
    pure: false
})
export class QuizResultPipe implements PipeTransform {
    transform(result: number): any {
        if(result <= 30)
            return 'INSUFICIENTE';
        else if (result > 30 && result <= 70)
            return 'MEJORABLE';
        else if (result > 70 && result < 100)
            return 'ADECUADA';
        else if (result == 100)
            return 'ÓPTIMA ';
    }
}