export class PlanAction {
    title: string;
    action: string;
    estimatedDate: string;
    description: string;
    cost: string;
    responsible:string;
    canMitigateRisk: boolean;
    canEliminateRisk: boolean;
    actionChekedInVisit: boolean;
    isMarked: boolean;
}
