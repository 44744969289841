import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Column, DataStateChangeEventArgs, EditEventArgs, EditSettingsModel, GridComponent, IEditCell, IFilter, TextWrapSettingsModel, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { EmitType } from '@syncfusion/ej2-base';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { MultiSelect, CheckBoxSelection, DropDownList } from '@syncfusion/ej2-angular-dropdowns';
import { ToastrService } from 'ngx-toastr';
import { UserConfigService } from 'src/app/services/common/user-config.service';
import { L10n, setCulture } from '@syncfusion/ej2-base';

MultiSelect.Inject(CheckBoxSelection);
setCulture('es-ES');


L10n.load({
    'es-ES': {
        'grid': {
            'EmptyRecord': 'No hay registros que mostrar',            
            'Item': 'Artículo',
            'Items': 'Elementos',
            'Edit': 'Editar',
            'Add' : 'Agregar',
            'Cancel': 'Cerrar',
            'Update': 'Actualizar',
            'EditOperationAlert' : 'No hay registros seleccionados',
            'SaveButton' : 'Guardar' ,
            'OKButton' : 'De acuerdo' ,
            'CancelButton' : 'Cerrar',
            'ClearButton' : 'Limpiar',
            'FilterButton' : 'Filtrar',
            'StartsWith' : 'Comienza con',
            'EndsWith' :	'Termina con' ,
            'Contains' :	'Contiene',
            'Equal' :	'Igual',
            'NotEqual' :	'No es igual',
            'LessThan' :	'Menos que',
            'LessThanOrEqual'	: 'Menor o igual',
            'GreaterThan'	: 'Mas grande que',
            'GreaterThanOrEqual':	'Mayor que o igual',
            'Empty' : 'Vacío',
            'Not Empty' : 'No Vacío',
            'InvalidFilterMessage': 'Mensaje de filtro no válido',
            'CustomFilterPlaceHolder' : 'Introduzca el valor',
            'NoResult': 'No se encontraron coincidencias',
            'SelectAll' : 'Seleccionar todo',
            'Blanks' : 'espacios en blanco',
            'TextFilter' : 'Filtro de texto',
            'CustomFilter' : 'Filtro personalizado',
            'Search' : 'Buscar',
            'True': 'Verdadero',
            'False' : 'Falso',
            'Matchs' :	'No se encontraron coincidencias',
            'FilterTrue' :	'Verdadero',
            'FilterFalse' :	'Falso',
        },
        'pager':{
            'currentPageInfo': '{0} Anterior {1} Siguiente ',
            'totalItemsInfo': ' (Total de {0} documentos encontrados.)'           
        }
    }
});

@Component({
  selector: 'app-user-config-popup',
  templateUrl: './user-config-popup.component.html',
  styleUrls: ['./user-config-popup.component.scss']
})
export class UserConfigPopupComponent implements OnInit {
  @ViewChild('ejDialog') ejDialog: DialogComponent;
  // Create element reference for dialog target element.
  @ViewChild('container', { read: ElementRef, static: true }) container: ElementRef;
  @ViewChild('userGrid') userGrid?: GridComponent;
  // The Dialog shows within the target element.
  public targetElement: HTMLElement;
  public visible: Boolean = false;
  public height: string = '500px';
  public width: string = '900px';
  public filterSettings: object;
  public filter?: IFilter;
  public editSettings?: EditSettingsModel;
  public toolbar?: ToolbarItems[];
  public stateParams?: IEditCell;
  public stateElem?: HTMLElement;
  public stateObj?: DropDownList;
  public state: DataStateChangeEventArgs;
  selectedRoleId: number = 0;
  roleRules?: object;

  public usersList: any;
  filterUserList: any;
  public roleList: any;
  public wrapSettings: TextWrapSettingsModel = { wrapMode: 'Header' };

  constructor(
    public modal: NgbActiveModal,
    public service: UserConfigService,
    private toastrService: ToastrService,
    private errorHandler: ErrorHandlerService) {
  }

  ngOnInit() {
    this.setGridSettings();
    this.getAllRoles();
    this.getAllUsers();
  }

  setGridSettings() {
    this.filterSettings = { type: 'Menu' };
    this.filter = { type: 'CheckBox' };
    this.editSettings = { allowEditing: true, mode: 'Dialog' };
    this.toolbar = ['Edit'];
    const state: any = { skip: 0, take: 12 };
    this.roleRules = { required: [this.comboValidationFn, 'El campo es obligatorio'] };

    this.stateParams = {
      create: () => {
        this.stateElem = document.createElement('input');
        return this.stateElem;
      },
      read: () => {
        return (this.stateObj as any).text;
      },
      destroy: () => {
        (this.stateObj as any).destroy();
      },
      write: () => {
        this.stateObj = new DropDownList({
          dataSource: this.roleList,
          fields: { value: 'roleId', text: 'roleCode' },
          enabled: true,
          placeholder: 'seleccionar',
          floatLabelType: 'Never',
          value: this.selectedRoleId
        });
        this.stateObj.appendTo(this.stateElem);
      }
    }
  }

  public comboValidationFn: (args: { [key: string]: string }) => boolean = (args: { [key: string]: string }) => {
    return args["value"] != '0';
  }

  // Hide the Dialog when click the footer button.
  public hideDialog: EmitType<object> = () => {
    this.ejDialog.hide();
  }

   // Enables the footer buttons
   public buttons: Object = [
    {
      'click': this.hideDialog.bind(this),
      buttonModel: {
        content: 'Cerrar'
      }
    }
  ];

  onClickClose(_event) {
    this.modal.close();
  }

  getAllUsers() {
    this.service.getAllUsers().subscribe(result => {
      this.usersList = result;
      this.filterUserList = result;
    }, error => {
      this.errorHandler.handle(error);
    });
  }

  getAllRoles() {
    this.service.getAllRoles().subscribe(result => {
      this.roleList = result;
    }, error => {
      this.errorHandler.handle(error);
    });
  }

  actionBegin(args: EditEventArgs) {
    if ((args as any).requestType === 'beginEdit' ) {
      this.selectedRoleId = args.rowData["roleId"];
      for (const cols of (this.userGrid as any).columns) {
        if ((cols as Column).field === 'nombre' || (cols as Column).field === 'nombreApellidos' || (cols as Column).field === 'apellidos'
          || (cols as Column).field === 'firma' || (cols as Column).field === 'firmaPath') {
          (cols as Column).visible = true;
        } else if ((cols as Column).field === 'userId') {
          (cols as Column).visible = false;
        }
      }
    }
    if ((args as any).requestType === 'refresh' || (args as any).requestType === 'paging') {
        this.userGrid.hideColumns('Nombre');
        this.userGrid.hideColumns('Nombre Apellidos');
        this.userGrid.hideColumns('Firma');
        this.userGrid.hideColumns('Apellidos');
        this.userGrid.hideColumns('FirmaPath');
        this.userGrid.hideColumns('Deleted');
        this.userGrid.hideColumns('UserId');
        this.userGrid.hideColumns('RoleId');
    }
  }

  onUserStatus(event) {
    const status = event.checked;
    this.usersList = this.filterUserList.filter(x => x.deleted == status);
  }

  actionComplete(args: any) {
    if ((args as any).requestType === 'beginEdit' || (args as any).requestType === 'add') {
      const dialog = (args as any).dialog;
      dialog.showCloseIcon = false;
      dialog.saveModel
      dialog.height = 400;
      // change the header of the dialog
      dialog.header = (args as any).requestType === 'beginEdit' ? 'Editar registro de ' + (args as any).rowData['username'] : 'Nuevo usuario';
    } else if ((args as any).requestType === 'save') {
      const editData = args.data;
      const role = this.roleList.filter(x => x.roleCode == editData["role"]);
      const userRoles = {
        RoleId: role[0].roleId,
        UserId: editData["userId"]
      };
      this.service.saveuserRoles(userRoles).subscribe(_result => {
        if (_result && _result.result == "success") {
          this.toastrService.success('Guardado exitosamente', 'Éxito');
          this.getAllUsers();
        }
        this.userGrid.endEdit();        
      }, (e) => {
        console.error(e);
        this.userGrid.closeEdit();
      });
    }
  }

}
