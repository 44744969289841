import { Component, OnInit } from '@angular/core';
import { Activity } from 'src/app/models/activity.model';
import { ActivityNotificationService } from 'src/app/services/activity-notification.service';
import { ActuacionItem } from 'src/app/models/actuacion-item';
import { ActivityService } from 'src/app/services/activity.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  activityCount:number;
  activities: ActuacionItem[];
  filterObject: { tipo_consulta:string,page_size: number, page_number: number, id_tecnico: string, id_empresa: string, id_centro: string, id_actuaciontipo:string,id_actuacionestado:string,fecha_desde:string};

  constructor(private notificationService : ActivityNotificationService,private activityService:ActivityService) { 

    if(localStorage.getItem("loggeduser")!=null)
    {
      var currentUserId=JSON.parse(localStorage.getItem("loggeduser")).id;   
    
      this.filterObject = {
        tipo_consulta:'TODAS',
        page_size: 100,
        page_number: 1,
        id_tecnico: currentUserId,
        id_empresa:null ,
        id_centro: null,
        id_actuaciontipo:null, 
        id_actuacionestado:"11",//For notifications, only the actions with status type='Realizada pdte validar' should be loaded
        fecha_desde:null
      };

    }

  }

  ngOnInit() {
    if(localStorage.getItem("loggeduser")!=null){
      this.getNotification();
      this.registerEvents();
    }  
  }

getNotification()
{  
  this.activityService.getFilteredActivities(this.filterObject).subscribe(response => {     
    let result = response.data;
    result.forEach(a => {
      a.fecha = a.fecha.substr(4, 2) + "/" + a.fecha.substr(6, 2) + "/" + a.fecha.substr(0, 4);//Get the date in the format MM/dd/yyyy
      a.hora_inicio = a.hora_inicio.substr(0, 2) + ":" + a.hora_inicio.substr(2, 2) + ":" + a.hora_inicio.substr(4, 2);//Get the time in the format HH:mm:ss 
    })
    this.activities = result;
    this.activityCount = this.activities.length;
    });
  }

  private registerEvents() {
    this.refreshEvents();
  }

  private refreshEvents() {
    this.activityService.notificationRefeshSubject
      .subscribe(res => {
        if (res !== null) {
          this.getNotification();
        }
      });
  }

}
