import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivityService } from 'src/app/services/activity.service';
import { MultipleActivityService } from 'src/app/services/multiple-activity.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { ActuacionItem } from 'src/app/models/actuacion-item';
import { NgxSpinnerService } from 'ngx-spinner';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SelectOptions } from '../../../../../models/select-options.model';
import { PaginationRowsPerPage } from '../../../../../models/pagination-rows-per-page-model';
import { DropdownService } from '../../../../../services/dropdown.service';
import { ActivityStatusService } from 'src/app/services/common/activity-status.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-add-existing-activity',
  templateUrl: './add-existing-activity.component.html',
  styleUrls: ['./add-existing-activity.component.scss']
})
export class AddExistingActivityComponent implements OnInit {

  activityList: Array<ActuacionItem>;
  filterObject: { tipo_consulta: string, page_size: number, page_number: number, id_tecnico: string, id_empresa: string, id_centro: string, id_actuaciontipo: string, id_actuacionestado: string, fecha_desde: string };

  //For pagiantion
  deviceInfo: any;
  isMobileDevice: boolean;
  isDesktopDevice: boolean;
  isTabletDevice: boolean;
  public listViewPaginationRanges$: SelectOptions[];
  public listViewPaginationRowsPerPage$: SelectOptions[];
  selectedPaginationValue: string = "0";
  selectedRowsPerCount: string = "2";
  pageNumberList: any[] = [];
  totalRecordCount: number = 0;
  pageCount: number = 0;
  private pageSize: number = 500;

  //For list view pagination  
  listViewPaginationConfig = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  };

  constructor(
    public addExistingActivityModal: NgbActiveModal,
    public activityService: ActivityService,
    public multiActivityService: MultipleActivityService,
    private errorHandler: ErrorHandlerService,
    private spinner: NgxSpinnerService,
    private deviceDetectorService: DeviceDetectorService,
    private dropdownService: DropdownService,
    private activityStatusService: ActivityStatusService,
    private datePipe: DatePipe,
  ) {
    this.getInitialFilterObject();
    this.getAppUsageDeviceDetails();
    this.setActionCountForListViewPage();
    this.buildRowsPerPageList();
  }

  ngOnInit() {
    this.spinner.show();
    this.refreshActivityList();
  }

  private getInitialFilterObject() {
    var currentUserId = JSON.parse(localStorage.getItem("loggeduser")).id;
    this.filterObject = {
      tipo_consulta: 'ASIGNADASA',
      page_size: this.pageSize,
      page_number: 1,
      id_tecnico: currentUserId,
      id_empresa: `${this.multiActivityService.saveModel.companyId}`,
      id_centro: `${this.multiActivityService.saveModel.workCentreId}`,
      id_actuaciontipo: null,
      id_actuacionestado: null,
      fecha_desde: null
    };

  }

  refreshActivityList() {
    this.activityService.getFilteredActivities(this.filterObject, false)
      .subscribe(response => {        
        this.activityList = new Array<ActuacionItem>();

        if (response.links.rowcount <= this.pageSize) {
          this.activityService.pageCount = 1;
        }
        else {
          this.activityService.pageCount = Math.floor(response.links.rowcount / response.data.length) + 1;
        }

        this.activityService.pageNumberList = Array.from(Array(this.activityService.pageCount).keys()).map(i => i + 1);
        this.listViewPaginationRanges$ = this.activityService.getListViewPaginationRanges(this.activityService.pageNumberList, 'existingactivities');
        this.selectedPaginationValue = "1";
        
        var responseData = response.data.filter(function (el) {
          return el.evento_estado_alias.includes('Hito') ||
            el.evento_estado_alias.includes('Pendiente')
        });

        responseData.forEach(element => {
          element.fecha = element.fecha.substring(6, 8) + "/" + element.fecha.substring(4, 6) + "/" + element.fecha.substring(0, 4);//Get the date in the format dd/MM/yyyy
          const [day, month, year] = element.fecha.split('/');
          element.fechaDate = new Date(+year, +month - 1, +day);
          element.hora_inicio = element.hora_inicio.substring(0, 2) + ":" + element.hora_inicio.substring(2, 2) + ":" + element.hora_inicio.substring(4, 2);//Get the time in the format HH:mm:ss 

          if (this.multiActivityService.selectedActivity !== undefined
            && !this.multiActivityService.selectedActivity.some((activity) => activity.id === element.id)
            && element.id != localStorage.getItem("parentactivityid")
            && !element.tipo_evento_especialidad_castellano.toLowerCase().trim().includes('visita')) {
            this.activityList.push(element);
          }
        });
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
        this.errorHandler.handle(err);
      });
  }

   

  public OnActivityClick(activity: ActuacionItem) {
    let activityObj = this.activityService.mapExistingActivityToActivityModel(activity);
    this.multiActivityService.selectedActivity.push(activityObj);
    this.addExistingActivityModal.close();
  }

  //#region  "pagination section"
  pageChanged(pageOfItems: any) {
    this.listViewPaginationConfig.currentPage = pageOfItems;
    this.filterObject.page_number = this.listViewPaginationConfig.currentPage;
  }

  getNextActionList(selectedOption: any) {
    this.filterObject.page_number = parseInt(selectedOption.value);
    this.filterObject.page_size = 100;
    this.refreshActivityList();
  }

  setRowsPerPage(selectedOption: any) {
    if (selectedOption.value != "") {
      this.selectedRowsPerCount = selectedOption.label.toString();
      this.listViewPaginationConfig.itemsPerPage = parseInt(selectedOption.value);
    }
  }

  getAppUsageDeviceDetails() {
    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    this.isMobileDevice = this.deviceDetectorService.isMobile();
    this.isTabletDevice = this.deviceDetectorService.isTablet();
    this.isDesktopDevice = this.deviceDetectorService.isDesktop();
  }

  setActionCountForListViewPage() {
    if (this.isMobileDevice) {
      this.listViewPaginationConfig.itemsPerPage = 5;
      this.selectedRowsPerCount = "5";
    }
    else if (this.isTabletDevice || this.isDesktopDevice) {
      this.listViewPaginationConfig.itemsPerPage = 10;
      this.selectedRowsPerCount = "10";
    }
  }



  buildRowsPerPageList() {
    let rowsPerPageList: PaginationRowsPerPage[] = [];
    let rowCountValList: string[] = ["", "5", "10", "15", "20", "25", "30"];

    for (let i = 0; i < rowCountValList.length; i++) {
      var rowPerPageObj = new PaginationRowsPerPage();
      rowPerPageObj.Id = rowCountValList[i];
      rowPerPageObj.RowsPerPage = rowCountValList[i];
      rowsPerPageList.push(rowPerPageObj);
    }
    this.listViewPaginationRowsPerPage$ = this.dropdownService.WrapPaginationRangeToDropDownList(rowsPerPageList, 'RowsPerPage', 'Id');
  }
  //#endregion

  getActivityColor(activity: ActuacionItem) {
    let activityStatus = this.activityStatusService.listOfActivityStatus.find(x=>x.id.toLowerCase() == activity.evento_estado.trim().toLowerCase());
    if(activityStatus!= null && activityStatus != undefined){
      return activityStatus.numcolor;
    }
    
  }
}
