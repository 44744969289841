import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DecimalToHex } from 'src/app/Helpers/DecimalToHex';
import { DropdownService } from '../dropdown.service';
import { ErrorHandlerService } from '../error-handler.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ActivityStatusService {
  public listOfActivityStatus  =[]
  constructor(private dropdownService: DropdownService,
    private errorHandler: ErrorHandlerService,   
    private spinner: NgxSpinnerService) { }

  
  getColorsOfActivityStatus(){    
    this.spinner.show();
    return this.dropdownService.getActivityStatusTypes().pipe(
      map(response => {
        for (let index = 0; index < response.length; index++) {
          response[index]['numcolor'] = DecimalToHex.convert(response[index]['numcolor'] );
        }
        this.listOfActivityStatus = response;
        localStorage.setItem('colorList', JSON.stringify(this.listOfActivityStatus));
         
      }, error => {
        this.errorHandler.handle(error);
      })
    );
  }

  
}
