import { EspecialidadTecnica } from "./activity.save.especialidadtecnica";
import { UsuarioUpsert } from './activity.save.usuarioupsert';

export class ActivityRoot {
  id: string;
  alias: string;
  descripcion: string;
  user_assigned: string;
  fecha: string;
  hora: string;
  especialidadTecnica: EspecialidadTecnica;
  usuario_upsert: UsuarioUpsert;
}
