
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MasterDetailsApiService } from '../masterdetailsapi.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UserConfigService{
  
    constructor(private apiService: MasterDetailsApiService) {
      this.apiService.baseUrl = environment.baseUrl;
    } 
  
    public getAllUsers(): Observable<any> {
      return this.apiService.get("Workforce/GetAllList");
    }
  
    public getAllRoles(): Observable<any> {
      return this.apiService.get("Workforce/AllRoles");
    }
  
    public saveuserRoles(data: any): Observable<any> {
      return this.apiService.post("Workforce/SaveUserRoles", data);
    }

    public saveUpdateRoles(data: any): Observable<any> {
      return this.apiService.post("Workforce/saveRoles", data);
    }

    public getRoleUserList(roleId: number): Observable<any> {
      return this.apiService.get("Workforce/GetRoleUser/"+roleId);
    }

    public DeleteRoleUser(data: any): Observable<any> {
      return this.apiService.post("Workforce/deleteRoleUser", data);
    }

    public getAllPermission(): Observable<any> {
      return this.apiService.get("Workforce/AllPermit");
    }

    public saveRolePermisssion(data: any): Observable<any> {
      return this.apiService.post("Workforce/SaveRolePermit", data);
    }

    public DeleteRolePermission(data: any): Observable<any> {
      return this.apiService.post("Workforce/deleteRolePermit", data);
    }

    public getRolePermissions(roleId: number): Observable<any> {
      return this.apiService.get("Workforce/GetRolePermit/"+roleId);
    }
  
  }
  
