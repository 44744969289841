import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import * as html2pdf from 'html2pdf.js';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class GeneratePdfService {

  constructor(
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
  ) { }

  public shareData:any;
  public pdf:any;
  public fileName:any;

  async convertHtmlToPdf(sectionIds,isEmail?:boolean) {
    let comapnyName = this.removeSpecialCharacter(this.shareData.CompanyName);
    let workCenterName = this.removeSpecialCharacter(this.shareData.WorkCentreName);
    const currentDate =  this.datePipe.transform(new Date(), 'yyyyMMdd');
    
    var opt_gen = {
      margin:       0.5,
      filename:     currentDate + '_PRG_' + comapnyName + '_' + workCenterName + '.pdf' , //'plan-de-actividades.pdf',
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { dpi: 192, scale: 4, letterRendering: true },
      jsPDF:        { unit: 'in', format: 'A4', orientation: 'portrait' }
    };
    var opt_landscape = {
      margin:       0.5,
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { dpi: 192, scale: 4, letterRendering: true },
      jsPDF:        { unit: 'in', format: 'A4', orientation: 'landscape' }
    };
    const html: any = document.getElementById(sectionIds[0]);
    const html2: any = document.getElementById(sectionIds[1]);
      if(isEmail){
        html2pdf().from(html).set(opt_gen).toPdf().get('pdf').then(function (pdf) {
          pdf.addPage('a4', 'landscape');
        }).from(html2).set(opt_landscape).toContainer().toCanvas().toPdf().outputPdf('blob').then(function (pdfBlob) {
          // Convert Blob to Base64
          const reader = new FileReader();
          reader.readAsDataURL(pdfBlob);
        
          reader.onloadend = function() {
            const result = reader.result;
            
            if (typeof result === 'string') {
              const base64String = result.split(',')[1]; // Extract the Base64 part
              localStorage.setItem("base64string", base64String);
              localStorage.setItem("filename", opt_gen.filename);
            }
          };
        });
        
      } else{
        html2pdf().from(html).set(opt_gen).toPdf().get('pdf').then(function (pdf) {
          pdf.addPage('a4', 'landscape');
        }).from(html2).set(opt_landscape).toContainer().toCanvas().toPdf().save().outputPdf().then(function(pdf1){
        })
      }

  }

  removeSpecialCharacter(str) {
    str = str.replace(/[^\w\s]/gi, "") // Remove non word characters
             .trim() // Remove trailing and leadings spaces
             .replace(/\s/g, (s) => s.toUpperCase()); // Make the first letter of each word upper case
    return str;
  }

 

  convertHtmlToPdf1(page){

    let comapnyName = this.removeSpecialCharacter(this.shareData.CompanyName);
    let workCenterName = this.removeSpecialCharacter(this.shareData.WorkCentreName);
    const currentDate =  this.datePipe.transform(new Date(), 'yyyyMMdd');
    const element: any = document.getElementById(page);
   
    var opt = {
      margin:       0.5,
      filename:     currentDate + "_MEM_" + comapnyName + "_" + workCenterName + ".pdf", //'_Generar_Memoria_Anual.pdf',
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { dpi: 192, letterRendering: true },
      jsPDF:        { unit: 'in', format: 'A4', orientation: 'portrait' }
    };
   
    //To download
     html2pdf().from(element).set(opt).save();
    
  }
}
