export class Activity {
    id: string;
    alias:string;
    activityTypeId: string;
    activityTypeName: string;
    companyId: string;
    companyName: string;  
    workCentreId: string;
    workCentreName: string;
    assignedUserId: string;
    assignedUserName: string;
    activityDate: any;
    activityTime: any;
    activityFinishTime:any;
    duration: string;
    description: string;
    statusTypeId: string;
    statusTypeName: string;
    userId: string;
    username: string;
    isDeleted: boolean=false;
    entryDate: Date;
}
