import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthContext } from 'src/app/models/auth/auth-context';
import { UserManager, User } from 'oidc-client';
import { Subject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { UserContext } from 'src/app/models/auth/user-context';
import { UserPermission } from 'src/app/models/auth/user-permission';
import { SimpleClaim } from 'src/app/models/auth/simple-claims';
import { LogActivityService } from '../_logActivity/log-activity.service';

@Injectable({ providedIn: 'root' })
export class GAuthService {

    private _userContext: UserContext;
    public _userManager: UserManager;    
    private _loginChangedSubject = new Subject<boolean>();
    private _userContextChanged = new Subject<UserContext>();
    public  _accessLevelSubject = new Subject();


  constructor(private httpClient: HttpClient, private logActivityService:LogActivityService) {
       
        let stsSettings = {
            authority: environment.gesemeIdentityServerUrl,
            client_id: environment.gesemeApiClientId,
            redirect_uri: `${environment.webRootUrl}signin-callback`,
            scope: environment.gesemeLoginScope,
            response_type: 'code',       //PKCE (for Implicit: "id_token token")
            post_logout_redirect_uri: `${environment.webRootUrl}signout-callback`,
            automaticSilentRenew: true,
            silent_redirect_uri: `${environment.webRootUrl}assets/silent-callback.html`
        };

        this._userManager = new UserManager(stsSettings);

        this._userManager.events.addAccessTokenExpired(_ => {
            this._loginChangedSubject.next(false);
        });


        this._userManager.events.addUserLoaded(user => {
            if (this._userContext != undefined) {
                if (this._userContext.user != undefined) {
                    if (this._userContext.user !== user) {                       
                        this.loadSecurityContext(user);
                        this._loginChangedSubject.next(!!user && !user.expired);
                    }
                }
            }
        });
    }
   

  login() {
    //window.location.href = 'https://it.geseme.com/Account/Login?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Dactuacweb%26redirect_uri%3Dhttp%253A%252F%252Flocalhost%253A4200%252Fsignin-callback%26response_type%3Dcode%26scope%3Dopenid%2520profile%2520prl_read%2520prl_write%2520usr_read%26state%3D01d758d1a5e24d24a192ee694c68d16f%26code_challenge%3DQkvl_pOieozO-ImBj8nYuuPudkMzGAyXaGmilo39P20%26code_challenge_method%3DS256%26response_mode%3Dquery';
    return this._userManager.signinRedirect();
    }

    public loginChanges() {
       return  this._loginChangedSubject.asObservable();        
    };

    public userChangedContext() {
        return this._userContextChanged.asObservable();
    };

    isLoggedIn(): Promise<boolean> {
      
        return this._userManager.getUser().then(user => {
            const userCurrent = !!user && !user.expired;        // user not expired
            if (this._userContext.user !== user) {
                this._loginChangedSubject.next(userCurrent);
            }
            if (userCurrent && !this._userContext) {
                this.loadSecurityContext(user);
            }
            return userCurrent;
        });
    }

    completeLogin() {
        return this._userManager.signinRedirectCallback().then(user => {
          
            this._loginChangedSubject.next(!!user && !user.expired);
            this.loadSecurityContext(user);
            return user;
        });
    }


    logout() {
        //this.router.navigate(['/signout-callback']);      
        this._userManager.signoutRedirect();          
    }

  completeLogout() {
    this._userContext = null;
    return this._userManager.signoutRedirectCallback();
  }

    getAccessToken() {
        return this._userManager.getUser().then(user => {
            if (!!user && !user.expired) {
                return user.access_token;
            }
            else {
                return null;
            }
        })

    }

    loadSecurityContext(userFromUserManager: User){

      const promise = this.httpClient
        .get<AuthContext>(`${environment.gesemeApiUrl}access/authContext`).toPromise();

      promise.then(context => {
        this._userContext = new UserContext();
        this._userContext.user = userFromUserManager;
        this._userContext.auth = new AuthContext();
        this._userContext.auth.claims = context.claims;
        this._userContext.auth.userPermissions = context.userPermissions;

        localStorage.setItem("userauthcontext", JSON.stringify(this._userContext));
        this._userContextChanged.next(this._userContext);
        this._accessLevelSubject.next('user context created');
      }).catch(error => {
        let loggedUser = JSON.parse(localStorage.getItem("loggeduser"));
        var indexedDBRecord = { userId: !loggedUser ? "0" : loggedUser.id, activityId: "", loggedInTime: "", loggedOutTime: "", committedAction: `Load Security Context=>${JSON.stringify(error)}`, requestParam: "", responseParam: "", logDate: this.logActivityService.getDateInCustomFormat(new Date()) }
        this.logActivityService.saveDataToIndexedDB('useractivity', indexedDBRecord);
        console.error(error);
      });
    }

    getUser() {
        if (!!this._userContext && !!this._userContext.user && !this._userContext.user.expired) {
            return this._userContext.user;
        }
        else {
            return null;
        }
    }

    getAuthPermissions(): UserPermission {
       
        if (this._userContext!==undefined && !!this._userContext && !!this._userContext.user && !this._userContext.user.expired && !!this._userContext.auth) {
            return this._userContext.auth.userPermissions[0];   // positions after "0" are for future use
        }
        else {
            if(localStorage.getItem("userauthcontext")!=null)
            {
                return JSON.parse(localStorage.getItem("userauthcontext")).auth.userPermissions[0];
            }
            return null;
        }
    }

    userNameInfo():String{
        let textualInformation: String = "";
        if (!!this._userContext && !!this._userContext.auth)
        {
          let theClaim: SimpleClaim;
          theClaim = this._userContext.auth.claims.find(x => x.type == "sub");
          if (!!theClaim)
            textualInformation = theClaim.value.toString();
        }
        return textualInformation;
      }      
     
      
      getUserAccessLevelInfo():string
      {        
        let authContextObj=localStorage.getItem("userauthcontext");
        let accessLevel: string = (this._userContext!=undefined && this._userContext!=null)?this._userContext.auth.userPermissions[0].accessLevelId.toString():(authContextObj!=null?JSON.parse(authContextObj).auth.userPermissions[0].accessLevelId.toString():"");       
        if (accessLevel!="")
        {         
          let i = this.getAuthPermissions().accessLevelId;
          switch(this.getAuthPermissions().accessLevelId) 
          { 
            case parseInt(environment.authNoAccessCode): { 
              accessLevel = "all apps->no access";
               break; 
            } 
            case parseInt(environment.authAdminCode): { 
              accessLevel = "all apps->admin access";
              break; 
            } 
            case parseInt(environment.authActuacWebAdminCode): { 
              accessLevel = "actuacweb->admin";
              break; 
            } 
            case parseInt(environment.authActuacWebNoAccessCode): { 
              accessLevel = "actuacweb->no access";
              break; 
            } 
            case parseInt(environment.authActuacWebFullEditCode): { 
              accessLevel = "actuacweb->full edit (technical manager)";
              break; 
            } 
            case parseInt(environment.authActuacWebOnlyMine): { 
              accessLevel = "actuacweb->only edit my actuaciones";
              break; 
            } 
            default: { 
               break; 
            } 
          } 
        }
        return accessLevel;
      }



}
