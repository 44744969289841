import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminComponent } from './layout/admin/admin.component';
import { DashboardCalendarComponent } from './components/dashboard/dashboard-calendar/dashboard-calendar.component';
import { DashboardListComponent } from './components/dashboard/dashboard-list/dashboard-list.component';
import { DynamicScriptLoaderService } from './services/script-loader/dynamic-script-loader-service.service';
import { AddEditActivityComponent } from './layout/shared/popups/add-edit-activity/add-edit-activity.component';
import { AddMultipleActivitiesComponent } from './layout/shared/popups/add-multiple-activities/add-multiple-activities.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiService } from './services/api.service';
import { DropdownService } from './services/dropdown.service';
import { CustomEncoderService } from './services/custom-encoder.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { ActivityCalendarComponent } from './components/dashboard/activity-calendar/activity-calendar.component';
import { ActivityListComponent } from './components/dashboard/activity-list/activity-list.component';
import { FiltersComponent } from './layout/shared/template/filters/filters.component';
import { HeaderComponent } from './layout/shared/template/header/header.component';
import { FooterComponent } from './layout/shared/template/footer/footer.component';
import { LoginComponent } from './components/login/login.component';
import { JwtInterceptor } from 'src/app/services/_authHelper/jwt.interceptor';
import { ErrorInterceptor } from 'src/app/services/_authHelper/error.interceptor';
import { FullCalendarModule } from '@fullcalendar/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormWizardModule, WizardComponent } from 'angular2-wizard';
import { ActivityMiniCalendarComponent } from './components/dashboard/activity-mini-calendar/activity-mini-calendar.component';
import { AddNewActivityComponent } from './layout/shared/popups/add-multiple-activities/add-new-activity/add-new-activity.component';
import { AddExistingActivityComponent } from './layout/shared/popups/add-multiple-activities/add-existing-activity/add-existing-activity.component';
import { AddContactsComponent } from './layout/shared/popups/add-multiple-activities/add-contacts/add-contacts.component';
import { NotificationComponent } from './layout/shared/template/notification/notification.component';
import { OwlDateTimeSpanish } from './models/owl-calendar-localization.model';
import { SigninRedirectCallbackComponent } from './components/gAuth/signinredirectcallback.component';
import { SignoutRedirectCallbackComponent } from './components/gAuth/signoutredirectcallback.component';
import { UnauthorizedComponent } from './components/gAuth/unauthorized.component';
import { CommonModule, DatePipe } from '@angular/common';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { NgxIndexedDBModule, DBConfig, NgxIndexedDBService } from 'ngx-indexed-db';
import { LogActivityService } from './services/_logActivity/log-activity.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UserPermissionService } from './services/user-permission-service';

import { AnnualShippingMicroSMEComponent } from './layout/shared/popups/annual-shipping-micro-sme/annual-shipping-micro-sme.component';
import { NgTippyModule } from 'angular-tippy';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { PdfAnnualShippingMicroSmeComponent } from './layout/shared/template/pdf/pdf-annual-shipping-micro-sme/pdf-annual-shipping-micro-sme.component';
import { PdfAnnualShippingHeaderComponent } from './layout/shared/template/pdf/pdf-annual-shipping-micro-sme/pdf-annual-shipping-header/pdf-annual-shipping-header.component';
import { MemoriaAnualPopupComponent } from './layout/shared/popups/memoria-anual-popup/memoria-anual-popup.component';
import { PdfMemoriaAnnualComponent } from './layout/shared/template/pdf/pdf-memoria-annual/pdf-memoria-annual.component';
import { PdfMemoriaAnnualHeaderComponent } from './layout/shared/template/pdf/pdf-memoria-annual/pdf-memoria-annual-header/pdf-memoria-annual-header.component';
import { PowerBiViewComponent } from './components/powerbi/power-bi-view/power-bi-view.component';
import { AddNewPlanactionComponent } from './layout/shared/popups/add-multiple-activities/add-new-planaction/add-new-planaction.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { UserConfigPopupComponent } from './layout/shared/popups/user-config-popup/user-config-popup.component';
import { EditService, FilterService, GridModule, PageService, ReorderService, ResizeService, SortService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { AutoCompleteModule, CheckBoxSelectionService, DropDownListAllModule, MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { ButtonModule, CheckBoxModule, FabModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { RoleConfigPopupComponent } from './layout/shared/popups/role-config-popup/role-config-popup.component';
import { PreventionPlanPopupComponent } from './layout/shared/popups/prevention-plan-popup/prevention-plan-popup.component';
import { ConfirmationPopupComponent } from './layout/shared/popups/confirmation-popup/confirmation-popup.component';
import { OWL_DATE_TIME_LOCALE, OwlDateTimeIntl, OwlDateTimeModule, OwlNativeDateTimeModule } from '@busacca/ng-pick-datetime';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { TagInputModule } from 'ngx-chips';
import { ActionConfigPopupComponent } from './layout/shared/popups/action-config-popup/action-config-popup.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { MemoriaAnualWithQuizPopupComponent } from './layout/shared/popups/memoria-anual-popup/memoria-anual-with-quiz-popup.component';
import { QuizFilterPipe, QuizResultPipe } from './Pipes/QuizFilterPipe';

const dbConfig: DBConfig = {
  name: 'UserActivityLogDB',
  version: 1,
  objectStoresMeta: [{
    store: 'useractivity',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'userId', keypath: 'userId', options: { unique: false } },
      { name: 'activityId', keypath: 'activityId', options: { unique: false } },
      { name: 'loggedInTime', keypath: 'loggedInTime', options: { unique: false } },
      { name: 'loggedOutTime', keypath: 'loggedOutTime', options: { unique: false } },
      { name: 'committedAction', keypath: 'committedAction', options: { unique: false } },
      { name: 'requestParam', keypath: 'requestParam', options: { unique: false } },
      { name: 'responseParam', keypath: 'responseParam', options: { unique: false } },
      { name: 'logDate', keypath: 'logDate', options: { unique: false } },
      { name: 'permissionLevel', keypath: 'permissionLevel', options: { unique: false } }
    ]
  }]
};


@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    LoginComponent,
    AddEditActivityComponent,
    AddMultipleActivitiesComponent,
    ActivityCalendarComponent,
    ActivityListComponent,
    FiltersComponent,
    HeaderComponent,
    FooterComponent,
    NotificationComponent,
    ActivityMiniCalendarComponent,
    AddNewActivityComponent,
    AddExistingActivityComponent,
    AddContactsComponent,
    DashboardCalendarComponent,
    DashboardListComponent,
    SigninRedirectCallbackComponent,
    SignoutRedirectCallbackComponent,
    UnauthorizedComponent,    
    PreventionPlanPopupComponent,
    AnnualShippingMicroSMEComponent,
    PdfAnnualShippingMicroSmeComponent,
    PdfAnnualShippingHeaderComponent,
    MemoriaAnualPopupComponent,
    PdfMemoriaAnnualComponent,
    PdfMemoriaAnnualHeaderComponent,
    PowerBiViewComponent,
    AddNewPlanactionComponent,
    UserConfigPopupComponent,
    RoleConfigPopupComponent, 
    ConfirmationPopupComponent,
    AddNewPlanactionComponent,
    ActionConfigPopupComponent,
    MemoriaAnualWithQuizPopupComponent,
    QuizFilterPipe,
    QuizResultPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    OwlDateTimeModule,
    NgxPaginationModule, 
    FullCalendarModule,
    OwlNativeDateTimeModule,
    FormWizardModule,
    CommonModule,
    AngularSignaturePadModule,
    NgSelectModule,
    NgxSpinnerModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    NgxPaginationModule,
    TagInputModule,
    NgTippyModule,
    ChartsModule,
    DialogModule,
    GridModule,
    CheckBoxModule,
    TextBoxModule,
    MultiSelectModule,
    AutoCompleteModule,
    DropDownListAllModule,
    SwitchModule,
    ButtonModule,
    PowerBIEmbedModule,
    FabModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    DynamicScriptLoaderService,
    ApiService,
    DropdownService,
    CustomEncoderService,
    NgxSpinnerService,
    NgxIndexedDBService,
    LogActivityService,
    DeviceDetectorService,
    UserPermissionService,
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'es' },
    { provide: OwlDateTimeIntl, useClass: OwlDateTimeSpanish },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    DatePipe,
    ThemeService,
    ResizeService,
    SortService,
    PageService, 
    ToolbarService, 
    EditService,
    FilterService,
    ReorderService,
    CheckBoxSelectionService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
