import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-pdf-annual-shipping-header',
  templateUrl: './pdf-annual-shipping-header.component.html',
  styleUrls: ['./pdf-annual-shipping-header.component.scss']
})
export class PdfAnnualShippingHeaderComponent implements OnInit {

  @Input() language: string;
  @Input() locale: any;
  @Input() page: string;
  @Input() totalPages: string;

  constructor() { }

  ngOnInit() {
  }

}
