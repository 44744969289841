import { Component, OnInit } from '@angular/core';
import { ActivityService } from 'src/app/services/activity.service';

@Component({
  selector: 'app-activity-mini-calendar',
  templateUrl: './activity-mini-calendar.component.html',
  styleUrls: ['./activity-mini-calendar.component.scss']
})
export class ActivityMiniCalendarComponent implements OnInit {

  selectedDate: Date;
  clickedDate: Date;
  constructor(private activityService: ActivityService) { }

  ngOnInit() {
  }

  setSelectedDate() {
    if (this.selectedDate !== this.clickedDate) {
      this.activityService.selectedDate.next(new Date(this.selectedDate));
    }
    this.clickedDate = this.selectedDate;
  }

}
