import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Activity } from 'src/app/models/activity.model';
import { ActivityService } from 'src/app/services/activity.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { Calendar, EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { FilterService } from 'src/app/services/filter.service';
import moment from 'moment/moment';
import { ActivityCalendarListService } from 'src/app/services/activity-calendar-list.service';
import { NgTippyService } from 'angular-tippy';

@Component({
  selector: 'app-activity-calendar',
  templateUrl: './activity-calendar.component.html',
  styleUrls: ['./activity-calendar.component.scss']
})
export class ActivityCalendarComponent implements OnInit {

  modalOptions: NgbModalOptions;
  activityCalendar: Calendar;
  eventInput: EventInput;
  startDate: string;
  fromDate: string;
  toDate: string;
  constructor(
    private activityService: ActivityService,
    private errorHandler: ErrorHandlerService,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private filterService: FilterService,
    private activityCalendarListService: ActivityCalendarListService,
    private tippyService: NgTippyService
  ) {
    this.modalOptions = {
      backdrop: 'static',
      windowClass: 'text-left modal-fade-animation',
      size: 'lg',
      centered: true
    };
  }


  ngOnInit() {
    this.activityCalendarListService.isCalendar = true;
    const self = this;
    this.activityCalendar = new Calendar(document.getElementById('activity-calendar'), {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      header: {
        left: 'dayGridMonth,timeGridWeek,timeGridDay',
        right: 'prev,title,next'
      },
      locale: esLocale,
      displayEventTime: false,
      navLinks: true,
      editable: true,
      customButtons: {
        addActivityButton: {
          text: 'Añadir',
          click: function () {
            this.openActivityAddEditPopup();
          }
        }
      },
      dateClick: function (element) {
        self.activityCalendarListService.addEditActivity(element, false);
      },
      eventClick: function (element) {
        self.spinner.show();
        self.activityCalendarListService.addEditActivity(element, true);
        self.spinner.hide();

      },
      eventDrop: function (element) {
        self.updateActivity(element);
      },
      datesRender: function (info) {
        var fromDate = moment(new Date(info.view.activeStart)).format('YYYYMMDD');
        var toDate = moment(new Date(info.view.activeEnd)).format('YYYYMMDD');
        if (self.startDate != fromDate) {
          self.fromDate = fromDate;
          self.toDate = toDate;
          self.bindCalendarActivities();
          self.startDate = fromDate;
        }
      },
      eventRender: function(element) {
        const title = element.event.title;
        const ref = new ElementRef(element.el);
        self.tippyService.init(ref, { content: title, arrow: true, });
      }

    });
    this.registerEvents();
    this.setSelectedDate();
    this.activityCalendar.render();
  }
 

  public setSelectedDate() {
    this.activityService.selectedDate.subscribe((response) => {
      this.activityCalendar.gotoDate(response);
    });
  }

  public bindCalendarActivities() {
    this.spinner.show();
    this.activityCalendar.removeAllEvents();
    this.activityCalendar.render();
    this.filterService.setPageSize(false);
    this.filterService.setFilterValuesForAPI();
    this.filterService.filterObject.page_number = 1;
    this.filterService.filterObject.fecha_desde = this.fromDate;
    this.filterService.filterObject.fecha_hasta = this.toDate;
    if (this.filterService.filterObject.tipo_consulta === 'TODAS') {
      this.filterService.filterObject.tipo_consulta = 'ASIGNADASA';
    }

      this.activityService.getFilteredActivities(this.filterService.filterObject).subscribe(response => {

      this.activityService.totalRecordCount = response.links.rowcount;

      setTimeout( () => { 
        this.activityCalendar.removeAllEvents();
        this.activityCalendar.render();
        if(this.filterService.filterObject.id_tecnico!="00000000000000-0000000-0000-W")
        {
          this.activityService.mapActivityToCalendarEvent(response.data);
          this.activityService.activityList.forEach(event => {
            this.activityCalendar.addEvent(event);
          });
        }

       }, 2000 );
      
      

      this.activityCalendar.render();
      this.filterService.setFilterValuesForView();
      setTimeout( () => { 
        this.spinner.hide();
      }, 2000 );
    }, error => {
      this.filterService.setFilterValuesForView();
      this.spinner.hide();
      this.errorHandler.handle(error);
    });
   
  }

  public async updateActivity(model) {

    this.spinner.show();
    let activity = new Activity();
    const targetDate = new Date(model.event.start);

    await this.activityService.getActivity(model.event.id).then(async response => {

      activity = this.activityService.mapActivityResponseToActivityModel(response);
      // Javascript month starts with 0
      const actualTargetMonth = targetDate.getMonth() + 1;
      // Get the date in the format dd/MM/yyyy
      activity.activityDate = ('0' + targetDate.getDate()).slice(-2) + '/'
        + ('0' + actualTargetMonth).slice(-2) + '/' + targetDate.getFullYear();
      const activityRoot = this.activityService.mapActivityModelToActivityRoot(activity);
      activityRoot.fecha = targetDate.getFullYear().toString() + ('0' + actualTargetMonth).slice(-2)
        + ('0' + targetDate.getDate()).slice(-2);

      /*NOTE:Client requirement=> Status type "11" is not available in client DB.Therefore send "01" instead*/
      activityRoot.especialidadTecnica.evento_estado = activityRoot.especialidadTecnica.evento_estado === '11' ?
        '01' : activityRoot.especialidadTecnica.evento_estado;

      this.activityService.saveActivity(activityRoot).then(saveResponse => {
        this.refreshEvents();

        // Check for error
        if (saveResponse.webError != null) {
          this.errorHandler.handle(saveResponse.webError.messageToUser, true);
        } else {
          this.toastrService.success('Actualizado con éxito', 'Éxito');
          this.activityService.notificationRefeshSubject.next('');
          this.bindCalendarActivities();
        }
      }, error => {
        this.spinner.hide();
        this.errorHandler.handle(error);
      });

    }, error => {
      this.spinner.hide();
      this.errorHandler.handle(error);
    });
  }

  private registerEvents() {
    this.refreshEvents();
  }

  private refreshEvents() {
    this.activityService.activityCalendarRefreshSubject
      .subscribe(res => {
        if (res !== null) {
          this.activityCalendar.removeAllEvents();
          this.activityCalendar.addEventSource(this.activityService.activityList);
          this.activityCalendar.render();
        }
      });
  }

}
