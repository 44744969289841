import { OwlDateTimeIntl } from "@busacca/ng-pick-datetime";


// here is the default text string
export class OwlDateTimeSpanish extends OwlDateTimeIntl {
    /** A label for the cancel button */
    cancelBtnLabel = 'Cancelar';

    /** A label for the set button */
    setBtnLabel = 'Aceptar';
}