import { GAuthService } from './_authService/gauth.service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UserPermissionService{
 
   public isActuacionInsertAllowed:boolean=false;
   public isActuacionUpdateAllowed:boolean=false;
   public isActuacionDeleteAllowed:boolean=false;
   public userAccessLevel:string="";
  
    constructor(private authService:GAuthService) {
       
        this.userAccessLevel=authService.getUserAccessLevelInfo();

        if(this.userAccessLevel.toLowerCase().includes("admin")) //Admin has any permission
        {
            this.isActuacionInsertAllowed=true;
            this.isActuacionUpdateAllowed=true;
            this.isActuacionDeleteAllowed=true;
        }
        else
        {            
            var permissionText=JSON.parse(localStorage.getItem("loggeduser")).permissionScope.toString().toLowerCase();
            this.isActuacionInsertAllowed=permissionText.includes("prl_write");
            this.isActuacionUpdateAllowed=permissionText.includes("prl_write");
            this.isActuacionDeleteAllowed=permissionText.includes("prl_write");
        }       
      
    }    
}
