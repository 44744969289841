import { Component, ChangeDetectorRef, HostListener } from '@angular/core';
import { GAuthService } from 'src/app/services/_authService/gauth.service';
import { UserContext } from './models/auth/user-context';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isLoggedIn = false;  
  currentUserContext: UserContext = null;
 

constructor( 
  private authService: GAuthService ){
  
  this.authService.loginChanges().subscribe(loggedIn => {
    this.isLoggedIn = loggedIn;
  });   

  this.authService.userChangedContext().subscribe(usr => {  
    this.currentUserContext = usr;
  });
  } 

ngOnInit() {
 
}

ngAfterViewInit(){
 
}

ngOnDestroy(){

}


login(){
  this.authService.login();
}

logout(){
  this.authService.logout();
}

isAdmin(){
  return this.currentUserContext.auth && this.currentUserContext.auth.isAdmin;
}
  
}
