import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactInfoService } from 'src/app/services/contact-info.service';
import { ContactInfo, WorkCentreGroupContactModel } from 'src/app/models/contact-info.model';
import { MultipleActivityService } from 'src/app/services/multiple-activity.service';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';

@Component({
  selector: 'app-add-contacts',
  templateUrl: './add-contacts.component.html',
  styleUrls: ['./add-contacts.component.scss']
})
export class AddContactsComponent implements OnInit {

  contactInfoResponseModel: WorkCentreGroupContactModel;
  contactInfoList$: Array<ContactInfo>;

  constructor(public addContactsModal: NgbActiveModal,
    private contactService: ContactInfoService,
    private multipleActivityService: MultipleActivityService,
    private errorHandlingService: ErrorHandlerService) { }

  ngOnInit() {
    this.contactService.getAllContactInfo(this.multipleActivityService.saveModel.workCentreId).subscribe((response) => {
      this.contactInfoResponseModel = response[0];
      this.contactInfoList$ = this.contactInfoResponseModel.contactDetails;
    });
  }

  selectContact(contactInfo: ContactInfo) {
    this.multipleActivityService.saveModel.recipientEmail.push(contactInfo.eMail);
    this.addContactsModal.close();
  }

}
