import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  private msgTimeOut = 50000; // Message display timeout in miliseconds

  constructor(private toastrService: ToastrService) { }

  handle(error, isCustomMsg: boolean = false) {
    let errorMessage = '';
    if (!isCustomMsg && error) {
      if (error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
      } else {
        // Get server-side error
        if (error.status && error.message) {
          errorMessage = `Código de error: ${error.status}\nMensaje: ${error.message}`;
        }
      }

      if (!errorMessage) {
        this.toastrService.error('La operación falló', 'Error', {
          timeOut: this.msgTimeOut
        });
      } else {
        this.toastrService.error(errorMessage, 'Error', {
          timeOut: this.msgTimeOut
        });
      }
    } else {
      if (error) {
        errorMessage = `Mensaje de error: ${error}`;
        this.toastrService.error(errorMessage, 'Error', {
          enableHtml: true,
          timeOut: this.msgTimeOut
        });
      }

    }

    if (errorMessage) {
      return throwError(errorMessage);
    }

  }
}
