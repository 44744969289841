import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './layout/admin/admin.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from 'src/app/services/_authHelper/auth.guard';
import { DashboardCalendarComponent } from './components/dashboard/dashboard-calendar/dashboard-calendar.component';
import { DashboardListComponent } from './components/dashboard/dashboard-list/dashboard-list.component';
import { SigninRedirectCallbackComponent } from 'src/app/components/gAuth/signinredirectcallback.component';
import { SignoutRedirectCallbackComponent } from 'src/app/components/gAuth/signoutredirectcallback.component';
import { UnauthorizedComponent } from 'src/app/components/gAuth/unauthorized.component';

import { PowerBiViewComponent } from './components/powerbi/power-bi-view/power-bi-view.component';



const routes: Routes = [
  { path: '',
    pathMatch: 'full',
    redirectTo: 'login'
    //redirectTo: '/home/calendar'
 },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'home',
    component: AdminComponent,
    children: [
      {
        path: 'calendar',
        component: DashboardCalendarComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'list',
        component: DashboardListComponent,
        canActivate: [AuthGuard]
      },    
    ]
  },
  {
    path:'powerbi/view',
   component:PowerBiViewComponent,
    canActivate:[AuthGuard]
  },
  { path: 'signin-callback', component: SigninRedirectCallbackComponent},
  { path: 'signout-callback', component: SignoutRedirectCallbackComponent},
  { path: 'unauthorized', component: UnauthorizedComponent},
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
