import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import moment from 'moment';
import { MemoriaAnualService } from 'src/app/services/common/memoria-anual.service';

@Component({
  selector: 'app-pdf-memoria-annual',
  templateUrl: './pdf-memoria-annual.component.html',
  styleUrls: ['./pdf-memoria-annual.component.scss']
})
export class PdfMemoriaAnnualComponent implements OnInit {
  @Input() language: string;
  @Input() reportValues: any;
  @Input() activityFormGroupWizard1: FormGroup;
  @Input() companyData: any;
  @Input() contactPersons: any;
  @Input() workCenterData: any;
  @Input() activeContracts: any;
  @Input() actuacionesData: any;
  actuacionesTable:any
  locale: any;
  hoy= moment(new Date()).format('DD/MM/yyyy')
  constructor(private memoriaAnualService:MemoriaAnualService) { }

  ngOnChanges(_changes: any) {
    this.language=this.activityFormGroupWizard1.get('TemplateLanguage').value;
    if(this.actuacionesData.data.length>0)
    {
      this.actuacionesTable= this.actuacionesData.data.sort((a: any, b: any) => {
        return <any>new Date(b.fecha) - <any>new Date(a.fecha);
      });
    }
  }

  ngOnInit() {

    this.locale = {
      'es' : {
        'page': 'Página',
        'of': 'de',
        'ref': 'Ref',
        'rev': 'Rev',
        'annual_memory': 'Memoria anual',
        'period': 'Periodo',
        'date_of_writing': 'Fecha de redacción',
        'preventive_activity': 'Memoria anual de la actividad preventiva',
        'index': 'Indice',
        'background': 'Antecedentes',
        'objeto': 'Objeto',
        'company_data': 'Datos de empresa',
        'modality_organization': 'Modalidad de organización de la actividad preventiva',
        'actions_carried_out': 'Actuaciones realizadas periodo concertado',
        'para_1':'Se redacta el presente informe en cumplimiento del contrato establecido con GESEME y en cumplimiento de la normativa legal en prevención de riesgos laborales, en particular a la ',
        'para_1_sub1':' Ley de Prevención de Riesgos 31/1995 ',
        'para_1_sub2':'de 8 de noviembre y al ',
        'para_1_sub3':'Real Decreto 39/',
        'para_1_sub4':'de 17 de enero por el que se aprueba el Reglamento de los Servicios de Prevención y RD 337/2010.',
        'para_2':'Según este RD "Se tiene que facilitar a las empresas para las cuales actúen como servicios de prevención la memoria y la programación anual a las cuales se refiere el apartado 2 d) del artículo 39 de la Ley de Prevención de Riesgos Laborales, a fin de que pueda ser conocida por el comité de Seguridad y Salud en los términos previstos en el artículo citado", en caso de que exista.',
        'para_3':'Aparte de la normativa, toda actividad preventiva tiene que tener un fondo documental que demuestre su implantación tanto por el seguimiento de la gestión de la prevención en la empresa y pueda ser mostrada a las autoridades competentes el seguimiento de la normativa vigente.',
        'para_4':'Es pues obligación de las empresas de establecer, mantener y guardar toda la documentación relativa en el Plan Preventivo que le fue entregado en su día por los técnicos de nuestra empresa.',
        'para_5':'Por lo tanto, GESEME entrega la presente Memoria Anual, en la que se refleja las diferentes actividades realizadas tanto por la propia empresa como desde este Servicio de Prevención Ajeno.',
        'para_6':'El periodo analizado y al que hace referencia estos documentos, se tratan las reformas y/o mejoras realizadas, tanto por parte del técnico del servicio de prevención, como por parte de la dirección de la empresa. Incidiendo en las acciones realizadas en el año anterior de la renovación y en el periodo de tiempo de vigencia del contrato',
        'para_7':'El objeto de la presente memoria es dar cumplimiento a los requerimientos legales mencionados en el apartado anterior y en particular:',
        'para_7_li1':'Presentar la modalidad de organización preventiva de la empresa.',
        'para_7_li2':'Presentar las actuaciones más relevantes realizadas en el desarrollo de la actividad preventiva por ',
        'para_7_li2_1':', y las llevadas a cabo por el Servicio de Prevención durante el año de concierto (periodo actual)',
        'para_7_li3':'Proponer una planificación de actividades para el periodo próximo en base a las actividades realizadas hasta la fecha.',
        'para_7_li4':'Presentar los datos relativos a los daños derivados del trabajo en la empresa.',
        'para_8':'La memoria tendrá el carácter de Documentación según lo establecido en el art. 23 de la LPRL y RD 337/2010. Esta documentación debe estar en la empresa y a disposición de la autoridad laboral y de los representantes de los trabajadores.',
        'company_data_2': 'Datos de la empresa',
        'name': 'Nombre',
        'company': 'Empresa',
        'nif':'NIF',
        'address': 'Dirección',
        'contact': 'Personas de contacto',
        'population': 'Población',
        'organization_modality':'Modalidad Organizativa:',
        'para_9':'La modalidad adoptada por la empresa durante el concierto de actividades, según lo establecido en el RSP, es la que se especifica a continuación, basada en un concierto con el Servicio de Prevención Ajeno num. SP-0007-B; GESEME 1996 SL. como entidad acreditada por la Generalitat de Catalunya para el desarrollo siguientes modalidades preventivas: ',
        'specialty': 'Especialidad',
        'responsible': 'Responsable',
        'technicianheader': 'Técnico Responsable',
        'technician': 'Técnico responsable: Fulano',
        'security': 'Seguridad',
        'hygiene': 'Higiene',
        'ergonomics': 'Ergonomía y Psicosociologia',
        'medicine':'Medicina del trabajo',
        'para_10':'La organización y el desarrollo de las actividades fueron llevadas a cabo a partir de las visitas que el técnico responsable de la empresa realizó a la misma para obtener información, hacer la evaluación de riesgos, la formación e información a los trabajadores y el resto de actividades previstas en el concierto.',
        'concerted_activities':'Actividades Concertadas con el servicio de prevención ajeno GESEME:',
        'ul_1_li1':'Asesoramiento general sobre necesidades en materia preventiva',
        'ul_1_li2':'Realización de la evaluación inicial de riesgos',
        'ul_1_li3':'Orientación para la realización de los planes y programas preventivos derivados de la evaluación',
        'ul_1_li4':'Fijación de Prioridades y controles de eficacia, así como información complementaria sobre las medidas derivadas de la evaluación de riesgos',
        'ul_1_li5':'Medidas básicas de emergencia',
        'ul_1_li6':'Confección de la memoria anual ',
        'ul_1_li7':'Elaboración de los protocolos específicos según los riesgos detectados',
        'ul_1_li8':'Información básica a los trabajadores sobre los riesgos existentes en sus puestos de trabajo',
        'para_11':'Actuaciones realizadas a partir de la entrada en vigor del Concierto con GESEME.',
        'section_5_1':'Formación - información de riesgos laborales',
        'para_12':'Durante todo el año se debe realizar la entrega de información a los trabajadores por parte de la empresa y según se referencia al artículo 18 de la Ley 31/1995 de 8 de noviembre de Prevención de Riesgos Laborales. GESEME ha entregado en caso de realizar la formación/información referente a los siguientes conceptos, de los cuales podemos encontrar copia en el apartado correspondiente dentro del Plan Preventivo.',
        'para_13':'Geseme ha realizado las siguientes sesiones formativas:',
        'para_14':'Como parte de las obligaciones de la empresa descritas al artículo 19 de la Ley 31/1995 de 8 de noviembre de Prevención de Riesgos Laborales, hay que realizar la formación de los trabajadores en materia de Prevención de Riesgos Laborales.',
        'para_15':'Los registros de las formaciones que se realicen quedarán registrados dentro del Plan Preventivo de la empresa.',
        'para_16':'Los trabajadores asistentes fueron reunidos por la empresa, y firmaron dos justificantes, uno que está a disposición de la empresa, y otro que dispone GESEME al expediente del cliente.',
        'section_5_2':'Equipos de protección individual',
        'para_17':'La empresa, en su afán de dar el máximo nivel de seguridad a sus trabajadores y dando cumplimiento al artículo 17 de la Ley 31/1995 de Prevención de Riesgos Laborales, debe hacer entrega de los diferentes equipos de protección individual necesarios para desarrollar el trabajo de manera correcta y segura. La entrega se ha hecho en diferentes fechas, dependiendo de las incorporaciones nuevas y la renovación del material.',
        'para_18':'En el control efectuado por el técnico se comprueba la entrega de EPI\'s.',
        'section_5_3':'Investigación y análisis de accidentes',
        'para_19':'La empresa debe pedir a la Mutua de accidentes laborales el informe de accidentabilidad del periodo contractual. Se debe enviar al técnico de GESEME para poder analizar.',
        'para_20':'De los accidentes comunicados por parte de la Empresa a GESEME se ha procedido a realizar la correspondiente investigación, tal y cómo queda recogido al artículo 16 de la Ley 31/1995 de 8 ',
        'para_21':'Las investigaciones realizadas están registradas en los informes correspondientes dentro del Plan Preventivo de la empresa',
        'section_5_4':'Medidas de emergencia y plan de emergencias',
        'para_22':'El artículo 20 de la Ley 31/1995, de prevención de riesgos laborales, dispone que teniendo en cuenta la medida y la actividad de cada empresa, y la posible presencia de personas ajenas a la misma, se tendrán que analizar las posibles situaciones de emergencia y adoptar las medidas necesarias en materia de primeros auxilios, lucha contra incendios y evacuación de los trabajadores.',
        'para_23':'Un plan de emergencia es la planificación y organización humana para la utilización óptima de los medios técnicos previstos, con el fin de reducir al mínimo las posibles consecuencias humanas y/o económicas que se puedan derivar de una situación de emergencia.',
        'para_24':'Los objetivos del plan de emergencias son:',
        'para_24_li1':'Organizar los medios humanos y materiales disponibles para contener y dejar bajo control las emergencias',
        'para_24_li2':'Proteger al personal y a las instalaciones',
        'para_24_li3':'Garantizar la evacuación y la intervención inmediata',
        'para_24_li4':'Facilitar la posible intervención de ayudas exteriores',
        'para_24_li5':'Dar la ayuda necesaria en caso de lisiados',
        'para_25':'Es necesario la implicación y participación de todo el personal de la empresa, para garantizar la competencia del Plan de emergencias, en cuanto a conocimientos, disposición y actuación en estos casos de los trabajadores.',
        'para_26':'GESEME con el Plan Preventivo hace entrega de un Plan Básico de medidas de emergencia en el cual se redactan las actuaciones mínimas ante un accidente o incidente.',
        'para_27':'Este plan se redacta con la intención de ser revisado por la Empresa cada año para poder comprobar y mantener su funcionalidad y con el asesoramiento de GESEME. ',
        'para_28':'Se recuerda pero que en todos los lugares de trabajo deben existir medio y equipos de lucha contra incendios. Así todo el personal que se encuentre en la zona debe ponerse de acuerdo, según formación recibida, para establecer un procedimiento y punto de reunión caso de incendio y/o evacuación y mantener claras las zonas de paso de los trabajadores para que caso de evacuación no se encuentren ningún obstáculo. ',
        'para_29':'Se informó a los trabajadores en las formaciones realizadas de los pasos que deben seguir en cualquier incidente que salga durante el desarrollo de su actividad.',
        'para_30':'Las actuaciones realizadas a este respecto se hayan referenciadas en la anterior tabla de actuaciones.',
        'section_5_5':'COORDINACIÓN DE ACTIVIDADES EMPRESARIALES',
        'para_31':'La Ley 54/03 de reforma de la Ley de 31/95 de Prevención de Riesgos Laborales, modifica el artículo 24 sobre Coordinación de Actividades. Esta modificación se ha desarrollado reglamentariamente por el Real Decreto 171/2004, para regular la concurrencia de actividades en un mismo centro de trabajo. Las empresas que trabajen simultáneamente en un centro de trabajo tendrán que cooperar en la aplicación de las actividades preventivas. A tal fin, establecerán los medios de coordinación necesarios en cuanto a la protección y prevención de riesgos laborales y la información sobre los mismos a sus respectivos trabajadores.',
        'section_5_6':'CONTROL DE EFICACIA SOBRE LA IMPLANTACIÓN DEL SISTEMA DE PREVENCIÓN',
        'para_32':'Una vez llevada a cabo la evaluación y realizada la planificación, no se puede dejar de seguir actuar en prevención, ya que debe ser un hecho continuado y en el que esté implicada toda la organización de la empresa. Los controles de eficacia son un instrumento de Gestión de la Prevención.',
        'para_33':'Los controles de eficacia son revisiones periódicas del sistema de gestión de la prevención de riesgos laborales y un análisis detallado de las condiciones de seguridad (máquinas, instalaciones, herramientas, etc.) a fin de descubrir las situaciones de riesgos que se derivan de ellas (condiciones peligrosas o prácticas inseguras) con el fin de adoptar las medidas adecuadas para su control, evitando el accidente (prevención) o reduciendo los daños materiales o personales derivados del mismo (protección).',
        'para_34':'El grado de implicación de la empresa en referencia a la Implantación de la Prevención en el año contractual se considera ',
        'section_5_7':'Evaluaciones higiénicas',
        'para_35':'El objetivo de este tipo de evaluaciones es determinar que las condiciones higiénicas en la empresa se encuentran dentro de los parámetros de la legislación vigente. Dentro de este tipo de evaluaciones se encuentran las mediciones de ruido, iluminación, temperatura y de la existencia de contaminantes ambientales.',
        'para_36':'Las evaluaciones higiénicas realizadas pueden consultarse en anterior tabla de actuaciones.',
        'section_5_8':'Evaluaciones ergonómicas y psicosociales',
        'para_37':'Las evaluaciones ergonómicas tienen por objetivo determinar la adecuación de los distintos puestos en función de los trabajadores y las tareas que en ellos se realizan. Para ello se analizan los elementos del plano de trabajo, su altura, la adaptabilidad, elementos del entorno de trabajo, etc. De la misma manera se analizan los factores psicosociales determinados por las relaciones sociales, tipo de organización, ritmos de trabajo, horarios, etc.',
        'para_38':'Las evaluaciones ergonómicas y psicosociales realizadas pueden consultarse en anterior tabla de actuaciones.',
        'para_39':'GESEME 1996 SL agradece a la empresa, las atenciones dispensadas, estando a su disposición para cualquier consulta sobre este informe o sobre otros temas relacionados con la prevención de riesgos.',
      },

      'ca' : {
        'page': 'Página',
        'of': 'de',
        'ref': 'Ref',
        'rev': 'Rev',
        'annual_memory': 'Memòria anual',
        'period': 'Període',
        'date_of_writing': 'Data de redacció',
        'preventive_activity': 'Memòria anual de l\'activitat preventiva',
        'index': 'Index',
        'background': 'Antecedents',
        'objeto': 'Objecte',
        'company_data': 'Dades d\'empresa',
        'modality_organization': 'Modalitat d\'organització de l\'activitat preventiva',
        'actions_carried_out': 'Actuacions realitzades període concertat',
        'para_1':'Es redacta el present informe en compliment del contracte establert amb GESEME i en compliment de la normativa legal en prevenció de riscos laborals, en particular a ',
        'para_1_sub1':'la Llei de Prevenció de Riscos 31/1995 ',
        'para_1_sub2':'de 8 de novembre i al ',
        'para_1_sub3':'Reial decret 39/1997 ',
        'para_1_sub4':'de 17 de gener pel qual s\'aprova el Reglament dels Serveis de Prevenció i RD 337/2010.',
        'para_2':'Segons aquest RD "S\'ha de facilitar a les empreses per les quals actuïn com a serveis de prevenció la memòria i la programació anual a les quals es refereix l\'apartat 2 d) de l\'article 39 de la Llei de Prevenció de Riscos Laborals, a fi que pugui ser coneguda pel comitè de Seguretat i Salut en els termes previstos en l\'article citat", en cas que existeixi.',
        'para_3':'A part de la normativa, tota activitat preventiva ha de tenir un fons documental que demostri la seva implantació tant pel seguiment de la gestió de la prevenció en l\'empresa i pugui ser mostrada a les autoritats competents el seguiment de la normativa vigent.',
        'para_4':'És doncs obligació de les empreses d\'establir, mantenir i guardar tota la documentació relativa en el Pla Preventiu que li va ser lliurat en el seu moment pels tècnics de la nostra empresa.',
        'para_5':'Per tant GESEME lliurament la present Memòria Anual, en la qual es reflecteix les diferents activitats realitzades tant per la pròpia empresa com des d\'aquest Servei de Prevenció Aliè.',
        'para_6':'El període analitzat i al que fa referència aquests documents, es tracten les reformes i/o millores realitzades, tant per part del tècnic del servei de prevenció, com per part de l\'adreça de l\'empresa. Incidint en les accions realitzades l\'any anterior de la renovació i en el període de temps de vigència del contracte.',
        'para_7':'L\'objecte de la present memòria és donar compliment als requeriments legals esmentats a l\'apartat anterior i en particular:',
        'para_7_li1':'Presentar la modalitat d\'organització preventiva de l\'empresa.',
        'para_7_li2':'Presentar les actuacions més rellevants realitzades en el desenvolupament de l\'activitat preventiva de ',
        'para_7_li2_1':', i les dutes a terme pel Servei de Prevenció durant l\'any de concert (període actual )',
        'para_7_li3':'.	Proposar una planificació d\'activitats per al període proper sobre la base de les activitats realitzades fins avui.',
        'para_7_li4':'.	Presentar les dades relatives als danys derivats del treball en l\'empresa.',
        'para_8':'La memòria tindrà el caràcter de Documentació segons l\'establert en l\'art. 23 de la LPRL i RD 337/2010. Aquesta documentació ha d\'estar en l\'empresa i a la disposició de l\'autoritat laboral i dels representants dels treballadors.',
        'company_data_2': 'Dades de l\'empresa',
        'name': 'Nom',
        'company': 'Empresa',
        'nif':'NIF',
        'address': 'Adreça:',
        'contact': 'Persones de contacte',
        'population': 'Població',
        'organization_modality':'Modalitat Organitzativa:',
        'para_9':'La modalitat adoptada per l\'empresa durant el concert d\'activitats, segons l\'establert en el RSP, és la que s\'especifica a continuació, basada en un concert amb el Servei de Prevenció Aliè num. SP-0007-B; GESEME 1996 SL. com a entitat acreditada per la Generalitat de Catalunya per al desenvolupament següents modalitats preventives: ',
        'specialty': 'Especialidad',
        'responsible': 'Responsable',
        'technicianheader': 'Tècnic Responsable',
        'technician': 'Tècnic responsable: Mengano',
        'security': 'Seguretat',
        'hygiene': 'Higiene',
        'ergonomics': 'Ergonomía y Psicosociologia',
        'medicine':'Medicina de l\'treball',
        'para_10':'L\'organització i el desenvolupament de les activitats van ser dutes a terme a partir de les visites que el tècnic responsable de l\'empresa va realitzar a la mateixa per obtenir informació, fer l\'avaluació de riscos, la formació i informació als treballadors i la resta d\'activitats previstes en el concert.',
        'concerted_activities':'Activitats Concertades amb el servei de prevenció aliè GESEME:',
        'ul_1_li1':'Assessorament general sobre necessitats en matèria preventiva',
        'ul_1_li2':'Realització de l\'avaluació inicial de riscos',
        'ul_1_li3':'Orientació per a la realització dels plans i programes preventius derivats de l\'avaluació',
        'ul_1_li4':'Fixació de Prioritats i controls d\'eficàcia així com informació complementària sobre les mesures derivades de l\'avaluació de riscos',
        'ul_1_li5':'Mesures bàsiques d\'emergència',
        'ul_1_li6':'Confecció de la memòria anual',
        'ul_1_li7':'Elaboració dels protocols específics segons els riscos detectats',
        'ul_1_li8':'Informació bàsica als treballadors sobre els riscos existents en els seus llocs de treball',
        'para_11':'Actuacions realitzades a partir de l\'entrada en vigor del Concert amb GESEME.',
        'section_5_1':'Formació - informació de riscos laborals',
        'para_12':'Durant tot l\'any s\'ha de realitzar el lliurament d\'informació als treballadors per part de l\'empresa i segons es referencia a l\'article 18 de la Llei 31/1995 de 8 de Novembre de Prevenció de Riscos Laborals. GESEME ha lliurat en cas de realitzar la formació/informació referent als següents conceptes, dels quals podem trobar còpia a l\'apartat corresponent dins del Pla Preventiu:',
        'para_13':'Geseme ha realitzat les següents sessions formatives:',
        'para_14':'Com a part de les obligacions de l\'empresa descrites a l\'article 19 de la Llei 31/1995 de 8 de Novembre de Prevenció de Riscos Laborals, cal realitzar la formació dels treballadors en matèria de Prevenció de Riscos Laborals.',
        'para_15':'Els registres de les formacions que es realitzin quedaran registrades dins del Pla Preventiu de l\'empresa.',
        'para_16':'Els treballadors assistents van ser reunits per l\'empresa, i van signar dos justificants, un que està a la disposició de l\'empresa, i un altre que disposa GESEME a l\'expedient del client.',
        'section_5_2':'Equips de protecció individual',
        'para_17':'L\'empresa, en el seu afany de donar el màxim nivell de seguretat als seus treballadors i donant compliment a l\'article 17 de la Llei 31/1995 de Prevenció de Riscos Laborals, ha de fer lliurament dels diferents equips de protecció individual necessaris per desenvolupar el treball de manera correcta i segura. El lliurament s\'ha fet en diferents dates, depenent de les incorporacions noves i la renovació del material.',
        'para_18':'En el control efectuat pel tècnic es comprova el lliurament de EPIs.',
        'section_5_3':'INVESTIGACIÓ I ANÀLISIS D\'ACCIDENTS',
        'para_19':'L\'empresa ha de demanar a la Mútua d\'accidents laborals l\'informe d\'accidentabilitat del període contractual. S\'ha d\'enviar al tècnic de GESEME per poder analitzar.',
        'para_20':'Dels accidents comunicats per part de l\'Empresa a GESEME s\'ha procedit a realitzar la corresponent recerca, tal i com queda recollit a l\'article 16 de la Llei 31/1995 de 8 de Novembre de Prevenció de Riscos Laborals.',
        'para_21':'Les recerques realitzades estan registrades en els informes corresponents dins del Pla Preventiu de l\'empresa.',
        'section_5_4':'MESURES D\'EMERGENCIA I PLA D\'EMERGENCIES',
        'para_22':'L\'article 20 de la Llei 31/1995, de prevenció de riscos laborals, disposa que tenint en compte la mesura i l\'activitat de cada empresa, i la possible presència de persones alienes a la mateixa, s\'hauran d\'analitzar les possibles situacions d\'emergència i adoptar les mesures necessàries en matèria de primers auxilis, lluita contra incendis i evacuació dels treballadors.',
        'para_23':'Un pla d\'emergència és la planificació i organització humana per a la utilització òptima dels mitjans tècnics previstos, amb la finalitat de reduir al mínim les possibles conseqüències humanes i/o econòmiques que es puguin derivar d\'una situació d\'emergència.',
        'para_24':'Els objectius del pla d\'emergències són:',
        'para_24_li1':'Organitzar els mitjans humans i materials disponibles per contenir i deixar sota control les emergències',
        'para_24_li2':'Protegir al personal i a les instal·lacions',
        'para_24_li3':'Garantir l\'evacuació i la intervenció immediata',
        'para_24_li4':'Facilitar la possible intervenció d\'ajudes exteriors',
        'para_24_li5':'Donar l\'ajuda necessària en cas de lesionats',
        'para_25':'És necessari la implicació i participació de tot el personal de l\'empresa, per garantir la competència del Pla d\'emergències, quant a coneixements, disposició i actuació en aquests casos dels treballadors.',
        'para_26':'GESEME amb el Pla Preventiu fa lliurament d\'un Pla Bàsic de mesures d\'emergència en el qual es redacten les actuacions mínimes davant un accident o incident.',
        'para_27':'Aquest pla es redacta amb la intenció de ser revisat per l\'Empresa cada any per poder comprovar i mantenir la seva funcionalitat i amb l\'assessorament de GESEME. ',
        'para_28':'Es recorda però que en tots els llocs de treball han d\'existir mitjà i equips de lluita contra incendis. Així tot el personal que es trobi a la zona ha de posar-se d\'acord, segons formació rebuda, per establir un procediment i punt de reunió caso d\'incendi i/o evacuació i mantenir clares les zones de pas dels treballadors perquè cas d\'evacuació no es trobin cap obstacle',
        'para_29':'Es va informar als treballadors en les formacions realitzades dels passos que han de seguir en qualsevol incident que surti durant el desenvolupament de la seva activitat.',
        'section_5_5':'Coordinació d\'activitats empresarials',
        'para_30':'La Llei 54/03 de reforma de la Llei de 31/95 de Prevenció de Riscos Laborals, modifica l\'article 24 sobre Coordinació d\'Activitats. Aquesta modificació s\'ha desenvolupat reglamentàriament pel Reial decret 171/2004, per regular la concurrència d\'activitats en un mateix centre de treball. ',
        'para_31':'Les empreses que treballin simultàniament en un centre de treball hauran de cooperar en l\'aplicació de les activitats preventives. A tal fi, establiran els mitjans de coordinació necessaris quant a la protecció i prevenció de riscos laborals i la informació sobre els mateixos als seus respectius treballadors.',
        'section_5_6':'CONTROL D\'EFICÀCIA SOBRE LA IMPLANTACIÓ DEL SISTEMA DE PREVENCIÓ',
        'para_32':'Una vegada duta a terme l\'avaluació i realitzada la planificació, no es pot deixar de seguir actuar en prevenció, ja que ha de ser un fet continuat i en el qual estigui implicada tota l\'organització de l\'empresa. Els controls d\'eficàcia són un instrument de Gestió de la Prevenció.',
        'para_33':'Els controls d\'eficàcia són revisions periòdiques del sistema de gestió de la prevenció de riscos laborals i una anàlisi detallada de les condicions de seguretat (màquines, instal·lacions, eines, etc.) a fi de descobrir les situacions de riscos que es deriven d\'elles (condicions perilloses o pràctiques insegures) amb la finalitat d\'adoptar les mesures adequades per al seu control, evitant l\'accident (prevenció) o reduint els danys materials o personals derivats del mateix (protecció).',
        'para_34':'El grau d\'implicació de l\'empresa en referència a la Implantació de la Prevenció l\'any contractual es considera ',
        'section_5_7':'Evaluacions higièniques',
        'para_35':'L\'objectiu d\'aquest tipus d\'avaluacions és determinar que les condicions higièniques en l\'empresa es troben dins dels paràmetres de la legislació vigent. Dins d\'aquest tipus d\'avaluacions es troben els mesuraments de soroll, il·luminació, temperatura i de l\'existència de contaminants ambientals.',
        'para_36':'Les avaluacions higièniques realitzades poden consultar-se en anterior taula d\'actuacions.',
        'section_5_8':'Avaluacions ergonòmiques i psicosocials',
        'para_37':'Les avaluacions ergonòmiques tenen per objectiu determinar l\'adequació dels diferents llocs en funció dels treballadors i les tasques que en ells es realitzen. Per a això s\'analitzen els elements del plànol de treball, la seva altura, l\'adaptabilitat, elements de l\'entorn de treball, etc. De la mateixa manera s\'analitzen els factors psicosocials determinats per les relacions socials, tipus d\'organització, ritmes de treball, horaris, etc.',
        'para_38':'Les avaluacions ergonòmiques i psicosocials realitzades poden consultar-se en anterior taula d\'actuacions. ',
        'para_39':'GESEME 1996 SL agraeix a l\'empresa, les atencions dispensades, estant a la seva disposició per a qualsevol consulta sobre aquest informe o sobre altres temes relacionats amb la prevenció de riscos.',
      }
    }
  }

  convertDate(fecha)
  {
    return fecha.substr(4, 2) + '/' + fecha.substr(6, 2) + '/' + fecha.substr(0, 4);
  }

}
function compareNumbers(a, b) {
  return a - b;
}
