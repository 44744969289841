import { Component, OnInit, Input, Renderer2, OnDestroy } from '@angular/core';
import { UserLogin } from '../../models/workforce-login.model';
import { AuthenticationService } from 'src/app/services/_authService/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GAuthService } from 'src/app/services/_authService/gauth.service';
import { LogActivityService } from 'src/app/services/_logActivity/log-activity.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {

  userLogin = new UserLogin();
  isLoading = false;
  formResetToggle = true;
  returnUrl: string;
  error = '';
  @Input()
  isModal = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private authService: GAuthService,
    private renderer: Renderer2,
    private logActivityService: LogActivityService    
  ) {
    
  }

  ngOnInit() {
    let errorMsg = localStorage.getItem("sessionErrorMsg");
    if (errorMsg) {
      this.error = errorMsg;
    }
    this.authService._userManager.clearStaleState();
    this.logActivityService.clearAndSaveSessionValues();  
    
  }


  ngOnDestroy() {
    this.renderer.removeClass(document.body, '1-column');
    this.renderer.removeClass(document.body, 'bg-full-screen-image');
    this.renderer.removeClass(document.body, 'blank-page'); 
  }

  login() {
    localStorage.setItem("sessionErrorMsg", null);
    this.error = null;
    this.authService.login();   
  }



}
