import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-pdf-memoria-annual-header',
  templateUrl: './pdf-memoria-annual-header.component.html',
  styleUrls: ['./pdf-memoria-annual-header.component.scss']
})
export class PdfMemoriaAnnualHeaderComponent implements OnInit {

  @Input() language: string;
  @Input() locale: any;
  @Input() page: string;
  @Input() totalPages: string;
  
  constructor() { }

  ngOnInit() {
  }

}
