import { Injectable } from '@angular/core';
import { ActivityFilterModel } from '../models/activity-filter';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  public filterObject: ActivityFilterModel;
  private currentUserId: string;

  constructor() {
    this.initializeFilters();
  }

  private initializeFilters(): void {
    this.currentUserId = JSON.parse(localStorage.getItem('loggeduser')).id;
    this.filterObject = new ActivityFilterModel();
    this.resetFilterValues();
  }

  public resetFilterValues(): void {
    this.filterObject.tipo_consulta = 'TODAS';
    this.filterObject.page_number = 1;
    this.filterObject.id_tecnico = this.currentUserId;
    this.filterObject.id_empresa = null;
    this.filterObject.id_centro = null;
    this.filterObject.id_actuaciontipo = null;
    this.filterObject.id_actuacionestado = null;
    this.filterObject.fecha_desde = null;
    this.filterObject.fecha_hasta = null;
  }

  public setPageSize(forPagination: boolean) {
    this.filterObject.page_size = forPagination ? 25 : 1000;
  }

  public resetPageDetails(): void {
    this.filterObject.page_number = 1;
  }

  public setFilterParamters(fObject: ActivityFilterModel): void {
    this.filterObject = fObject;
  }

  public resetDateFilters(): void {
    this.filterObject.fecha_desde = null;
    this.filterObject.fecha_hasta = null;
  }

  public setFilterValuesForAPI(): void {
    this.filterObject.id_actuaciontipo = this.filterObject.id_actuaciontipo === '0'
      ? null : this.filterObject.id_actuaciontipo;
    this.filterObject.id_empresa = this.filterObject.id_empresa === '0'
      ? null : this.filterObject.id_empresa;
    this.filterObject.id_centro = this.filterObject.id_centro === '0'
      ? null : this.filterObject.id_centro;
    this.filterObject.id_actuacionestado = this.filterObject.id_actuacionestado === '0'
      ? null : this.filterObject.id_actuacionestado;
  }

  public setFilterValuesForView(): void {
  
    this.filterObject.id_actuaciontipo = !this.filterObject.id_actuaciontipo ? '0' : this.filterObject.id_actuaciontipo;
    this.filterObject.id_empresa = !this.filterObject.id_empresa ? '0' : this.filterObject.id_empresa;
    this.filterObject.id_centro = !this.filterObject.id_centro ? '0' : this.filterObject.id_centro;
    this.filterObject.id_actuacionestado = !this.filterObject.id_actuacionestado ? '0' : this.filterObject.id_actuacionestado;
  }
}
