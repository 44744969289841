import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneratePdfService } from 'src/app/services/common/generate-pdf.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MemoriaAnualService } from 'src/app/services/common/memoria-anual.service';
import moment from 'moment';
import { ActivityFilterModel } from 'src/app/models/activity-filter';
import { FilterService } from 'src/app/services/filter.service';
import { ActivityService } from 'src/app/services/activity.service';
import { DropdownService } from 'src/app/services/dropdown.service';
import { SubSink } from 'subsink';
import { WizardComponent } from 'angular2-wizard';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-memoria-anual-popup',
  templateUrl: './memoria-anual-popup.component.html',
  styleUrls: ['./memoria-anual-popup.component.scss']
})
export class MemoriaAnualPopupComponent implements OnInit {
  contracts = [
    { id: 1, name: 'Vilnius-abc' },
    { id: 2, name: 'Kaunas-abc' },
    { id: 3, name: 'Pavilnys-abc', selected: true },
    { id: 4, name: 'Pabradė-abc' },
    { id: 5, name: 'Klaipėda-abc' }
  ];
  @ViewChild("wizardComponent") wizardComponent: WizardComponent;

  @Input() public companyData: any;
  public emailValidators = [Validators.email];
  public emailPlaceholder = 'ingrese la (s) dirección (s) de correo electrónico';
  public emailAddMoreText = '';
  public IsFooterHidden = false;
  period: any = [];
  selectedDate: any;
  reportValues: any;
  ActivityFormGroupWizard1: FormGroup;
  ActivityFormGroupWizard2: FormGroup;
  ActivityFormGroupWizard3: FormGroup;
  filterObject: ActivityFilterModel;
  public selectedCompanyLanguage = 'es';
  contactPersons: any;
  workCenterData: any;
  actuacionesData: any;
  isCa = false;
  isUpdateInputData = false;
  Implicacion: any;
  activeContracts: any
  selected
  emailAddresses = [];
  private clientReqIPAddress = '';
  base64: any;
  memoriaEmailObject: any;
  private subs = new SubSink();
  public userEmailAddress = '';


  constructor(
    public memoriaAnualModal: NgbActiveModal,
    public fb: FormBuilder,
    private toastrService: ToastrService,
    private pdfService: GeneratePdfService,
    private spinner: NgxSpinnerService,
    private errorHandler: ErrorHandlerService,
    public memoriaAnualService: MemoriaAnualService,
    private filterService: FilterService,
    private sharedService: SharedService,
    private activityService: ActivityService,
    private dropdownService: DropdownService,

  ) {
    this.spinner.hide();
    const loggedUserId = JSON.parse(localStorage.getItem('loggeduser')).id;

    this.subs.add(
      this.dropdownService.getClientReqIPAddress().subscribe(response => {
        this.clientReqIPAddress = response.clientReqIPAddress;
      }, error => {
        this.errorHandler.handle(error);
      }),
      this.dropdownService.getUserEmailAddress(loggedUserId).subscribe(response => {
        this.userEmailAddress = response.userEmail ? response.userEmail : '';
        if (this.userEmailAddress !== '') {
          const emailObj = { display: this.userEmailAddress, value: this.userEmailAddress }
          this.emailAddresses.push(emailObj);
          this.ActivityFormGroupWizard2.get('ContactEmail').setErrors(null);
        }
        this.ActivityFormGroupWizard2.updateValueAndValidity();
      }, error => {
        this.errorHandler.handle(error);
      }),
    );
  }

  DownloadHtmlReport(_id): void {
    this.spinner.show();
    let requestModel = this.setMemoriaValues();

    this.memoriaAnualService.downloadMemoriaAttachment(requestModel).subscribe(response => {
      const linkSource = 'data:application/pdf;base64,' + response.base64String + '\n';
      const downloadLink = document.createElement('a');
      const fileName = response.fileName;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      this.spinner.hide();
    }, error => {
      this.toastrService.error('No se pudo descargar el archivo adjunto', 'Error');
      this.errorHandler.handle(error);
      this.spinner.hide();
    });
  }

  ngOnInit() {
    this.spinner.hide();
    this.pdfService.shareData = this.companyData;
    this.InitFormWizard1();
    this.InitFormWizard2();
    this.getClosedContacts(this.companyData.WorkCentreId);
    this.getWorkCenterData(this.companyData.WorkCentreId);
    this.getContactPersons(this.companyData.WorkCentreId);
    this.getActuaciones(this.companyData.WorkCentreId)

    this.changeWizardText();
    const implicacionValues = {
      Baja: 'Baja',
      Media: 'Media',
      Alta: 'Alta'
    }
    this.Implicacion = implicacionValues; 
    const workCenterId = this.companyData.WorkCentreId;

    this.dropdownService.getContactEmailAddress(workCenterId).subscribe(response => {
      if (response.length > 0) {
        response.forEach(element => {
          const emailObj = { display: element, value: element }
          this.emailAddresses.push(emailObj);
        });
      }
    });

  }

  getActuaciones(wordCenterId) {
    let fromStr = this.memoriaAnualService.ActivityFormGroupWizard1.controls.Inicio.value;
    let toStr = this.memoriaAnualService.ActivityFormGroupWizard1.controls.Fin.value;

    if (fromStr != null && toStr != null) {
      const [fromday, frommonth, fromyear] = fromStr.split('/');
      const fromdate = fromyear + frommonth + fromday;

      const [today, tomonth, toyear] = toStr.split('/');
      const todate = toyear + tomonth + today;

      const obj = new ActivityFilterModel();
      obj.fecha_desde = fromdate;
      obj.fecha_hasta = todate;
      obj.id_centro = wordCenterId;
      obj.tipo_consulta = 'TODAS';
      obj.id_empresa = this.companyData.CompanyId;
      obj.id_tecnico = this.filterService.filterObject.id_tecnico;
      obj.id_tecnico_selected = this.filterService.filterObject.id_tecnico_selected;
      obj.id_actuacionestado = null;
      obj.id_actuaciontipo = localStorage.getItem("selectedTipoActuation");
      obj.page_size = Number(localStorage.getItem("selectedRowCount"));
      obj.page_number = 1;

      this.activityService.getFilteredActivities(obj).subscribe(res => {
        this.actuacionesData = res;
      });
    }
  }

  getClosedContacts(wordCenterId) {
    this.contracts = [];
    var obj = {
      workCentreId: wordCenterId,
      activityTypeId: this.companyData.ActivityTypeId
    }
    this.memoriaAnualService.getAllClosedContracts(obj).subscribe(res => {
      if (res.webError != null) {
        this.spinner.hide();
        this.errorHandler.handle(res.webError.messageToUser, true);
      }
      else if (res.length > 0) {
        this.activeContracts = res;
        this.selectedDate = '' + moment(new Date(res[0].dateBeginContract)).format('DD/MM/yyyy') + ' - ' + moment(new Date(res[0].dateEndContract)).format('DD/MM/yyyy') + ''
        this.memoriaAnualService.ActivityFormGroupWizard1.patchValue({
          DateRange: Number(res[0].id),
          Inicio: moment(new Date(res[0].dateBeginContract)).format('DD/MM/yyyy'),
          Fin: moment(new Date(res[0].dateEndContract)).format('DD/MM/yyyy')
        });

        res.forEach(element => {

          let obj = {
            id: element.id,
            name: moment(new Date(element.dateBeginContract)).format('DD/MM/yyyy') + ' - ' + moment(new Date(element.dateEndContract)).format('DD/MM/yyyy')
          }
          this.period.push(obj);
        });
        this.contracts = this.period;

        this.getActuaciones(this.companyData.wordCenterId);
      }
      else {
        this.toastrService.warning("no hay contratos pasados para mostrar");
      }
    })
  }

  getContactPersons(wordCenterId) {
    this.memoriaAnualService.getContactPersons(wordCenterId).subscribe(res => {
      if (res) {
        if (res.webError != null) {
          this.spinner.hide();
          this.errorHandler.handle(res.webError.messageToUser, true);
        } else {
          this.contactPersons = res;
        }
      }
    })
  }

  getWorkCenterData(wordCenterId) {
    this.memoriaAnualService.getWorkCenterData(wordCenterId).subscribe(res => {
      if (res) {
        if (res.webError != null) {
          this.spinner.hide();
          this.errorHandler.handle(res.webError.messageToUser, true);
        } else {
          this.workCenterData = res;
        }
      }
    })
  }

  InitFormWizard1() {
    this.memoriaAnualService.ActivityFormGroupWizard1 = this.fb.group({
      TemplateLanguage: [this.selectedCompanyLanguage, Validators.required],
      Inicio: [],
      Fin: [],
      Implicacions: [null, Validators.required],
      DateRange: [null]
    });
  }

  get f() {
    return this.memoriaAnualService.ActivityFormGroupWizard1.controls;
  }

  InitFormWizard2() {
    this.ActivityFormGroupWizard2 = this.fb.group({
      EmailSubject: [null, Validators.required],
      EmailBody: [null, Validators.required],
      ContactEmail: [null, Validators.required]
    });

    this.updateMailBody();
  }

  updateMailBody() {
    if (this.memoriaAnualService.ActivityFormGroupWizard1.value.TemplateLanguage == 'es') {
      this.ActivityFormGroupWizard2.patchValue({
        EmailSubject: '[GESEME] Memoria anual',
        EmailBody: `Estimado/a cliente.\n\n` +
          `Adjunto le remito la “Memoria anual” que recoge las actuaciones llevadas a cabo por GESEME en su empresa, en el último período contractual. \n\n` +
          `Atentamente,`
      });
    } else {
      this.ActivityFormGroupWizard2.patchValue({
        EmailSubject: '[GESEME] Memoria anual',
        EmailBody: `Benvolgut/da client, \n\n` +
          `Li adjuntem la “Memoria anual” que recull les actuacions realitzades per GESEME en la seva empresa, al llarg de l’últim període contractual. \n\n` +
          `Atentament,`
      });
    }
  }

  onChangePeriod(period) {
    this.memoriaAnualService.ActivityFormGroupWizard1.patchValue({
      Inicio: period.name.split('-')[0],
      Fin: period.name.split('-')[1]
    });

    this.getActuaciones(this.companyData.wordCenterId);
  }

  onChangeLanguage(lang) {
    if (lang.target.value == 'ca') {
      this.isCa = true;
      this.memoriaAnualService.ActivityFormGroupWizard1.patchValue({
        Implicacions: 'Baxia'
      });
    } else {
      this.isCa = false;
      this.memoriaAnualService.ActivityFormGroupWizard1.patchValue({
        Implicacions: 'Baja'
      });
    }
    this.updateMailBody();
  }

  changeWizardText(_isFirma = false): void {
    const modalElement = document.getElementById('modal-annual-report');
    var footer = modalElement.getElementsByClassName('card-footer')[0];
    if(modalElement.getElementsByClassName('card-footer').length > 1){
      footer = modalElement.getElementsByClassName('card-footer')[1];
    }
    const footerBtns = footer.getElementsByClassName('btn');

    for (let index = 0; index < footerBtns.length; index++) {
      footerBtns[index].classList.add('wizard-buttons');
      if (footerBtns[index].innerHTML === 'Previous') {
        footerBtns[index].innerHTML = 'Anterior';
      } else if (footerBtns[index].innerHTML === 'Next') {
        footerBtns[index].innerHTML = 'Siguiente';
      } else if (footerBtns[index].innerHTML === 'Done') {
        footerBtns[index].innerHTML = 'Enviar Mail';
      }
    }
  }

  onNextFistFormWizard() {
    this.isUpdateInputData = true;
  }

  onPreFistFormWizard() {
    this.isUpdateInputData = false;
  }

  setMemoriaValues() {
    let actuacionesTable = [];
    if (this.actuacionesData.data.length > 0) {
      actuacionesTable = this.actuacionesData.data.sort((a: any, b: any) => {
        return <any>new Date(b.fecha) - <any>new Date(a.fecha);
      });
    }

    let companyDetails = this.companyData;
    companyDetails.ActivityDate = this.companyData.ActivityDate != null ?
      this.sharedService.dateToString(this.companyData.ActivityDate) : this.companyData.ActivityDate;
    companyDetails.ActivityFinishTime = this.companyData.ActivityFinishTime != null ?
      this.sharedService.dateToString(this.companyData.ActivityFinishTime) : this.companyData.ActivityFinishTime;
    companyDetails.ActivityTime = this.companyData.ActivityTime != null ?
      this.sharedService.dateToString(this.companyData.ActivityTime) : this.companyData.ActivityTime;

    let coverData = {
      language: this.memoriaAnualService.ActivityFormGroupWizard1.value.TemplateLanguage,
      incio: this.memoriaAnualService.ActivityFormGroupWizard1.value.Inicio,
      fin: this.memoriaAnualService.ActivityFormGroupWizard1.value.Fin,
      implicacions: this.memoriaAnualService.ActivityFormGroupWizard1.value.Implicacions,
    }

    return {
      activityData: coverData,
      companyData: companyDetails,
      contactPersons: this.contactPersons,
      workCenters: this.workCenterData,
      activeContracts: this.activeContracts,
      actuacionesData: actuacionesTable,
    };
  }

  public sendParteDeVisitaEmail(): void {
    this.spinner.show();
    let requestpdf = this.setMemoriaValues();
    if (this.ActivityFormGroupWizard2.valid) {
      this.spinner.show();
      const obj = {
        EmailAddresses: this.emailAddresses,
        EmailSubject: this.ActivityFormGroupWizard2.get('EmailSubject').value,
        EmailBody: this.ActivityFormGroupWizard2.get('EmailBody').value,
        Base64Attachment: null,
        CompanyName: this.pdfService.shareData.CompanyName,
        WorkCentreName: this.pdfService.shareData.WorkCentreName,
        AssignedUserId: this.pdfService.shareData.AssignedUserId
      }

      let reuestObj = {
        memoriaRequestPDF: requestpdf,
        memoriaEmail: obj
      };
      
      this.dropdownService.sendMemoriaEmail(reuestObj).subscribe(res => {
        if (res) {
          if (res == 1) {
            this.toastrService
              .success('Se le enviará un correo electrónico si ha solicitado uno',
                'Éxito');
          } else {
            this.toastrService
              .error('Se produjo un error interno al enviar el correo electrónico',
                'Éxito');
          }
          this.spinner.hide();
          this.memoriaAnualModal.close();
        }
      });
    } else {
      this.ActivityFormGroupWizard2.touched;
      this.spinner.hide();
    }
  }


}
