import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { LoggedUser } from 'src/app/services/_authModel/user';
import { ActivityStatusService } from '../common/activity-status.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<LoggedUser>;
    public currentUser: Observable<LoggedUser>;

    constructor(private http: HttpClient, ) {
        this.currentUserSubject = new BehaviorSubject<LoggedUser>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): LoggedUser {
        return this.currentUserSubject.value;
    }

    login(userId: string, password: string,gAuthToken:string="") {
        return this.http.post<any>(`${environment.baseUrl}/Login/Authenticate?userId=${userId}&password=${password}&gAuthToken=${gAuthToken}`, { userId, password,gAuthToken})
            .pipe(map(user => {                
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                return user;
            }));
    }

    logout() {        
        // remove user from local storage to log user out
        localStorage.removeItem('loggeduser');
        this.currentUserSubject.next(null);
    }
  
}
