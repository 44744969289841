import { Component, OnInit, ViewChild } from '@angular/core';
import { PowerbiService } from 'src/app/services/powerBi/powerbi.service';
import { ActivatedRoute } from '@angular/router';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';
import { models } from 'powerbi-client';
import { Observable, Observer } from 'rxjs';

@Component({
  selector: 'app-power-bi-view',
  templateUrl: './power-bi-view.component.html',
  styleUrls: ['./power-bi-view.component.scss']
})
export class PowerBiViewComponent implements OnInit {

  @ViewChild(PowerBIReportEmbedComponent) reportObj!: PowerBIReportEmbedComponent;
  userID:string="";
  reportId:Number;
  isLoading:boolean;
  embedConfigObs: Observable<any>;
  reportClass = 'report-container reportClass iframe-boder';
  readReportId:string;
  readWorkspaceId:string;

  constructor(private powerBiService:PowerbiService,private activatedRoute: ActivatedRoute) { }
  ngOnInit() {
    this.getUserId();
    this.readRouterData();
    this.getPBIReportEmbededDetail()
  }
  
  getPBIReportEmbededDetail(){
    this.isLoading = true;
    this.powerBiService.getEmbededInfo(this.readReportId,this.userID).subscribe(res=>{
      this.isLoading = false;

      let embedConfig = { 
        type: 'report',
        id: res.EmbedReport[0].ReportId,
        embedUrl:res.EmbedReport[0].EmbedUrl,
        accessToken: res.EmbedToken.token, 
        tokenType: models.TokenType.Embed,
        hostname: "https://app.powerbi.com",
      } 
      this.embedConfigObs = new Observable<any>((observer: Observer<any>) => {
        observer.next(embedConfig)
        
      });
    }, error => {
      //this.uiService.showFailToast("Error","Informe de error al cargar");
      this.isLoading = false;
    });
  }


  readRouterData(){
       this.readReportId=  this.activatedRoute.snapshot.queryParamMap.get("reportId");
       this.readWorkspaceId=this.activatedRoute.snapshot.queryParamMap.get("workSpaceId");
  }

  private getUserId():void
  {
    const logedUser=JSON.parse(localStorage.getItem("loggeduser"));
    this.userID=logedUser.id;
  }

}
