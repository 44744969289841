import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { CustomEncoderService } from './custom-encoder.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './_authService/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  protected httpOptions: any;
  protected token: any;
  protected timeout_seconds = 60000;
  public baseUrl: string;

  constructor(private http: HttpClient, private customEncoder: CustomEncoderService,private authService : AuthenticationService) {
    this.baseUrl = environment.baseUrl;
  }

  // private getAuthorizationToken(): string {
  //   // Until Login Is Done
  //   return 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImFkbWluIiwicm9sZSI6IkFkbWluaXN0cmF0b3IiLCJuYmYiOjE1NzgzMDU5ODEsImV4cCI6MTU3ODMwOTU4MSwiaWF0IjoxNTc4MzA1OTgxLCJpc3MiOiJFeHBlbnNlc0lzc3VlciIsImF1ZCI6IkV4cGVuc2VzQXVkaWVuY2UifQ.OEsOTnNaOsDSKQ3NzDC9kCxYrKKNbNlIpbKjtke08eq3x8y4BwgyeGg0yr4run3tDTUns-EyDSZFEY1JD4B7cBNSrRmamwYh03jgxCGaUH3vAZ_dPjQ1DFQeekNu8agim37qsZmO_7Ct5E2G2AMnriIeW42Cw2Hg3DdCq3iDx3-1pTyVKaC0cf68QxnxSiXKGlfmin5wDZQkQaujZSd74IZWGd-cKtUukT_5_bwD7Ro9JP5KEHFegmRz726DMGIpgC_p7qrXf-ZdRPisOQ8dwJBth1oiLRtz2C5GfJBecAG7vD3t3COgcgntEoz82ssIMVci424gg3oTGiBFq8lyDQ';
  // }

  private getRequestHttpParams(httpParams?: any) {
    let params = new HttpParams({ encoder: this.customEncoder });
    for (const key in httpParams) {
      if (httpParams.hasOwnProperty(key)) {
        params = params.append(key, httpParams[key]);
      }
    }
    return params;
  }

  private getRequestHttpHeaders(): HttpHeaders {
    //const authorizationToken = 'Bearer ' + this.getAuthorizationToken();
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': ''
    });
    return headers;
  }

  private getRequestHttpOptions(httpParams?: any) {
    const params = this.getRequestHttpParams(httpParams);
    const headers = this.getRequestHttpHeaders();
    const options = {
      headers,
      params
    };
    return options;
  }

  get(url: string, httpParams?, withTimeout?: boolean): Observable<any> {
    const uri = this.baseUrl + url;
    const options = this.getRequestHttpOptions(httpParams);

    let call = this.http.get(uri, options);
    if (withTimeout) {
      call = call.timeout(this.timeout_seconds);
    }

    return call as Observable<any>;
  }

  post(url: string, data?: any, httpParams?, withTimeout?: boolean): Observable<any> {
    const options = this.getRequestHttpOptions(httpParams);

    let call = this.http.post(this.baseUrl + url, data, options);
    if (withTimeout) {
      call = call.timeout(this.timeout_seconds);
    }

    return call as Observable<any>;
  }

  delete(url: string, withTimeout?: boolean) {
    const options = this.getRequestHttpOptions();
    let call = this.http.delete(this.baseUrl + url, options);
    if (withTimeout) {
      call = call.timeout(this.timeout_seconds);
    }

    return call;
  }

  put(url: string, data?: any, httpParams?: any, withTimeout?: boolean) {
    const options = this.getRequestHttpOptions();

    let call = this.http.put(this.baseUrl + url, data, options);
    if (withTimeout) {
      call = call.timeout(this.timeout_seconds);
    }

    return call;
  }

  multiPartFormPost(url: string, key: string, image, token, withTimeout?: boolean) {
    const headers = new HttpHeaders().set('Authorization', token ? token : '');

    const options = {
      headers
    };
    const formData = new FormData();
    formData.append(key, image);

    let call = this.http.post<any>(this.baseUrl + url, formData, options);
    if (withTimeout) {
      call = call.timeout(this.timeout_seconds);
    }

    return call;
  }
}


