import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { ActivityType } from "../models/activity-type.model";
import { SelectOptions } from "../models/select-options.model";
import { Company } from "../models/company.model";
import { Workforce } from "../models/workforce.model";
import { WorkingCentre } from "../models/working-centre.model";
import { StatusType } from "../models/status-type.model";
import { environment } from "src/environments/environment";
import { MasterDetailsApiService } from "./masterdetailsapi.service";
import { MultipleActivitySaveModel } from "../models/multiple.activity.save.model";
import { DecimalToHex } from "../Helpers/DecimalToHex";

@Injectable({
  providedIn: "root",
})
export class DropdownService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
    }),
  };
  emailAddresses = [];
  constructor(private apiService: MasterDetailsApiService) {
    this.apiService.baseUrl = environment.baseUrl;
  }

  WrappedToDropDownList(
    res: Array<any>,
    displayMember: string,
    valueMemeber: string,
    withAny?: boolean,
    withoutVisit?: boolean,
    iStatusType: boolean = false,
    isForLateralFilter: boolean = false,
    isStatus = false
  ): Array<SelectOptions> {
    const tempList = [];
    if (withAny) {
      tempList.push({ label: "Cualquiera", value: String(0) });
    }
    // Assign the sort order manually first for status types only
    // Then bind the values in sort order
    if (iStatusType) {
      res.forEach((element) => {
        element["sortOrder"] = this.getSortOrderForStatusType(
          element.statusTypeName
        );
      });

      // change the status type list order by Sort Order
      const sortedStatusTypes = res.sort((a, b) => a.sortOrder - b.sortOrder);

      sortedStatusTypes.forEach((element) => {
        if (element.name === "Visita" && withoutVisit) {
          return;
        }
        const optItem = {
          label: element[displayMember],
          value: String(element[valueMemeber]),
        };
        if (isStatus) {
          optItem["color"] = DecimalToHex.convert(element["numcolor"]);
        }
        // Client request=>Include 'Realizada-Pendiente de validar' ONLY for the lateral filter
        if (
          !isForLateralFilter &&
          element.statusTypeName.trim() === "Realizada-Pendiente de validar"
        ) {
          return;
        } else {
          tempList.push(optItem);
        }
      });
    } else {
      res.forEach((element) => {
        if (element.name === "Visita" && withoutVisit) {
          return;
        }
        const optItem = {
          label: element[displayMember],
          value: String(element[valueMemeber]),
        };
        if (isStatus) {
          optItem["color"] = DecimalToHex.convert(element["numcolor"]);
        }
        tempList.push(optItem);
      });
    }
    return tempList;
  }

  WrappedToDropDownListWithLang(
    lang: any,
    res: Array<any>,
    displayMember: string,
    displayMember1: string,
    valueMemeber: string,
    withAny?: boolean,
    withoutVisit?: boolean,
    iStatusType: boolean = false,
    isForLateralFilter: boolean = false,
    isStatus = false
  ): Array<SelectOptions> {
    const tempList = [];
    if (res.length > 0) {
      res.forEach((element) => {
        if (element.name === "Visita" && withoutVisit) {
          return;
        }
        if (lang == "es") {
          const optItem = {
            label: element[displayMember],
            value: String(element[valueMemeber]),
          };
          tempList.push(optItem);
        } else {
          const optItem = {
            label: element[displayMember1],
            value: String(element[valueMemeber]),
          };
          tempList.push(optItem);
        }
      });
    }
    return tempList;
  }

  WrappedToDropDownListAsignadasa(
    res: Array<any>,
    displayMember: string,
    valueMemeber: string,
    withAny?: boolean,
    withoutVisit?: boolean,
    iStatusType: boolean = false,
    isForLateralFilter: boolean = false,
    isStatus = false
  ): Array<SelectOptions> {
    const tempList = [];
    res.forEach((element) => {
      if (element.nombre_apellidos === "técnico inexistente") {
        // this.filterService.filterObject.id_tecnico_selected=element.id;
        // this.filterService.filterObject.id_tecnico=element.id;
      }
      const optItem = {
        label: element[displayMember],
        value: String(element[valueMemeber]),
      };
      if (element.nombre_apellidos !== "cualquiera") {
        tempList.push(optItem);
      }
    });
    return tempList;
  }

  WrapPaginationRangeToDropDownList(
    res: Array<any>,
    displayMember: string,
    valueMemeber: string
  ): Array<SelectOptions> {
    const tempList = [];
    // tempList.push({ label: 'Cualquiera', value: String(-1) });
    res.forEach((element) => {
      const optItem = {
        label: element[displayMember],
        value: String(element[valueMemeber]),
      };
      tempList.push(optItem);
    });
    return tempList;
  }

  getActivityTypes(
    activityTypeNameSubstring: string = "-99",
    fetchLimit: number = 100,
    activiyTypeId: string = "",    
    isNotVisitaType: boolean = false,
    isSearchMode: boolean = false,
    size: number = 0,
  ): Observable<ActivityType[]> {
    return this.apiService.get(
      "Common/ActivityType/GetAll?activityTypeNameSubstring=" + activityTypeNameSubstring +
        "&fetchLimit=" + fetchLimit +
        "&activityTypeId=" + activiyTypeId +        
        "&isNotVisitaType=" + isNotVisitaType +
        "&isSearchMode=" + isSearchMode +
        "&size=" + size
    );
  }

  getCompanies(
    companyNameSubstring: string = "-99",
    fetchLimit: number = 100,
    companyId: string = "",
    isSearchMode: boolean = false
  ): Observable<Company[]> {
    return this.apiService.get(
      "Common/Company/GetAll?companyNameSubstring=" +
        companyNameSubstring +
        "&fetchLimit=" +
        fetchLimit +
        "&companyId=" +
        companyId +
        "&isSearchMode=" +
        isSearchMode
    );
  }

  // Get filtered companies
  getFilteredCompanies(companyIds: Array<string>): Observable<Company[]> {
    const url: string = "Common/Company/Get" + "?id=" + companyIds.join("&id=");
    return this.apiService.get(url);
  }

  getWorkingCentres(
    wcNameSubstring: string = "-99",
    fetchLimit: number = 100,
    workCentreId: string = ""
  ): Observable<WorkingCentre[]> {
    return this.apiService.get(
      "Common/WorkCentre/GetAll?wcNameSubstring=" +
        wcNameSubstring +
        "&fetchLimit=" +
        fetchLimit +
        "&workCentreId" +
        workCentreId
    );
  }

  getWorkCentreDetails(workCenterId: any): Observable<any> {
    return this.apiService.get(
      "Common/WorkCentre/Get/Single" + "?id=" + workCenterId
    );
  }

  // Get filtered working centres
  getFilteredWorkingCentres(
    workCentreIds: Array<string>
  ): Observable<WorkingCentre[]> {
    const url: string =
      "Common/WorkCentre/Get" + "?id=" + workCentreIds.join("&id=");
    return this.apiService.get(url);
  }

  getWorkingCentresByCompany(
    companyId: string,
    wcNameSubstring: string = "-99",
    fetchLimit: number = 100,
    workCentreId: string = "",
    isSearchMode: boolean = false
  ): Observable<WorkingCentre[]> {
    return this.apiService.get(
      "Common/WorkCentre/ForCompanyOrUser?CompanyId=" +
        companyId +
        "&wcNameSubstring=" +
        wcNameSubstring +
        "&fetchLimit=" +
        fetchLimit +
        "&workCentreId=" +
        workCentreId +
        "&isSearchMode=" +
        isSearchMode
    );
  }

  getUsers(): Observable<Workforce[]> {
    return this.apiService.get("Workforce/GetAll");
  }

  getBulkEmail(centroCode: string) {
    return this.apiService.get("Workforce/BulckEmail?CentroCode=" + centroCode);
  }

  getCompanyCodigo(companyId: string) {
    return this.apiService.get("Common/Company/Get/Codigo?id=" + companyId);
  }

  getUsersLimited(
    usernameSubStr: string = "-99",
    fetchLimit: number = 100,
    userId: string = "",
    isSearchMode: boolean = false
  ): Observable<Workforce[]> {
    return this.apiService.get(
      "Workforce/GetAllLimited?usernameSubStr=" +
        usernameSubStr +
        "&fetchLimit=" +
        fetchLimit +
        "&userId=" +
        userId +
        "&isSearchMode=" +
        isSearchMode
    );
  }

  getAssociatedTechs(
    usernameSubStr: string = "-99",
    fetchLimit: number = 100,
    userId: string = "",
    isSearchMode: boolean = false
  ): Observable<Workforce[]> {
    return this.apiService.get(
      "Workforce/GetAssociatedTechs?usernameSubStr=" +
        usernameSubStr +
        "&fetchLimit=" +
        fetchLimit +
        "&userId=" +
        userId +
        "&isSearchMode=" +
        isSearchMode
    );
  }

  getActivityStatusTypes(
    isAssignableOnly: boolean = false
  ): Observable<StatusType[]> {
    return this.apiService.get(
      "Common/StatusType/GetAll?isAssignableOnly=" + isAssignableOnly
    );
  }

  // To manually set the order of the status types
  getSortOrderForStatusType(statusType): number {
    let sortOrder = 0;
    const trimmedStatusType = statusType.trim();
    switch (trimmedStatusType) {
      case "Pendiente de confirmar":
        sortOrder = 1;
        break;
      case "Confirmada-Pendiente de realizar":
        sortOrder = 2;
        break;
      case "Realizada-Pendiente de validar":
        sortOrder = 3;
        break;
      case "Realizada y Validada":
        sortOrder = 4;
        break;
      case "Cancelada":
        sortOrder = 5;
        break;
      default:
        sortOrder = 1;
        break;
    }

    return sortOrder;
  }

  // NOTE : This is related to activity service but is retrieved from the master data API service
  getClientReqIPAddress(): Observable<any> {
    return this.apiService.get("Common/IPAddress/Get");
  }

  // Dedicated API call to log the operations(insert/update/delete) done by the user
  // NOTE : This is related to activity service but is invoked from the master data API service
  saveLogUserActivity(userActivityLog: any) {
    return this.apiService.post("Common/UserActivityLog/Save", userActivityLog);
  }

  // NOTE : This is related to activity service but is retrieved from the master data API service
  public async saveMultipleActivities(
    saveModel: MultipleActivitySaveModel
  ): Promise<any> {
    return this.apiService.post("Activity/AddMultiple", saveModel).toPromise();
  }

  public sendMemoriaEmail(emailModel: any): Observable<any> {
    return this.apiService.post("Activity/SendMemoriaEmail", emailModel);
  }
  public SendMemoriaEmailWithQuiz(emailModel: any): Observable<any> {
    return this.apiService.post("Activity/SendMemoriaEmailWithQuiz", emailModel);
  }

  // NOTE : This is related to activity service but is retrieved from the master data API service
  getUserEmailAddress(userId: string): Observable<any> {
    return this.apiService.get(`Workforce/Get/Email/${userId}`);
  }

  getContactEmailAddress(workCentreId: string): Observable<any> {
    return this.apiService.get(`Workforce/ContactEmail/${workCentreId}`);
  }

  GetActiveContractsByCentroCode(CentroCode: string) {
    return this.apiService.get(
      "Workforce/GetActiveContractsByCentroCode?CentroCode=" + CentroCode
    );
  }

  sendEmail(activity: any): Observable<any> {
    return this.apiService.post("Activity/SendEmail", activity);
  }
}
