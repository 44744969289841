import { Component, OnInit } from '@angular/core';
import { ActivityService } from 'src/app/services/activity.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ActuacionItem } from 'src/app/models/actuacion-item';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectOptions } from 'src/app/models/select-options.model';
import { ActivityFilterModel } from 'src/app/models/activity-filter';
import { FilterService } from 'src/app/services/filter.service';
import { FormControl, FormGroup } from '@angular/forms';
import { SharedService } from 'src/app/services/shared.service';
import moment from 'moment/moment';
import { ActivityCalendarListService } from 'src/app/services/activity-calendar-list.service';
import { ActivityStatusService } from 'src/app/services/common/activity-status.service';

@Component({
  selector: 'app-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.scss',
  ]
})
export class ActivityListComponent implements OnInit {

  public activityList: Array<ActuacionItem>;
  modalOptions: NgbModalOptions;
  filterObject: ActivityFilterModel;
  deviceInfo: any;
  isMobileDevice: boolean;
  isDesktopDevice: boolean;
  isTabletDevice: boolean;
  public listViewPaginationRanges$: SelectOptions[];
  public listViewPaginationRowsPerPage$: SelectOptions[];
  selectedPaginationValue = '0';
  selectedRowsPerCount = '2';
  pageNumberList: any[] = [];
  pageCount = 0;
  message: string;
  test1 = '';
  emptyActuacionItem : any[]=[]
  dateValidationText:string ="Ingresar valor"

  dateFilterForm = new FormGroup({
    DateFrom: new FormControl(null),
    DateTo: new FormControl(null)
  });

  constructor(
    public activityService: ActivityService,
    private errorHandler: ErrorHandlerService, private modalService: NgbModal,
    private deviceDetectorService: DeviceDetectorService, private spinner: NgxSpinnerService,
    private filterService: FilterService,
    private sharedService: SharedService,
    private activityCalendarListService: ActivityCalendarListService,
    private activityStatusService: ActivityStatusService,
    ) {

    this.modalOptions = {
      backdrop: 'static',
      windowClass: 'text-left modal-fade-animation',
      size: 'lg',
      centered: true
    };

    if (JSON.parse(localStorage.getItem('loggeduser'))) {
      this.getAppUsageDeviceDetails();
    }

    this.onFormChange();
  }

  ngOnInit() {
    this.loadActivities();
    this.loadPaginationRanges();
    this.registerPaginationRanges();
    this.registerTotalActionCount();
    this.registerSelectedPaginationRange();
    this.setDefaultDates();
    this.filterActivityDates();
  }

  getAppUsageDeviceDetails() {
    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    this.isMobileDevice = this.deviceDetectorService.isMobile();
    this.isTabletDevice = this.deviceDetectorService.isTablet();
    this.isDesktopDevice = this.deviceDetectorService.isDesktop();
  }

  setActionCountForListViewPage() {
    if (this.isMobileDevice) {
      this.activityService.listViewPaginationConfig.itemsPerPage = 5;
      this.selectedRowsPerCount = '5';
    } else if (this.isTabletDevice || this.isDesktopDevice) {
      this.activityService.listViewPaginationConfig.itemsPerPage = 10;
      this.selectedRowsPerCount = '10';
    }
  }

  loadActivities() {
    this.activityList = this.activityService.listViewArray;
  }

  returnEmprasa(activity: ActuacionItem) {
    var emprasa = activity.empresa_nombre; 
    var centro = activity.empresa_centro_nombre ;
    var newstr = emprasa + centro.replace(emprasa, "");
    return newstr + ' - ' + activity.descripcion;
  }

  loadPaginationRanges() {
    this.listViewPaginationRanges$ = this.activityService.listViewPaginationRanges$;
    this.selectedPaginationValue = '1';
  }
 

  getActivityColor(activity: ActuacionItem) {
    let activityStatus = this.activityStatusService.listOfActivityStatus.find(x=>x.id.toLowerCase() == activity.evento_estado.trim().toLowerCase());
    if(activityStatus!= null && activityStatus != undefined){
      return activityStatus.numcolor;
    }
    
  }
  // To edit activity details
  openActivityAddPopup(activity: ActuacionItem): void {

    this.activityCalendarListService.isCalendar = false;
    this.activityCalendarListService.openActivityEditPopup(activity.id);
    this.activityCalendarListService.activityAddEditCompleted.subscribe(res => {
      if(res) {
        this.refreshActivityList();
      }
    });

  }

  // Refresh the activity list
  // NOTE : 'isNewRequest' implies any filter-based data fetch,not the pagination range drop down change
  public refreshActivityList(isNewRequest: boolean = false) {
    this.spinner.show();
    this.filterService.setPageSize(true);
    this.filterService.setFilterValuesForAPI();
    this.activityService.getFilteredActivities(this.filterService.filterObject).subscribe(response => {

      if (response.data != null && response.data.length > 0) {
        localStorage.setItem('selectedRowCount',response.links.rowcount);
        let paginationRangeArr: any[];
        if (isNewRequest) {
          this.activityService.pageCount = Math.floor(response.links.rowcount / response.data.length);
          this.activityService.pageNumberList = Array.from(Array(this.pageCount).keys()).map(i => i + 1);
          paginationRangeArr = this.activityService.getListViewPaginationRanges(this.activityService.pageNumberList);
          this.activityService.mapActivityToCalendarEvent(response.data, paginationRangeArr);
        }
        this.activityService.totalRecordCount = response.links.rowcount;
        this.activityService.listViewPaginationConfig.totalItems = this.activityService.totalRecordCount;
        this.activityService.mapActivityToCalendarEvent(response.data, paginationRangeArr);
        this.loadActivities();

      }else if(response.data != null && response.data.length == 0)
      {
        this.resetSearchResult();
      }
      this.filterService.setFilterValuesForView();
      this.spinner.hide();

    }, error => {
      this.spinner.hide();
      this.errorHandler.handle(error);
      this.filterService.setFilterValuesForView();
    });
  }

  private registerPaginationRanges() {
    this.activityService.pageRangeSubject
      .subscribe(res => {
        if (res !== null) {
          this.loadPaginationRanges();

        }
      });
  }

  private registerTotalActionCount() {
    this.activityService.totalActionCountSubject
      .subscribe(res => {
        if (res) {
          this.activityService.listViewPaginationConfig.totalItems = parseInt(String(res));
        }
      });
  }

  private registerSelectedPaginationRange() {
    this.activityService.selectedPageValueSubject
      .subscribe(res => {
        if (res !== null) {
          this.selectedPaginationValue = String(res);
        }
      });
  }

  //#region "Pagination"

  public pageChanged(pageOfItems: number) {
    this.activityService.listViewPaginationConfig.currentPage = pageOfItems;
    this.filterService.filterObject.page_number = pageOfItems;
    this.refreshActivityList();
  }

  public setDefaultDates(): void {
    const currentDate = moment();
    this.dateFilterForm.patchValue({
      DateFrom: new Date(currentDate.subtract(1, 'month').startOf('month').toString()),
      DateTo: new Date(currentDate.add(2, 'month').endOf('month').toString())
    });
    this.dateFilterForm.updateValueAndValidity();
  }

  public filterActivityDates(): void {
    if (this.dateFilterForm.valid) {
      this.spinner.show();
      const dateFrom: Date = this.dateFilterForm.get('DateFrom').value;
      const dateTo: Date = this.dateFilterForm.get('DateTo').value;
      this.filterService.resetPageDetails();

      if(this.dateFilterForm.get('DateFrom').value > this.dateFilterForm.get('DateTo').value)
      {
        this.dateFilterForm.controls['DateTo'].setErrors({'incorrect': true});
        this.dateFilterForm.markAllAsTouched();
        this.dateValidationText="erróneo";
        this.resetSearchResult();
        this.spinner.hide()
      }else
      {
        this.filterService.filterObject.fecha_desde = dateFrom ? this.sharedService.dateToString(dateFrom) : null;
        this.filterService.filterObject.fecha_hasta = dateTo ? this.sharedService.dateToString(dateTo) : null;
        this.refreshActivityList();
      }
      
    } else {
      this.resetSearchResult();
      this.errorHandler.handle('Introduzca fechas válidas para filtrar', true);
    }

  }

  private resetSearchResult() : void {
    let paginationRangeArr: any[];
    this.activityService.mapActivityToCalendarEvent(this.emptyActuacionItem, paginationRangeArr);
  }

  private onFormChange():void{
    this.dateFilterForm.valueChanges.subscribe(x => {
      if(this.dateFilterForm.get('DateFrom').value < this.dateFilterForm.get('DateTo').value)
      {
        this.dateFilterForm.get('DateTo').setErrors(null)
        this.dateFilterForm.markAllAsTouched();
        this.dateValidationText="Ingresar valor";
      }
    })
  }

  //#endregion
}
